<template>
    <aside>
      <div class="content-header">
        <div class="content-header-left mb-2">
          <div class="breadcrumbs-top">
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a>
                    <i class="feather icon-file-text"></i>
                    List Try Out / List Student
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <h2 class="content-header-title mb-0">List Student</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="card-body card-dashboard">
            <div class="row align-items-center justify-content-between">
              <div class="col mb-1">
                <div class="row">
                  <div class="col-5 input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      v-model.lazy="search"
                      type="search"
                      id="example-search-input"
                      placeholder="Masukkan Email"
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <i class="fa fa-search pointer"></i>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-3 mb-1">
                <div class="btn-responsive float-right">
                  <a
                    :href="studentsdownload.url"
                    download
                    type="button"
                    class="btn btn-primary"
                  >
                    <i class="fa fa-download"></i> Download
                  </a>
                </div>
              </div>
            </div>
  
            <div class="col">
              <div class="row float-right">
                <div class="align-self-center text-right mr-1">Menampilkan</div>
                <div class="">
                  <select v-model="limit" class="form-control">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="0">Semua</option>
                  </select>
                </div>
              </div>
            </div>
  
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th rowspan="2"><b>No.</b></th>
                    <th rowspan="2"><b>Name</b></th>
                    <th rowspan="2"><b>Email</b></th>
                    <th rowspan="2"><b>Kampus Pilihan</b></th>
                    <th rowspan="2"><b>Jurusan</b></th>
                    <th :colspan="header.length" class="text-center"><b>Nilai</b></th>
                    <th rowspan="2"><b>Total Nilai</b></th>
                    <th rowspan="2"><b>Aksi</b></th>
                  </tr>
                  <tr>
                    <th v-for="(data, index) in header" :key="index">#{{ data }}</th>
                  </tr>
                </thead>
                <tbody v-if="students.total == 0">
                  <tr>
                    <td :colspan="header.length + 7">Tidak ada data</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(data, index) in students" :key="index">
                    <td class="text-center">
                      {{ (page.current_page - 1) * limit + index + 1 }}
                    </td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.email }}</td>
                    <td>{{ data.college }}</td>
                    <td>{{ data.majors }}</td>
                    <td
                      v-for="(dt, idx) in data.nilai_sub_tryout"
                      :key="idx"
                      class="text-center"
                    >
                      {{ dt.nilai }}
                    </td>
                    <td>
                      <b>{{ data.total_poin }}</b>
                    </td>
                    <td>
                      <button
                        type="button"
                        @click="destroy(data)"
                        class="btn btn-danger waves-effect waves-light btn-rounded"
                        v-can="'tryout/delete'"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5 mt-2">
                Menampilkan <b>{{ page.from }} - {{ page.to }}</b> dari
                <b>{{ page.total }} Data</b>
              </div>
              <div class="col-sm-12 col-md-7 mt-1">
                <div class="dataTables_paginate paging_simple_numbers pull-right">
                  <ul class="pagination">
                    <li
                      class="paginate_button page-item"
                      v-for="(page, key) in pages"
                      :key="key"
                      :class="isCurrentPage(page) ? 'active' : ''"
                    >
                      <button
                        type="button"
                        class="page-link"
                        @click.prevent="changePage(page)"
                      >
                        {{ page }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    name: "TryoutStudentIndex",
    data() {
      return {
        students: [],
        studentsdownload: [],
        header: [],
        search: "",
        limit: 10,
        backup: [],
        page: {
          from: 0,
          to: 0,
          total: 0,
          current_page: 1,
          last_page: 0,
        },
      };
    },
    mounted() {
      let _ = this;
      _.getListDownload();
      _.getHeader();
      _.getList();
      _.$root.$on("paginate", (data) => {
        _.getList(data);
      });
    },
    beforeDestroy() {
      this.$root.$off("paginate");
    },
    methods: {
      isCurrentPage(page) {
        return this.page.current_page === page;
      },
      changePage(page) {
        if (page > this.page.last_page) {
          page = this.page.last_page;
        }
        this.page.current_page = page;
        this.$root.$emit("paginate", page);
        window.scrollTo(0, 0);
      },
      getList() {
        let _ = this;
        _.axios
          .get(
            `/student/tryout/getListStudent?id_tryout=${_.$route.params.id}&per_page=${_.limit}&page=${_.page.current_page}&search_email=${_.search}`
          )
          .then((resp) => {
            _.students = resp.data.data;
            _.backup = _.students;
            _.paginate();
          })
          .catch((err) => {
            console.log(err.response);
          });
      },
  
      getListDownload() {
        let _ = this;
        _.axios
          .get("/student/tryout/export?id_tryout=" + _.$route.params.id)
          .then((resp) => {
            _.studentsdownload = resp.data;
            console.log("LIST DOWNLOAD", _.getListDownload);
          })
          .catch((err) => {
            console.log(err.response);
          });
      },
      getHeader() {
        let _ = this;
        _.axios
          .get("/tryout/sub_categories/all?id_tryout=" + _.$route.params.id)
          .then((resp) => {
            _.header = resp.data.data;
          })
          .catch((err) => {
            console.log(err.response);
          });
      },
      download() {
        let _ = this;
        _.axios.defaults.responseType = "blob";
        _.axios.get("student/tryout/export?id_tryout=" + _.$route.params.id).then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.href = url;
  
          let date = moment().format("DMYhmmss");
  
          link.setAttribute("download", "StudentTryout-" + date + ".xlsx");
          document.body.appendChild(link);
          link.click();
        });
      },
      paginate() {
        let _ = this;
  
        _.page.total = _.students.total;
  
        _.page.from = _.students.from;
        _.page.to = _.students.to;
        _.page.current_page = parseInt(_.students.current_page);
        _.page.last_page = _.students.last_page;
  
        _.students = _.students.data;
      },
      destroy(x) {
        let _ = this;
        _.$swal
          .fire({
            title: "Hapus Student?",
            html:
              '<p class="popup-title">' +
              x.name +
              "</p>" +
              '<p class="popup-text">' +
              x.email +
              "</p>" +
              '<p class="popup-text-normal">Student yang sudah dihapus tidak dapat dikembalikan </p>',
            showCancelButton: true,
            cancelButtonText: "Batalkan",
            confirmButtonColor: "#EF8481",
            confirmButtonText: "Hapus Student",
          })
          .then((result) => {
            if (result.value) {
              _.axios
                .delete("/student/tryout", {
                  data: {
                    id_tryout: _.$route.params.id,
                    email: x.email,
                  },
                })
                .then((resp) => {
                  if (resp.status) {
                    _.getList();
                    _.$swal.fire({
                      title: "Terhapus!",
                      text: "Data berhasil dihapus",
                      icon: "success",
                      confirmButtonColor: "#37BA71",
                      confirmButtonText: "OK",
                    });
                  } else {
                    console.log("Failed");
                  }
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }
          });
      },
    },
    watch: {
      search: function () {
        this.getList();
      },
      limit: function () {
        this.getList();
      },
    },
    computed: {
      pages() {
        let current = this.page.current_page;
        let last = this.page.last_page;
        let delta = 2;
        let left = current - delta;
        let right = current + delta + 1;
        let range = [];
        let rangeWithDots = [];
        let l = 0;
  
        for (let i = 1; i <= last; i++) {
          if (i == 1 || i == last || (i >= left && i < right)) {
            range.push(i);
          }
        }
  
        for (let i of range) {
          if (l) {
            if (i - l === 2) {
              rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
              rangeWithDots.push("...");
            }
          }
          rangeWithDots.push(i);
          l = i;
        }
  
        return rangeWithDots;
      },
    },
  };
  </script>
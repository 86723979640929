<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Try Out / Management Sub Try Out / {{ $route.params.id_sub ? 'Edit' : 'Tambah'}} Subkategori Try Out
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link :to="'/tryout/' + $route.params.id">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ $route.params.id_sub ? 'Edit' : 'Tambah'}} Subkategori Try Out</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Type" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Type</label>
                                                            <select class="form-control" v-model="model.id_sub_category">
                                                                <option value="">Pilih Tipe</option>
                                                                <option v-for="(data, index) in optionSubcategory" :key="index" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Waktu try out" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Waktu Try Out <small><i>(satuan dalam detik)</i></small></label>
                                                            <vue-numeric-input  v-model="model.waktu" :min="0" controlsType='updown'></vue-numeric-input>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <ValidationProvider name="Jenis penilaian" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jenis Penilaian</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="Scoring" v-model="model.jenis_penilaian" style="align-self: center">
                                                                    <label for="Scoring" class="ml-1 mr-1">Scoring</label>
                                                                    <input type="radio" value="0" name="Pembobotan" v-model="model.jenis_penilaian" style="align-self: center">
                                                                    <label for="Pembobotan" class="ml-1 mr-1">Pembobotan</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row" v-if="model.jenis_penilaian == 1">
                                                <div class="col">
                                                    <ValidationProvider name="Bobot benar" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Benar</label>
                                                            <input type="number" step=".01" v-model="model.benar" class="form-control"
                                                                placeholder="Masukkan bobot benar">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Bobot salah" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Salah</label>
                                                            <input type="number" step=".01" v-model="model.salah" class="form-control"
                                                                placeholder="Masukkan bobot salah">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6" v-if="model.jenis_penilaian == 1">
                                                    <ValidationProvider name="Bobot kosong" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Kosong</label>
                                                            <input type="number" step=".01" v-model="model.kosong" class="form-control"
                                                                placeholder="Masukkan bobot kosong">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-6">
                                                    <ValidationProvider name="Minimal skor" v-slot="{ errors }">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Minimal skor</label>
                                                            <input type="number" step=".01" v-model="model.minimal_skor" class="form-control"
                                                                placeholder="Masukkan minimal skor">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Deskripsi</label>
                                                    <quill-editor v-model="model.deskripsi" :options="editorOption"/>
                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                </div>
                                            </ValidationProvider>                                
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Simpan
                                </button>
                                <router-link :to="'/tryout/' + $route.params.id">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'TryoutSubForm',
    data() {
        return {            
            model: {
                id_tryout: this.$route.params.id,
                id_sub_category: '',
                waktu: 0,
                benar: 0,
                salah: 0,
                kosong: 0,
                minimal_skor: 0,
                deskripsi: '',
                jenis_penilaian: 1
            },
            optionSubcategory: [],
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ]
                }
            },
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/tryout/sub_categories?id=' + _.$route.params.id_sub)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_subcategory() {
            let _ = this
            _.axios.get('/sub_category/all')
                .then(resp=>{
                    _.optionSubcategory = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if (_.$route.params.id_sub){
                _.axios.patch('/tryout/sub_categories', _.model)
                    .then(resp => {                
                        _.$router.push('/tryout/' + _.$route.params.id)    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/tryout/sub_categories', _.model)
                    .then(resp => {                
                        _.$router.push('/tryout/' + _.$route.params.id)    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        }
    },
    mounted() {
        let _ = this
        _.get_subcategory()
        if (_.$route.params.id_sub) {
            _.get()
        }
    }
}
</script>
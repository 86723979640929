<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a>
                                    <i class="feather icon-file-text"></i>
                                    List Reward / {{$route.params.id ? 'Edit' : 'Tambah'}} Reward
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/reward">
                                        <i class="feather icon-arrow-left"></i>
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Reward</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Foto reward" v-slot="{ errors }" rules="required">
                                                        <div style="text-align: -webkit-center;">
                                                            <div v-if="temp_photo != null" >
                                                                <img :src="temp_photo" class="img-fluid" alt="">
                                                                <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.image = null">Hapus Foto</button>
                                                            </div>
                                                            <div v-else
                                                                style="
                                                                    text-align: center;
                                                                    height: 150px;
                                                                    width: 150px;
                                                                    border: 1px solid #bdbdbd;
                                                                    border-radius: 50%;
                                                                    background: #FAFAFA;
                                                                ">
                                                                <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                                    <i class="fa fa-camera" aria-hidden="true"></i>
                                                                    <p style="font-size: 14px">Pilih Foto</p>
                                                                </label>
                                                                <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                            </div>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Nama reward" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Judul Reward</label>
                                                            <input type="text" v-model="model.title" class="form-control"
                                                                placeholder="Masukkan judul reward">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Deskripsi</label>
                                                            <quill-editor v-model="model.description" :options="editorOption"/>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <div class="row mb-2">
                                                        <div class="col">
                                                            <ValidationProvider name="Harga Saku" v-slot="{ errors }" rules="required">
                                                                <label for="">Harga Saku</label>
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="align-self-center">
                                                                            <img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 40px">
                                                                        </div>
                                                                        <div class="col"><input type="number" min="1" required v-model="model.price_wallet" class="form-control" placeholder="0"></div>
                                                                    </div>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="Harga wallet" v-slot="{ errors }" rules="required">
                                                                <label for="">Harga Diamond</label>
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="align-self-center">
                                                                            <img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width: 40px">
                                                                        </div>
                                                                        <div class="col"><input type="number" min="1" required v-model="model.price_diamond" class="form-control" placeholder="0"></div>
                                                                    </div>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <ValidationProvider name="Tanggal kedaluarsa" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Tanggal kedaluarsa</label>
                                                            <input type="date" v-model="model.expired_at" class="form-control" :min="getCurrentDate()"
                                                                placeholder="Masukkan tanggal kedaluarsa">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Simpan Reward
                                </button>
                                <router-link to="/reward" v-if="!$route.params.id">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                                <button type="button" @click="destroy($route.params.id)" class="btn btn-danger mr-1 float-right" v-else>
                                    Hapus
                                </button>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
import moment from 'moment'


export default {
	name: 'RewardForm',
    data() {
        return {
            model: {
                title: '',
                description: '',
                image: null,
                price_wallet: 0,
                price_diamond: 0,
                expired_at: null,
            },
            temp_photo: null,
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    imageResize: {
                        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
                        handleStyles: {
                        backgroundColor: 'black',
                        border: 'none',
                        color: 'white'
                        // other camelCase styles for size display
                        },
                        toolbarStyles: {
                            backgroundColor: 'black',
                            border: 'none',
                            color: 'white'
                            // other camelCase styles for size display
                        },
                        toolbarButtonStyles: {
                            // ...
                        },
                        toolbarButtonSvgStyles: {
                            // ...
                        },
                    },
                }
            },
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/reward/' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data;

                    _.temp_photo = _.model.image;

                    _.model.image = null;
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }

            if (_.$route.params.id){
                _.axios.post('/reward/' + _.$route.params.id, _.FData)
                    .then(resp => {
                        _.$router.push('/reward')
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                if (_.model.image == null){
                    _.$toast.warning('Foto harus diisi.')
                    return;
                }

                _.axios.post('/reward', _.FData)
                    .then(resp => {
                        _.$router.push('/reward')
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                       
                        _.$toast.warning(err.response.data.message)
                    })
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Reward?',
                html: '<p class="popup-text-normal">Reward tidak dapat dikembalikan apabila sudah dihapus </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Reward',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/reward/' + x)
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/reward')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })
                    }
            })
        },
        Addphoto(ev) {
            const file          = ev.target.files[0];
            this.model.image    = ev.target.files[0];
            this.temp_photo     = URL.createObjectURL(file);
        },
        makeid(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            return result.join('');
        },
        generateReward(){
            let _ = this
            let data = []
            for (let i = 0; i < _.model.jumlah; i++) {
                data[i] = _.makeid(6)
            }
            _.model.reward_codes = data
        },
        getCurrentDate() {
            return moment().format('YYYY-MM-DD');
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }

        _.$root.$on("pick", (data) => {
            _.selectedIdKategori = []
            _.selectedIdKategori.push(data)
        });

        _.$root.$on("unpick", (data) => {
            if (data) {
                _.selectedIdKategori = []
            }
        });
    },
    watch: {
        'model.jumlah': function(){
            this.model.kode_rewards = null
        }
    }
}
</script>
<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a >
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.8422 6.23341H16.8248V4.5346C16.8248 3.34475 15.8568 2.37671 14.667 2.37671H10.2267C9.98942 2.37671 9.79705 2.56908 9.79705 2.8064C9.79705 3.04371 9.98942 3.23608 10.2267 3.23608H14.667C15.383 3.23608 15.9655 3.81861 15.9655 4.5346V6.23341H7.33288C6.14303 6.23341 5.17503 7.20141 5.17503 8.39126V12.7163H3.72926C3.12391 12.7163 2.53898 12.935 2.08227 13.3322L0.859375 14.3951V4.53464C0.859375 3.81861 1.4419 3.23613 2.15789 3.23613H6.44639C6.6837 3.23613 6.87607 3.04376 6.87607 2.80644C6.87607 2.56912 6.6837 2.37675 6.44639 2.37675H2.15789C0.968043 2.37675 0 3.34475 0 4.53464V15.3378C0 15.6979 0.438496 15.8996 0.711563 15.6621L2.64606 13.9807C2.94645 13.7195 3.33111 13.5757 3.72922 13.5757H5.17498V15.2746C5.17498 16.4645 6.14298 17.4325 7.33283 17.4325H18.2687C18.6667 17.4325 19.0514 17.5763 19.3518 17.8374L21.2884 19.5206C21.5615 19.758 22 19.5565 22 19.1964V8.39126C22 7.20141 21.032 6.23341 19.8422 6.23341ZM21.1406 18.2535L19.9156 17.1888C19.4588 16.7917 18.8739 16.5731 18.2687 16.5731H7.33288C6.61689 16.5731 6.0344 15.9906 6.0344 15.2746V13.1485C6.0344 13.1476 6.03453 13.1469 6.03453 13.146V8.39109C6.03453 7.67531 6.61706 7.093 7.33305 7.093H16.3952C16.3964 7.093 16.3976 7.09283 16.3989 7.09283H19.8422C20.5581 7.09283 21.1406 7.67531 21.1406 8.3913V18.2535Z" fill="white"/>
                                        <path d="M8.01607 2.97081C8.10605 3.18682 8.36017 3.29256 8.57682 3.20328C8.79316 3.11411 8.89891 2.85798 8.80928 2.6421C8.71947 2.42584 8.46475 2.31996 8.2481 2.40964C8.03115 2.49945 7.92739 2.75447 8.01607 2.97081Z" fill="white"/>
                                    </svg>
                                    Chat
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Chat</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3"> <!-- List Chat -->
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body" style="position: relative;">
                                            <h5><b>All Chats</b></h5>
                                            <div class="input-group form-search-rounded">
                                                <input class="form-control py-2 border-right-0 border" v-model.lazy="searchChat" @change="contacts.chat = [], page_chat = 1, getChats()"  type="search" id="example-search-input" placeholder="Cari Obrolan">
                                                <span class="input-group-append">
                                                    <div class="input-group-text bg-transparent">
                                                        <i class="fa fa-search pointer"></i>
                                                    </div>
                                                </span>
                                            </div>
                                            <hr>
                                            <div id="infinite-list" style="height: 455px; overflow-x: hidden;">
                                                <div v-for="(data, idx) in chats" :key="idx">
                                                    <div class="row btn-transparent" @click="page_message = 1, getMessages(data)">
                                                        <div class="avatar-small ml-2 mr-1" v-if="data.user.photo != null">
                                                            <img :src="data.user.photo.url" alt="" class="avatar-small">
                                                        </div>
                                                        <div class="avatar-small ml-2 mr-1" v-else>
                                                            <img class="avatar-small" src="/assets/img/icon/profile.png">
                                                        </div>
                                                        <div class="col pl-0 align-self-center">
                                                            <p class="mb-0"><b>{{data.user.name}} </b><small>{{data.col_user_status == 'Student' ? 'User' : 'Admin'}}</small></p>
                                                            <small>{{ data.col_last_message.length > 20 ? data.col_last_message.substring(0, 20) + '...' : data.col_last_message}}</small>
                                                        </div>
                                                        <div class="text-right mr-1">
                                                            <small>{{ data.col_last_message_time | moment('hh:mm')}}</small><br>
                                                            <button v-if="data.col_unread_numbers > 0"
                                                                class="btn btn-success"
                                                                style="
                                                                    padding: 0px;
                                                                    width: 25px;
                                                                    height: 25px;
                                                                    border-radius: 50%;
                                                                    font-size: 12px;">
                                                                {{ data.col_unread_numbers }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                                <p class="text-center" v-if="chats.length == 0"><small>Tidak ada obrolan</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6"> <!-- Window Chat -->
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body" v-if="message_detail">
                                            <div class="row">
                                                <div class="avatar-small ml-2 mr-1" v-if="message_detail.photo != null">
                                                    <img :src="message_detail.photo.url" alt=""  class="avatar-small">
                                                </div>
                                                <div class="avatar-small ml-2 mr-1" v-else>
                                                    <img class="img-fluid" src="/assets/img/icon/profile.png">
                                                </div>
                                                <div class="col pl-0 align-self-center">
                                                    <p class="mb-0"><b>{{message_detail.name}} </b><small>{{message_detail.is_student ? 'User' : 'Admin'}}</small></p>
                                                    <small>Online</small>
                                                </div>
                                            </div>
                                            <hr>
                                            <section name="window-chat" id="chat-window" style="height: 400px; overflow: auto;" class="message-content-wrapper" v-if="messages.length > 0"
                                                v-chat-scroll="{always: false, smooth: true}" @v-chat-scroll-top-reached="page_message += 1, getMessages(message_window)">
                                                <div v-for="(data, idx) in messages" :key="idx">
                                                    <div class="chat-item from-me" v-if="data.id_sender == my_id">
                                                        <div class="chat-content">
                                                            <div class="chat">
                                                                <p>{{ data.message }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chat-item from-other" v-else>
                                                        <div class="chat-content">
                                                            <div class="chat">
                                                                <p>{{ data.message }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section name="window-chat" id="chat-window-empty" style="height: 400px; overflow: auto;" class="message-content-wrapper" v-else>
                                                <img style="
                                                    position: absolute;
                                                    left: 50%;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);"
                                                    src="/assets/img/bro.png">
                                            </section>
                                            <hr>
                                            <div class="row">
                                                <div class="col">
                                                    <textarea
                                                        id="flex-textarea"
                                                        class="form-control"
                                                        v-on:keyup="textAreaAdjust(this)"
                                                        @keydown="handleKeyDown"
                                                        placeholder="Ketik pesan"
                                                        v-model="message.message"
                                                        style="overflow:hidden; height: 40px;">
                                                    </textarea>
                                                </div>
                                                <div class="col-2 align-self-center text-center">
                                                    <b><a @click="sendMessage()">Send</a></b>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body text-center align-middle" style="height: 595px;" v-else>
                                            <img style="
                                                position: absolute;
                                                left: 50%;
                                                top: 50%;
                                                transform: translate(-50%, -50%);"
                                                src="/assets/img/bro.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3"> <!-- Contacts -->
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body text-center">
                                            <h5><b>Contacts</b></h5>
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item w-50 text-center">
                                                    <a class="nav-link active" id="user-tab" data-toggle="tab" href="#user" role="tab" aria-controls="user" aria-selected="true">User</a>
                                                </li>
                                                <li class="nav-item w-50 text-center">
                                                    <a class="nav-link" id="admin-tab" data-toggle="tab" href="#admin" role="tab" aria-controls="admin" aria-selected="false">Admin</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                                    <div class="input-group form-search-rounded mb-1">
                                                        <input class="form-control py-2 border-right-0 border" v-model.lazy="searchUser" @change="contacts.users = [], page_user = 1, getContactsUser()" type="search" placeholder="Cari User">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <i class="fa fa-search pointer"></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div id="infinite-list-student" style="height: 420px; overflow-x: hidden;">
                                                        <div v-for="(data, idx) in contacts.users" :key="idx">
                                                            <div class="row text-left btn-transparent" @click="searchContact(0, data.id_user)">
                                                                <div class="avatar-small ml-2 mr-1" v-if="data.photo != null">
                                                                    <img :src="data.photo.url" alt="" class="avatar-small">
                                                                </div>
                                                                <div class="avatar-small ml-2 mr-1" v-else>
                                                                    <img class="img-fluid" src="/assets/img/icon/profile.png">
                                                                </div>
                                                                <div class="col pl-0 align-self-center">
                                                                    <p class="mb-0"><b>{{data.name}} </b></p>
                                                                    <b><a>Mulai Chat</a></b>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="admin" role="tabpanel" aria-labelledby="admin-tab">
                                                    <div class="input-group form-search-rounded mb-1">
                                                        <input class="form-control py-2 border-right-0 border" v-model.lazy="searchAdmin" @change="contacts.admins = [], page_admin = 1, getContactsAdmin()" type="search" placeholder="Cari Admin">
                                                        <span class="input-group-append">
                                                            <div class="input-group-text bg-transparent">
                                                                <i class="fa fa-search pointer"></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div id="infinite-list-admin" style="height: 420px; overflow-x: hidden;">
                                                        <div v-for="(data, idx) in contacts.admins" :key="idx">
                                                            <div class="row text-left btn-transparent" @click="searchContact(1, data.id_user)">
                                                                <div class="avatar-small ml-2 mr-1" v-if="data.user.photo != null">
                                                                    <img :src="data.user.photo.url" alt="" class="avatar-small">
                                                                </div>
                                                                <div class="avatar-small ml-2 mr-1" v-else>
                                                                    <img class="img-fluid" src="/assets/img/icon/profile.png">
                                                                </div>
                                                                <div class="col pl-0 align-self-center">
                                                                    <p class="mb-0"><b>{{data.name}} </b></p>
                                                                    <b><a>Mulai Chat</a></b>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {
            chats: [],
            messages: [],
            message_detail: null,
            message: {
                user_id: null,
                message: null
            },
            message_window: null,
            contacts: {
                chat: [],
                users: [],
                admins: []
            },
            my_id: localStorage.getItem('id'),
            chat_id: null,
            page_chat: 1,
            page_user: 1,
            page_admin: 1,
            page_message: 1,
            isNewChat: 0,
            searchUser: '',
            searchAdmin: '',
            searchChat: ''
        }
    },
    methods: {
        getChats(){
            let _ = this
            _.axios.get('/chat?page=' + _.page_chat + '&limit=10&key=' + _.searchChat)
                .then(resp=>{
                    if (_.page_chat == 1){
                        _.chats = []
                    }
                    let data = resp.data.data.rows
                    for (let i = 0; i < data.data.length; i++) {
                        _.chats.push(data.data[i])
                        _.contacts.chat.push(data.data[i])
                      
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getMessages(x){
            let _ = this;

            _.unsubscribeAllChatChannels();

            _.axios.get('/chat?id=' + x.id + '&page=' + _.page_message + '&limit=10')
                .then(resp=>{
                    _.isNewChat = 0
                    if (_.page_message == 1){
                        _.messages      = []
                        _.message_detail    = x.user
                        _.message.user_id   = x.user.id
                        _.message.message   = null
                        _.message_window    = x

                        let data = resp.data.data.rows
                        for (let i = data.data.length - 1; i >= 0; i--) {
                            _.messages.push(data.data[i])
                        }

                        //Leave previous chat room
                        _.$echo.leave('chat-' + _.chat_id)
                        _.chat_id = x.id

                        //Mark as read
                        let foundChat = _.chats.findIndex(el => el.id === x.id)
                        _.chats[foundChat].col_unread_numbers = 0

                        //Auto scroll
                        setTimeout(() => {
                            var elm = document.getElementById('chat-window')
                            elm.scrollTop = elm.scrollHeight
                        })

                        //Listen to chat room
                        _.$pusher.subscribe(`private-chat-${x.id}`)
                        .bind('chat', e => {
                            _.messages.push(e.chat_message)
                            setTimeout(() => {
                                var elm = document.getElementById('chat-window')
                                elm.scrollTop = elm.scrollHeight

                                let searchChat = _.chats.findIndex(el => el.id === x.id)
                                _.chats[searchChat].col_last_message = e.chat_message.message
                                _.chats[searchChat].col_last_message_time = e.chat_message.created_at
                                _.chats[searchChat].col_unread_numbers = 0
                            })
                        });
                    } else {
                        let data = resp.data.data.rows
                        for (let i = 0; i < data.data.length; i++) {
                            _.messages.unshift(data.data[i])
                        }
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
        },
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === "Enter") {
                this.sendMessage();
            }
        },
        sendMessage(){
            let _ = this
            if (_.message.message == null){
                _.$toast.error('Pesan harus diisi')
                return;
            }
            _.axios.post('/chat', _.message)
                .then(resp=>{
                    if (resp.data.status){
                        _.$toast.success('Pesan berhasil terkirim')
                        _.message.message = null

                        _.page_chat     = 1
                        _.page_message  = 1

                        _.getChats()
                        _.getMessages(resp.data.data)
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        textAreaAdjust() {
            var elm = document.getElementById('flex-textarea');
            elm.style.height = "0px";
            elm.style.height = (25 + elm.scrollHeight) + "px";
            elm.style.height = (-25 + elm.scrollHeight) + "px";
        },
        getContactsUser(){
            let _ = this
            _.axios.get('/student?page=' + _.page_user + '&limit=10&key=' + _.searchUser)
                .then(resp=>{
                    let data = resp.data.data.rows
                    for (let i = 0; i < data.data.length; i++) {
                        _.contacts.users.push(data.data[i])
                   
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getContactsAdmin(){
            let _ = this
            _.axios.get('/admin?page=' + _.page_admin + '&limit=10&key=' + _.searchAdmin)
                .then(resp=>{
                    let data = resp.data.data.rows
                    for (let i = 0; i < data.data.length; i++) {
                        _.contacts.admins.push(data.data[i])
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getDetailUser($id_user){
            let _ = this

            _.axios.get('student?id_user=' + $id_user)
                .then(resp => {
                    let data = resp.data.data.rows
                    _.messages          = []
                    _.message_detail    = data.user
                    _.message.user_id   = $id_user
                    _.isNewChat         = 1
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getDetailAdmin($id_user){
            let _ = this

            _.axios.get('admin?id=' + $id_user)
                .then(resp => {
                    let data = resp.data.data.rows
                    _.messages          = []
                    _.message_detail    = data
                    _.message.user_id   = $id_user
                    _.isNewChat         = 1
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        searchContact($type, $id_user){
            let _ = this
            _.axios.get('/chat/is_new_chat?id_user='+$id_user)
                .then(resp=>{
                    if (resp.data.status){
                        _.page_message = 1
                        _.getMessages(resp.data.data)
                    }
                })
                .catch(err => {
                    if (err) {
                        //
                    }
                    if ($type == 0){
                        _.getDetailUser($id_user)
                    } else {
                        _.getDetailAdmin($id_user)
                    }
                })
        },
        unsubscribeAllChatChannels() {
            Object.keys(this.$pusher.channels.channels).forEach(channelName => {
                if (channelName.startsWith('private-chat-') && !channelName.startsWith('private-chat-to')) {
                    this.$pusher.unsubscribe(channelName)
                }
            })
        }
    },
    beforeDestroy(){
        this.$pusher.unsubscribe(`private-chat-to-${localStorage.getItem('id')}`);
        this.unsubscribeAllChatChannels();
    },
    mounted() {
        let _ = this
        _.getChats()
        _.getContactsUser(_.page_user)
        _.getContactsAdmin(_.page_admin)

        _.$pusher.subscribe(`private-chat-to-${localStorage.getItem('id')}`)
            .bind('chat-to', () => {
                console.log('gsef')
                _.getChats()
            });

        const listElm           = document.querySelector('#infinite-list')
        const listElmStudent    = document.querySelector('#infinite-list-student')
        const listElmAdmin      = document.querySelector('#infinite-list-admin')

        listElm.addEventListener('scroll', e => {
            if (e) {
                //
            }
            if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                _.page_chat += 1
                _.getChats()
            }
        });
        listElmStudent.addEventListener('scroll', e => {
            if (e) {
                //
            }
            if(listElmStudent.scrollTop + listElmStudent.clientHeight >= listElmStudent.scrollHeight) {
                _.page_user += 1
                _.getContactsUser()
            }
        });
        listElmAdmin.addEventListener('scroll', e => {
            if (e) {
                //
            }
            if(listElmAdmin.scrollTop + listElmAdmin.clientHeight >= listElmAdmin.scrollHeight) {
                _.page_admin += 1
                _.getContactsAdmin()
            }
        });
    }
}
</script>

<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.875 2.625C8.52397 2.625 9.15837 2.81744 9.69797 3.17799C10.2376 3.53854 10.6581 4.051 10.9065 4.65057C11.1548 5.25014 11.2198 5.90989 11.0932 6.54639C10.9666 7.18289 10.6541 7.76755 10.1952 8.22644C9.73631 8.68534 9.15164 8.99784 8.51514 9.12445C7.87864 9.25106 7.21889 9.18608 6.61932 8.93773C6.01975 8.68938 5.50729 8.26881 5.14674 7.72921C4.78619 7.18962 4.59375 6.55522 4.59375 5.90625C4.59375 5.03601 4.93945 4.20141 5.55481 3.58606C6.17016 2.9707 7.00476 2.625 7.875 2.625ZM7.875 1.3125C6.96644 1.3125 6.07829 1.58192 5.32285 2.08669C4.56741 2.59145 3.97862 3.3089 3.63093 4.1483C3.28324 4.9877 3.19227 5.91135 3.36952 6.80245C3.54677 7.69355 3.98428 8.51207 4.62673 9.15452C5.26918 9.79697 6.08771 10.2345 6.97881 10.4117C7.86991 10.589 8.79356 10.498 9.63295 10.1503C10.4724 9.80263 11.1898 9.21384 11.6946 8.4584C12.1993 7.70296 12.4688 6.81481 12.4688 5.90625C12.4688 4.68791 11.9848 3.51947 11.1233 2.65798C10.2618 1.79648 9.09334 1.3125 7.875 1.3125Z" fill="white"/>
                                        <path d="M14.4375 19.0312C14.4375 19.3937 14.1437 19.6875 13.7812 19.6875C13.4188 19.6875 13.125 19.3937 13.125 19.0312V16.4062C13.125 15.536 12.7793 14.7014 12.1639 14.0861C11.5486 13.4707 10.714 13.125 9.84375 13.125H5.90625C5.03601 13.125 4.20141 13.4707 3.58606 14.0861C2.9707 14.7014 2.625 15.536 2.625 16.4062V19.0312C2.625 19.3937 2.33119 19.6875 1.96875 19.6875C1.60631 19.6875 1.3125 19.3937 1.3125 19.0312V16.4062C1.3125 15.1879 1.79648 14.0195 2.65798 13.158C3.51947 12.2965 4.68791 11.8125 5.90625 11.8125H9.84375C11.0621 11.8125 12.2305 12.2965 13.092 13.158C13.9535 14.0195 14.4375 15.1879 14.4375 16.4062V19.0312Z" fill="white"/>
                                        <path d="M14.4375 3.28125C14.4375 2.91881 14.7313 2.625 15.0938 2.625H20.3438C20.7062 2.625 21 2.91881 21 3.28125C21 3.64369 20.7062 3.9375 20.3438 3.9375H15.0938C14.7313 3.9375 14.4375 3.64369 14.4375 3.28125Z" fill="white"/>
                                        <path d="M14.4375 6.5625C14.4375 6.20006 14.7313 5.90625 15.0938 5.90625H20.3438C20.7062 5.90625 21 6.20006 21 6.5625C21 6.92494 20.7062 7.21875 20.3438 7.21875H15.0938C14.7313 7.21875 14.4375 6.92494 14.4375 6.5625Z" fill="white"/>
                                        <path d="M14.4375 9.84375C14.4375 9.48131 14.7313 9.1875 15.0938 9.1875H18.375C18.7374 9.1875 19.0312 9.48131 19.0312 9.84375C19.0312 10.2062 18.7374 10.5 18.375 10.5H15.0937C14.7313 10.5 14.4375 10.2062 14.4375 9.84375Z" fill="white"/>
                                    </svg>
                                    List User / {{$route.params.id ? 'Edit' : 'Tambah'}} User
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/user">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} User</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div v-if="temp_photo != null">
                                                            <img :src="temp_photo" class="img-fluid" alt="">
                                                            <button class="btn btn-danger mt-1" @click="temp_photo = null">Hapus Foto</button>
                                                        </div>
                                                        <div v-else 
                                                            style="
                                                                text-align: center; 
                                                                height: 150px; 
                                                                width: 150px; 
                                                                border: 1px solid #bdbdbd; 
                                                                border-radius: 50%; 
                                                                background: #FAFAFA;
                                                            ">
                                                            <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                                <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                <p style="font-size: 14px">Pilih Foto</p>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Lengkap</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan nama">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jenis Kelamin</label>
                                                            <div class="container">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="male" v-model="model.jenkel" style="align-self: center">
                                                                    <label for="male" class="ml-1 mr-1">Laki-laki</label>
                                                                    <input type="radio" value="2" name="women" v-model="model.jenkel" style="align-self: center">
                                                                    <label for="women" class="ml-1 mr-1">Perempuan</label>
                                                                </div>
                                                            </div>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Alamat Lengkap</label>
                                                            <textarea cols="30" rows="5" class="form-control" placeholder="Tulis alamat lengkap" v-model="model.alamat"></textarea>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div class="col">
                                                    <ValidationProvider name="Email" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Email</label>
                                                            <input type="text" v-model="model.email" id="email" class="form-control"
                                                                placeholder="Masukkan alamat email">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Nomor telepon" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nomor Telepon</label>
                                                            <input type="text" v-model="model.no_telp" id="no_telp" class="form-control"
                                                                placeholder="Masukkan nomor telepon">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Tempat lahir" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Tempat Lahir</label>
                                                            <input type="text" v-model="model.tempat_lahir" id="tempat_lahir" class="form-control"
                                                                placeholder="Masukkan tempat lahir">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Tanggal Lahir</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal lahir" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.tanggal_lahir"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal lahir"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider> 
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Kabupaten / Kota" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Kabupaten / Kota</label>
                                                            <select v-model="model.kabupaten" class="form-control">
                                                                <option value="">Pilih Kabupaten</option>
                                                                <option v-for="(data, index) in option.city" :key="index" :value="data.city_id">{{data.type}} {{data.city_name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>                                                    
                                                    <ValidationProvider name="Kecamatan" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Kecamatan</label>
                                                            <select v-model="model.kecamatan" class="form-control">
                                                                <option value="">Pilih Kecamatan</option>
                                                                <option v-for="(data, index) in option.subdistrict" :key="index" :value="data.subdistrict_id">{{data.subdistrict_name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Kelurahan" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Kelurahan</label>
                                                            <input type="text" v-model="model.kelurahan" id="kelurahan" class="form-control"
                                                                placeholder="Masukkan nama kelurahan">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>                                                    
                                                </div>
                                            </div>                                            
                                            <div class="row">
                                                <div class="col-2"></div>                                                
                                                <div class="col">
                                                    <hr>
                                                    <ValidationProvider name="Kata sandi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Kata Sandi</label>
                                                            <input type="password" v-model="model.password" id="password" class="form-control"
                                                                placeholder="Masukkan nama kata sandi">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Konfirmasi kata sandi" v-slot="{ errors }" rules="required|confirmed:Kata sandi">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Konfirmasi Kata Sandi</label>
                                                            <input type="password" v-model="model.confirm_password" id="confirm_password" class="form-control"
                                                                placeholder="Masukkan konfirmasi kata sandi">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Tambah User
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'UserForm',
    data() {
        return {            
            model: {
                jenkel  : 1,
                kabupaten: '',
                kecamatan: ''
            },
            temp_photo: null,
            option: {
                provinces: {},
                city: {},
                subdistrict: {}
            }
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/user?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_city() {
            let _ = this
            _.axios.get('/location/city')
                .then(resp=>{
                    _.option.city = resp.data.data.rajaongkir.results
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_subdistrict() {
            let _ = this
            _.axios.get('/location/subdistrict' + '?city=' + _.model.kabupaten)
                .then(resp=>{
                    _.option.subdistrict = resp.data.data.rajaongkir.results
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }  
    
            _.$swal.fire({
                title: 'Tambahkan User?',                
                html:   '<p class="mb-0">Anda akan menambahkan user baru</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#30B44B',
                confirmButtonText: 'Tambahkan',
                }).then((result) => {
                    if (result.value) {
                        _.axios.post('/student', _.FData)
                            .then(resp => {                    
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'User ditambahkan!',
                                        text: 'User berhasil ditambahkan',
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/user')
                                }                               
                            })
                            .catch(err => {
                                _.$toast.warning(err.response.data.message)
                            })                    
                    }
            }) 
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
    },
    mounted() {
        let _ = this
        _.get_city()
        if (_.$route.params.id) {
            _.get()
        }
    },
    watch:{
        'model.kabupaten':function(){
            this.get_subdistrict()
        }
    }
}
</script>
<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Materi">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div>
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalAdd">
                        Tambah Sub
                    </button>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No</th>
                        <th>Judul</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">             
                    <router-link :to="'/sub/'+ data.id +'/konten/'" >
                        <button type="button" class="btn btn-success waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded" @click="model_edit.id = data.id, model_edit.title = data.title"
                            data-toggle="modal" data-target="#ModalEdit">
                            <i class="fa fa-pencil"></i>
                        </button>
                    <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <div class="modal fade text-left" id="ModalAdd" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-header modal-primary white">
                            <h5 class="modal-title" id="myModalLabel160">Tambah Sub Materi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationProvider name="Judul sub materi" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Judul Sub Materi</label>
                                    <input type="text" v-model="model.title" class="form-control" placeholder="Masukan judul sub materi"
                                        aria-describedby="helpId">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary" data-dismiss="modal">Simpan</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <div class="modal fade text-left" id="ModalEdit" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-header modal-primary white">
                            <h5 class="modal-title" id="myModalLabel160">Edit Sub Materi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationProvider name="Judul sub materi" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Judul Sub Materi</label>
                                    <input type="text" v-model="model_edit.title" class="form-control" placeholder="Masukan judul sub materi"
                                        aria-describedby="helpId">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="update()" class="btn btn-primary" data-dismiss="modal">Simpan</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'MateriSubIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "Sub Materi",
                svgIcon: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "Materi / Sub Materi",
                dataTable: {
                    lists: [],
                    columns: ['title', 'created_at']
                }
			},
            search: '',
            limit: 10,
            model: {
                id_sub_category: this.$route.params.id,
                title: ''
            },
            model_edit: {
                id_sub_category: this.$route.params.id,
                id: '',
                title: ''
            }
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/sub_category/chapter?id_sub_category=' + _.$route.params.id + '&key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Sub Materi?',                
                html:   '<p class="popup-title">'+ x.title + '</p>' +
                        '<p class="popup-text-normal">Sub Materi yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Sub Materi',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/sub_category/chapter', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        save() {
            let _ = this
            _.axios.post('/sub_category/chapter', _.model)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.getList()
                    _.model.title = ''
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        },
        update() {
            let _ = this
            _.axios.patch('/sub_category/chapter', _.model_edit)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.getList()
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>
<template>
  <section>
    <h1><b>Download List Users</b></h1>
    <br>
    <br>

    <div
      class="card"
      v-for="(data, index) in list_download"
      :key="index"
      :value="data.id"
    >
      <div class="card-body">
        <div>
          <h4>FileName : <b>{{ data.filename }}</b></h4>
          <a :href="data.path" style="float: right;color:#eee" class="btn btn-primary mr-1" download> <i class="fa fa-download fa-l" aria-hidden="true"></i>Download</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//   import List from "@/component++s/List.vue";
//   import moment f/rom "moment";

export default {
  name: "Download",
  components: {
    //   List,
  },
  data() {
    return {
      list_download: [],
    };
  },
  mounted() {
    let _ = this;
    _.get_list_download();
    _.$router.reload()

  },
  methods: {

    get_list_download() {
      let _ = this;
      _.axios
        .get("/student/export/list")
        .then((resp) => {
          _.list_download = resp.data.data;
        //   let date = moment().format("DMYhmmss");
          console.log(_.list_download);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

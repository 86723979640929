<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Try Out / {{$route.params.id ? 'Edit' : 'Tambah'}} Try Out
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/tryout">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Try Out</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div v-if="temp_photo != null">
                                                            <img :src="temp_photo" class="img-fluid" alt="">
                                                            <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                                        </div>
                                                        <div v-else 
                                                            style="
                                                                text-align: center; 
                                                                height: 150px; 
                                                                width: 150px; 
                                                                border: 1px solid #bdbdbd; 
                                                                border-radius: 50%; 
                                                                background: #FAFAFA;
                                                            ">
                                                            <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                                <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                <p style="font-size: 14px">Pilih Foto</p>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Nama try out" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Try Out</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan nama try out">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="row">
                                                        <div class="col">
                                                            <ValidationProvider name="Jenjang" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Jenjang</label>
                                                                    <select name="" id="" v-model="model.id_level" class="form-control">
                                                                        <option value="">Pilih Jenjang</option>
                                                                        <option v-for="(data, index) in option_jenjang" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="Materi" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Materi</label>
                                                                    <select name="" id="" v-model="model.id_theory" class="form-control">
                                                                        <option value="">Pilih Materi</option>
                                                                        <option v-for="(data, index) in option_materi.filter(data => data.id_level == model.id_level)" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <ValidationProvider name="Urutan" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Urutan</label>
                                                        <input type="number" class="form-control" v-model="model.index">                                            
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Jenis try out" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Jenis Try Out</label>
                                                        <div class="container ml-0">
                                                            <div class="row mt-1">                                                        
                                                                <input type="radio" value="1" name="premium" v-model="model.jenis" style="align-self: center">
                                                                <label for="premium" class="ml-1 mr-1">Premium</label>
                                                                <input type="radio" value="0" name="free" v-model="model.jenis" style="align-self: center">
                                                                <label for="free" class="ml-1 mr-1">Free</label>
                                                            </div>
                                                        </div>                                               
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col" v-if="model.jenis == 1">                                                
                                                <div class="form-group mb-0">
                                                    <label for="">Harga</label>
                                                    <div class="container ml-0">
                                                        <div class="row mt-1">                                                        
                                                            <input type="radio" value="0" name="semua" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="semua" class="ml-1 mr-1">Semua</label>
                                                            
                                                            <input type="radio" value="1" name="rupiah" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="rupiah" class="ml-1 mr-1">Rupiah</label>
                                                            
                                                            <input type="radio" value="2" name="saku" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="saku" class="ml-1 mr-1">Saku</label>
                                                            
                                                            <input type="radio" value="3" name="diamond" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="diamond" class="ml-1 mr-1">Diamond</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-1" v-if="model.jenis == 1">
                                                <div class="col pr-0">
                                                    <div class="row">
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 1">
                                                            <div class="row">
                                                                <div class="align-self-center">Rp</div>
                                                                <div class="col"><input type="number" min="1" v-model="model.harga_rupiah" class="form-control" placeholder="0" required></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 2">
                                                            <div class="row">
                                                                <div class="align-self-center"><img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 40px"></div>
                                                                <div class="col"><input type="number" min="1" v-model="model.harga_saku" class="form-control" placeholder="0" required></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 3">
                                                            <div class="row">
                                                                <div class="align-self-center"><img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width:30px"></div>
                                                                <div class="col"><input type="number" min="1" v-model="model.harga_diamond" class="form-control" placeholder="0" required></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="form-group mr-2 col">
                                                        <label for="">Tanggal Awal</label>
                                                        <div class="position-relative has-icon-right">
                                                            <ValidationProvider name="Tanggal awal" rules="required" v-slot="{ errors }">
                                                                <date-picker
                                                                    v-model="model.start_date"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Masukan tanggal awal"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col">
                                                        <label for="">Tanggal Selesai</label>
                                                        <div class="position-relative has-icon-right">
                                                            <ValidationProvider name="Tanggal selesai" rules="required" v-slot="{ errors }">
                                                                <date-picker
                                                                    v-model="model.end_date"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Masukan tanggal selesai"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Deskripsi</label>
                                                        <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="model.deskripsi" placeholder="Tulis deskripsi"></textarea>
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col">
                                                        <ValidationProvider name="Status publish" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Status Publish</label>
                                                                <select name="" id="" v-model="model.status_publik" class="form-control">
                                                                    <option value="0">Private</option>
                                                                    <option value="1">Public</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col">
                                                        <ValidationProvider name="Status pembahasan" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Status Pembahasan</label>
                                                                <select name="" id="" v-model="model.status_pembahasan" class="form-control">
                                                                    <option value="0">Tidak Aktif</option>
                                                                    <option value="1">Aktif</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col">
                                                        <ValidationProvider name="Status ulangi" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Status Ulangi</label>
                                                                <select name="" id="" v-model="model.status_ulangi" class="form-control">
                                                                    <option value="1">Iya</option>
                                                                    <option value="0">Tidak</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col">
                                                        <ValidationProvider name="Gabung waktu" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Gabung Waktu</label>
                                                                <select name="" id="" v-model="model.status_gabung_waktu" class="form-control">
                                                                    <option value="1">Iya</option>
                                                                    <option value="0">Tidak</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col">
                                                        <ValidationProvider name="Acak soal" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Acak Soal</label>
                                                                <select name="" id="" v-model="model.status_acak_soal" class="form-control">
                                                                    <option value="1">Iya</option>
                                                                    <option value="0">Tidak</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col">
                                                        <ValidationProvider name="Acak jawaban" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Acak Jawaban</label>
                                                                <select name="" id="" v-model="model.status_acak_jawaban" class="form-control">
                                                                    <option value="1">Iya</option>
                                                                    <option value="0">Tidak</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col">
                                                        <ValidationProvider name="Download soal" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Download Soal</label>
                                                                <select name="" id="" v-model="model.status_download_soal" class="form-control">
                                                                    <option value="1">Iya</option>
                                                                    <option value="0">Tidak</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Simpan Try Out
                                </button>
                                <router-link to="/tryout">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'TryoutForm',
    data() {
        return {            
            model: {
                id_level            : '',
                id_theory           : '',
                jenis               : 1,
                jenis_harga         : 0,
                status_publik       : 0,
                status_pembahasan   : 0,
                status_ulangi       : 0,
                status_gabung_waktu : 0,
                status_acak_soal    : 0,
                status_acak_jawaban : 0,
                status_download_soal: 0,
                index               : 1
            },
            option_jenjang: [],
            option_materi: [],
            temp_photo: null      
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/tryout?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url 
                    } 
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_materi = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            if (_.model.photo == null){
                _.$toast.warning('Foto harus diisi.')
                return;
            }

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }  

            if (_.$route.params.id){
                _.axios.post('/tryout/'+_.$route.params.id, _.FData)
                    .then(resp => {                
                        _.$router.push('/tryout')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/tryout', _.FData)
                    .then(resp => {                
                        _.$router.push('/tryout')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        resetHarga(){
            let _ = this
            _.model.harga_rupiah = 0
            _.model.harga_saku = 0
            _.model.harga_diamond = 0
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.get_jenjang()
    },
    watch: {
        'model.id_level': function(newValue, oldValue){
            let _ = this
            _.get_materi()
            if (oldValue != ''){
                _.model.id_theory = ''
            }
        }
    }
}
</script>
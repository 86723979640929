<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.05625 14.61V7.9425H7.265C6.6983 8.24459 6.15062 8.58105 5.625 8.95V9.81875C6.09375 9.4975 6.83625 9.04375 7.1975 8.8475H7.2125V14.61H8.05625ZM9.54125 12.9788C9.6 13.7788 10.2838 14.7362 11.67 14.7362C13.2425 14.7362 14.17 13.4038 14.17 11.1475C14.17 8.73 13.1937 7.8125 11.7287 7.8125C10.5712 7.8125 9.4825 8.6525 9.4825 10.0738C9.4825 11.5238 10.5125 12.2863 11.5775 12.2863C12.51 12.2863 13.115 11.8162 13.3062 11.2987H13.34C13.335 12.9437 12.7637 14.0038 11.7087 14.0038C10.8787 14.0038 10.4488 13.4413 10.3963 12.9788H9.54125ZM13.2325 10.0825C13.2325 10.9525 12.5338 11.5575 11.7525 11.5575C11.0012 11.5575 10.3225 11.0787 10.3225 10.0575C10.3225 9.02875 11.05 8.545 11.7825 8.545C12.5737 8.545 13.2325 9.0425 13.2325 10.0825Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V5H1.25ZM2.5 1.25C1.83696 1.25 1.20107 1.51339 0.732233 1.98223C0.263392 2.45107 0 3.08696 0 3.75L0 17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V3.75C20 3.08696 19.7366 2.45107 19.2678 1.98223C18.7989 1.51339 18.163 1.25 17.5 1.25H2.5Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25C5 1.41576 4.93415 1.57473 4.81694 1.69194C4.69973 1.80915 4.54076 1.875 4.375 1.875C4.20924 1.875 4.05027 1.80915 3.93306 1.69194C3.81585 1.57473 3.75 1.41576 3.75 1.25V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0V0ZM15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25C16.25 1.41576 16.1842 1.57473 16.0669 1.69194C15.9497 1.80915 15.7908 1.875 15.625 1.875C15.4592 1.875 15.3003 1.80915 15.1831 1.69194C15.0658 1.57473 15 1.41576 15 1.25V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0V0Z" fill="white"/>
                                    </svg>
                                    List Event / {{ $route.params.id ? 'Edit' : 'Tambah'}} Event
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>                
                <h2 class="content-header-title mb-0">{{ $route.params.id ? 'Edit' : 'Tambah'}} Event</h2>
            </div>                                    
        </div>
        <div class="col mb-2 text-right">
            <button v-if="$route.params.id" type="button" @click="destroy(model)" class="btn btn-outline-danger" v-can="'event/delete'">
                <i class="feather icon-x"></i> Hapus
            </button>
            <a :href="'http://kelastryout.id/fitur/event/' + model.url" v-if="$route.params.id" target="blank" type="button" class="btn btn-outline-warning ml-1 mr-1 text-warning">
                <i class="feather icon-eye"></i> Lihat
            </a>
            <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#setting">
                <i class="feather icon-settings"></i> Pengaturan
            </button>
        </div>
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="register-tab" data-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="true">Register</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="persyaratan-tab" data-toggle="tab" href="#persyaratan" role="tab" aria-controls="persyaratan" aria-selected="false">Persyaratan</a>
            </li>
            <li class="nav-item" v-show="$route.params.id">
                <a class="nav-link" id="pendaftar-tab" data-toggle="tab" href="#pendaftar" role="tab" aria-controls="pendaftar" aria-selected="false">Pendaftar</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="register" role="tabpanel" aria-labelledby="register-tab">
                <div class="content-body">
                    <section id="basic-datatable">                
                        <div class="row">
                            <div class="col-12">
                                <ValidationObserver v-slot="{ invalid }" class="card-content">
                                    <form @submit.prevent="save">
                                        <div class="card">
                                            <div class="card-content">
                                                <div class="card-body card-dashboard">
                                                    <ValidationProvider name="Nama event" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Event</label>
                                                            <input type="text" v-model="model.name" class="form-control"
                                                                placeholder="Masukkan nama event">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <section v-for="(data, index) in model.forms" :key="index">
                                                        <hr>
                                                        <ValidationProvider name="Label" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Label</label>
                                                                <input type="text" v-model="model.forms[index].label" class="form-control"
                                                                    placeholder="Masukkan label">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Deskripsi</label>
                                                                <div class="btn-group">
                                                                    <button type="button" class="btn btn-success dropdown-toggle" 
                                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        {{ model.forms[index].type }}
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'Input Text'">Input Text</a>
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'Radio', add_sub(index)">Radio</a>
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'Checkbox', add_sub(index)">Checkbox</a>
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'Select', add_sub(index)">Select</a>
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'File Upload'">File Upload</a>
                                                                        <a class="dropdown-item"  @click="model.forms[index].type = 'Text Area'">Text Area</a>
                                                                    </div>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Input Text" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'Input Text'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <textarea cols="30" rows="5" class="form-control" placeholder="Tulis deskripsi disini" v-model="model.forms[index].value"></textarea>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Radio" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'Radio'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <div class="row mb-1" v-for="(dt, idx) in model.forms[index].value" :key="idx">
                                                                    <div class="col-1 text-right align-self-center">
                                                                        <input type="radio">
                                                                    </div>
                                                                    <div class="col">                                                                    
                                                                        <input type="text" class="form-control" placeholder="Tulis deskripsi disini" v-model="model.forms[index].value[idx].val">
                                                                    </div>
                                                                    <div class="col-1 align-self-center">
                                                                        <a @click="remove_sub(index, idx)" v-if="idx > 0">Hapus</a>
                                                                    </div>
                                                                </div>
                                                                <div class="col ml-5 pl-2 mt-1">
                                                                    <a @click="add_sub(index)">+ Tambah</a>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>                                                            
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Checkbox" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'Checkbox'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <div class="row mb-1" v-for="(dt, idx) in model.forms[index].value" :key="idx">
                                                                    <div class="col-1 text-right align-self-center">
                                                                        <input type="checkbox">
                                                                    </div>
                                                                    <div class="col">                                                                    
                                                                        <input type="text" class="form-control" placeholder="Tulis deskripsi disini" v-model="model.forms[index].value[idx].val">
                                                                    </div>
                                                                    <div class="col-1 align-self-center" v-if="idx > 0">
                                                                        <a @click="remove_sub(index, idx)">Hapus</a>
                                                                    </div>
                                                                </div>
                                                                <div class="col ml-5 pl-2 mt-1">
                                                                    <a @click="add_sub(index)">+ Tambah</a>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>                                                            
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Select" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'Select'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <div class="row mb-1" v-for="(dt, idx) in model.forms[index].value" :key="idx">
                                                                    <div class="col-1 text-right align-self-center">
                                                                        -
                                                                    </div>
                                                                    <div class="col">                                                                    
                                                                        <input type="text" class="form-control" placeholder="Tulis deskripsi disini" v-model="model.forms[index].value[idx].val">
                                                                    </div>
                                                                    <div class="col-1 align-self-center" v-if="idx > 0">
                                                                        <a @click="remove_sub(index, idx)">Hapus</a>
                                                                    </div>
                                                                </div>
                                                                <div class="col ml-5 pl-2 mt-1">
                                                                    <a @click="add_sub(index)">+ Tambah</a>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>                                                            
                                                        </ValidationProvider>

                                                        <ValidationProvider name="File Upload" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'File Upload'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <input type="text" class="form-control" v-model="model.forms[index].value" placeholder="Tulis deskripsi disini">
                                                                <div class="input-group mb-3 mt-1">
                                                                    <div class="custom-file align-self-center">
                                                                        <input type="file" class="custom-file-input" id="inputGroupFile01" disabled>
                                                                        <label class="custom-file-label" for="inputGroupFile01" style="padding-top: 8px">Choose file</label>
                                                                    </div>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="Text Area" v-slot="{ errors }" rules="required" v-if="model.forms[index].type == 'Text Area'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <input type="text" class="form-control" v-model="model.forms[index].value" placeholder="Tulis deskripsi disini">
                                                                <textarea disabled cols="30" rows="5" class="form-control mt-1"></textarea>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-1">
                                                                <span style="font-size: 25px; color: #30B44B" @click="remove_form(index)">
                                                                    <i class="feather icon-trash" style="padding: 10px; border-right: 1px solid #D1D1D1"></i>                                                            
                                                                </span>
                                                            </div>
                                                            <div class="col" style="margin-top:5px">
                                                                <div class="form-group">
                                                                    <input type="checkbox" class="custom-control-input" :id="'customCheck'+index" v-model="model.forms[index].status_wajib">
                                                                    <label class="custom-control-label" :for="'customCheck'+index">Wajib diisi</label>
                                                                </div>
                                                            </div>                                                        
                                                        </div>
                                                    </section>
                                                    <button @click="add_form()" type="button" class="btn btn-outline-success mt-1">
                                                        + Tambah Form
                                                    </button>
                                                </div>
                                            </div>
                                        </div>   
                                        <button type="submit" class="btn btn-success float-right" :disabled="invalid" v-can="['event/create', 'event/update']">
                                            Simpan
                                        </button>
                                    </form>  
                                </ValidationObserver>             
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="tab-pane fade" id="persyaratan" role="tabpanel" aria-labelledby="persyaratan-tab">
                <div class="content-body">
                    <section id="basic-datatable">                
                        <div class="row">
                            <div class="col-12">
                                <ValidationObserver v-slot="{ invalid }" class="card-content">
                                    <form @submit.prevent="save">
                                        <div class="card">
                                            <div class="card-content">
                                                <div class="card-body card-dashboard">
                                                    <section v-for="(data, index) in model.requirements" :key="index">      
                                                        <ValidationProvider name="File Upload" v-slot="{ errors }" rules="required" v-if="model.requirements[index].type == 'File Upload'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <input type="text" class="form-control" v-model="model.requirements[index].label" placeholder="Tulis label disini">
                                                                <div class="input-group mb-3 mt-1">
                                                                    <div class="custom-file align-self-center">
                                                                        <input type="file" class="custom-file-input" id="inputGroupFile01" disabled>
                                                                        <label class="custom-file-label" for="inputGroupFile01" style="padding-top: 8px">Choose file</label>
                                                                    </div>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-1">
                                                                <span style="font-size: 25px; color: #30B44B" @click="remove_requirement(index)">
                                                                    <i class="feather icon-trash" style="padding: 10px; border-right: 1px solid #D1D1D1"></i>                                                            
                                                                </span>
                                                            </div>
                                                            <div class="col" style="margin-top:5px">
                                                                <div class="form-group">
                                                                    <input type="checkbox" class="custom-control-input" :id="'customCheckReq'+index" v-model="model.requirements[index].status_wajib">
                                                                    <label class="custom-control-label" :for="'customCheckReq'+index">Wajib diisi</label>
                                                                </div>
                                                            </div>                                                        
                                                        </div>
                                                    </section>
                                                    <button @click="add_requirement()" type="button" class="btn btn-outline-success mt-1">
                                                        + Tambah Syarat
                                                    </button>
                                                </div>
                                            </div>
                                        </div>   
                                        <button type="submit" class="btn btn-success float-right" :disabled="invalid" v-can="['event/create', 'event/update']">
                                            Simpan
                                        </button>
                                    </form>  
                                </ValidationObserver>             
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="tab-pane fade" id="pendaftar" role="tabpanel" aria-labelledby="pendaftar-tab">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body card-dashboard">
                            <div class="row align-items-center justify-content-between">
                                <div class="col mb-1">
                                    <div class="row">
                                        <div class="col-5 input-group form-search-rounded">
                                            <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Email">
                                            <span class="input-group-append">
                                                <div class="input-group-text bg-transparent">
                                                    <i class="fa fa-search pointer"></i>
                                                </div>
                                            </span>
                                        </div>
                                        
                                        <div class="col">
                                            <div class="row float-right">
                                                <div class="align-self-center text-right mr-1">
                                                    Menampilkan
                                                </div>
                                                <div class=" mr-1">
                                                    <select v-model="limit" class="form-control">                        
                                                        <option value="10">10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="250">250</option>
                                                        <option value="500">500</option>
                                                        <option value="1000">1000</option>
                                                        <option value="0">Semua</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                     
                            </div>                
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th rowspan="2"><b>No.</b></th>
                                            <th rowspan="2"><b>Name</b></th>
                                            <th rowspan="2"><b>Email</b></th>
                                            <th :colspan="model.forms.length" class="text-center"><b>Forms</b></th>
                                            <!-- <th :colspan="model.requirements.length" class="text-center"><b>Requirements</b></th> -->
                                            <th rowspan="2"><b>Status</b></th>
                                            <th rowspan="2"><b>Aksi</b></th>
                                        </tr>
                                        <tr>
                                            <th v-for="(data, index) in model.forms" :key="index">
                                                #{{data.label}}
                                            </th>
                                            <!-- <th v-for="(data, index) in model.requirements" :key="index">
                                                #{{data.label}}
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody v-if="events_student.data.total == 0">
                                        <tr>
                                            <td :colspan="model.forms.length + 4">Tidak ada data</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(data, index) in events_student.data" :key="index">
                                            <td>{{ (events_student.current_page - 1) * events_student.per_page + index + 1 }}</td>
                                            <td>{{data.name}}</td>
                                            <td>{{data.email}}</td>                                          
                                            <td v-for="(dt, idx) in data.form_value" :key="idx">
                                                <div v-if="dt.type == 'File Upload'">
                                                    <img :src="dt.value" alt="" class="img-fluid">
                                                </div>
                                                <div v-else>
                                                    {{dt.value}}
                                                </div>                                                
                                            </td>
                                            <!-- <td v-for="(dt, idx) in data.requirement_value" :key="idx">
                                                <img :src="dt.value" alt="" class="img-fluid">
                                            </td> -->

                                            <td>{{data.status == 0 ? 'Belum Join' : 'Sudah Join'}}</td>

                                            <!-- <router-link :to="'tryout/' + data.id" v-can="'tryout/detail'"> -->
                        <button type="button" class="btn btn-danger waves-effect waves-light btn-rounded"  @click="hapus_peserta(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    <!-- </router-link> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <PaginateList :data="events_student" v-if="events_student.data.total != 0"/>
                        </div>
                    </div>
                </div>
            </div>            
        </div>    
        <div class="modal fade" id="setting" tabindex="-1" role="dialog" aria-labelledby="setting" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3>Pengaturan</h3>
                        <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="umum-tab" data-toggle="tab" href="#umum" role="tab" aria-controls="umum" aria-selected="true">Umum</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="tryout-tab" data-toggle="tab" href="#tryout" role="tab" aria-controls="tryout" aria-selected="false">Try Out</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="kodeakses-tab" data-toggle="tab" href="#kodeakses" role="tab" aria-controls="kodeakses" aria-selected="false">Kode Akses</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="umum" role="tabpanel" aria-labelledby="umum-tab">                                    
                                <div class="row">
                                    <div class="col-2 align-self-center">URL</div>
                                    <div class="col">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button class="btn btn-secondary text-black" type="button">/event/</button>
                                            </div>
                                            <input type="text" v-model="model.url" class="form-control" placeholder="event_25" aria-label="" aria-describedby="basic-addon1">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-2 align-self-center">Waktu Mulai</div>
                                    <div class="col">                                               
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih tanggal"
                                            style="width: 100%;"
                                            v-model="model.start_date"
                                            >
                                        </date-picker>     
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-2 align-self-center">Berakhir Pada</div>
                                    <div class="col">                                               
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih tanggal"
                                            style="width: 100%;"
                                            v-model="model.end_date"
                                            >
                                        </date-picker>     
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-2 align-self-center">Tipe</div>
                                    <div class="col">                                               
                                        <select class="form-control" v-model="model.id_theory">
                                            <option value="">Pilih tipe</option>
                                            <option v-for="(data, index) in option_materi" :key="index" :value="data.id">{{data.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col mt-1">
                                    <div class="form-group ml-1">
                                        <input type="checkbox" class="custom-control-input" id="customCheckcok" v-model="model.status_publik">
                                        <label class="custom-control-label" for="customCheckcok">Publish</label>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tryout" role="tabpanel" aria-labelledby="tryout-tab">
                                <div class="row">
                                    <div class="col-2 align-self-center">Judul Tryout</div>
                                    <div class="col">
                                        <v-select :options="option_tryout" label="name" v-model="selected_tryout"></v-select>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <th>#</th>
                                            <th>Judul Tryout</th>
                                            <th>Deskripsi</th>
                                            <th>Jumlah Soal</th>
                                            <th>Publish</th>
                                            <th>Aksi</th>
                                        </thead>
                                        <tbody v-if="events_tryout.length == 0">
                                            <tr>
                                                <td colspan="6">Tidak ada data</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(data, index) in events_tryout" :key="index">
                                                <td>{{index+1}}</td>
                                                <td>{{data.name}}</td>
                                                <td>{{data.deskripsi}}</td>
                                                <td>{{data.col_jml_question}} Soal</td>
                                                <td v-html="data.col_status_publik"></td>
                                                <td>
                                                    <button @click="remove_tryout(data.id)" type="button" class="btn btn-danger">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="kodeakses" role="tabpanel" aria-labelledby="kodeakses-tab">
                                <div class="row">
                                    <div class="col">                                            
                                        <input type="text" id="name" class="form-control" placeholder="Masukkan kode" v-model="access_code.kode">
                                    </div>
                                    <div class="col">
                                        <select v-model="access_code.tipe" class="form-control">
                                            <option value="1">General</option>
                                            <option value="2">Custom</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input type="number" class="form-control" placeholder="0" v-model="access_code.jumlah">
                                    </div>
                                    <div class="col">
                                        <button :disabled="model.access_codes.length > 0" type="button" @click="add_access_code()" 
                                            class="btn col"
                                            :class="model.access_codes.length > 0 ? 'btn-outline-dark' : 'btn-outline-success'">
                                            + Tambah
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <th>#</th>
                                            <th>Kode</th>
                                            <th>Jumlah</th>
                                            <th>Digunakan</th>
                                            <th>Aksi</th>
                                        </thead>
                                        <tbody v-if="model.access_codes.length == 0">
                                            <tr>
                                                <td colspan="5">Tidak ada data</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(data, index) in model.access_codes" :key="index">
                                                <td>{{index+1}}</td>
                                                <td>{{data.kode}}</td>
                                                <td>{{data.jumlah}}</td>
                                                <td>{{data.col_jml_digunakan}}</td>
                                                <td>
                                                    <button @click="remove_access_code(index)" type="button" class="btn btn-danger">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" data-dismiss="modal">Tutup</button>
                        <button type="button" class="btn btn-success" data-dismiss="modal">Simpan</button>
                    </div>
                </div>
            </div>
        </div>
	</aside>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
    name: 'EventForm',
    components: {
        PaginateList
    },
    data() {
        return {
            model: {
                forms: [],
                requirements: [],
                tryouts: [],
                access_codes: [],
                status_publik: false,
                id_theory: '',                
            },
            events_tryout: [],
            events_student: {
                data: {
                    total: 0
                }
            },
            option_materi: [],
            option_tryout: [],            
            selected_tryout: '',
            access_code: {
                kode: '',
                jumlah: 0,
                tipe: 1,
                col_jml_digunakan: 0
            },
            search: '',
            limit: 10
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/event?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.get_event_tryout()                    
                    _.get_event_student()
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },

        hapus_peserta(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Peserta?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        // '<p class="popup-text">'+ x.col_level + ' - ' + x.col_theory + '</p>' +
                        // '<p class="popup-text-muted">'+ x.col_jml_sub_tryout + ' Jenis Tes - '+ x.col_jml_question + ' Soal</p>' +
                        // '<p class="popup-text-muted">Waktu - '+ x.col_total_waktu/60 + ' Menit</p>' +
                        '<p class="popup-text-normal">Peserta yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Peserta',
                }).then((result) => {
                    if (result.value) {
                        _.axios.get('/student/event/remove/registrant', { params: { id_event: x.id_event,email:x.email }})
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    // _.fix_order()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        add_form(){
            let _ = this
            _.model.forms.push({
                label: '',
                type: 'Input Text',
                value: [],
                status_wajib: false,
                step: 1
            })
        },
        remove_form(x){
            let _ = this
            _.model.forms.splice(x, 1);
        },
        add_requirement(){
            let _ = this
            _.model.requirements.push({
                label: '',
                type: 'File Upload',
                value: '',
                status_wajib: false,
                step: 2
            })
        },
        remove_requirement(x){
            let _ = this
            _.model.requirements.splice(x, 1);
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_materi = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Event?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text-normal">Event yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Event',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/event', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/event')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        save() {
            let _ = this
            let data = {                    
                ..._.model,
                forms: JSON.stringify(_.model.forms),
                requirements: JSON.stringify(_.model.requirements)
            }
            if (_.model.access_codes.length == 0){
                return _.$toast.warning('Harap masukan kode akses')
            }

            if (_.$route.params.id){
                _.axios.patch('/event', data)
                    .then(resp => {                
                        _.$router.push('/event')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/event', data)
                    .then(resp => {                
                        _.$router.push('/event')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        },
        get_event_tryout(){
            let _ = this
            _.axios.post('/tryout/by_array', _.model)
                .then(resp=>{
                    _.events_tryout = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_tryout() {
            let _ = this
            _.axios.get('/tryout?status=1')
                .then(resp=>{
                    _.option_tryout = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        remove_tryout(x){
            let _ = this
            const index = _.model.tryouts.indexOf(x);
            if (index > -1) {
                _.model.tryouts.splice(index, 1);
            }
            _.get_event_tryout()
        },
        add_sub(x){
            let _ = this
            _.model.forms[x].value.push({
                val: ''
            })
        },
        remove_sub(x, y){
            let _ = this
            _.model.forms[x].value.splice(y, 1)
        },
        get_event_student(page){
            let _ = this
            _.axios.get('/student/event?id_event=' + _.model.id + '&page=' + page + '&key=' + _.search + '&limit=' + _.limit)
                .then(resp=>{
                    _.events_student = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        add_access_code(){
            let _ = this
            _.model.access_codes.push(_.access_code)
            _.access_code = {
                kode: '',
                jumlah: 0,
                tipe: 1,
                col_jml_digunakan: 0
            }
        },
        remove_access_code(x){
            let _ = this
            _.model.access_codes.splice(x, 1);
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.get_materi()
        _.get_tryout()
        _.$root.$on("paginate", (data) => {
            _.get_event_student(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    watch: {
        search: function() {
            this.get_event_student()
        },
        limit: function() {
            this.get_event_student()
        },
        selected_tryout: function(){
            let _ = this
            if (_.selected_tryout != ''){
                if (_.model.tryouts.indexOf(_.selected_tryout.id) != -1){
                    console.log('Tryout already selected')
                } else {
                    _.model.tryouts.push(_.selected_tryout.id)
                    _.get_event_tryout()
                }
            }            
            _.selected_tryout = ''
        }
    }
}
</script>
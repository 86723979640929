<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22 12.8423V9.15774L19.0852 8.67193C18.9111 8.06619 18.6692 7.48293 18.3627 6.92996L20.0809 4.52449L17.4755 1.91913L15.07 3.63733C14.517 3.3308 13.9338 3.08884 13.3281 2.91478L12.8423 0H9.15774L8.67193 2.91478C8.06619 3.08889 7.48293 3.33084 6.92996 3.63733L4.52449 1.91913L1.91913 4.52449L3.63733 6.92996C3.3308 7.48298 3.08884 8.06619 2.91478 8.67193L0 9.15774V12.8423L2.91478 13.3281C3.08889 13.9338 3.33084 14.5171 3.63733 15.07L1.91913 17.4755L4.52449 20.0809L6.92996 18.3627C7.48298 18.6692 8.06619 18.9112 8.67193 19.0853L9.15774 22H12.8423L13.328 19.0852C13.9338 18.9111 14.517 18.6692 15.07 18.3626L17.4755 20.0808L20.0808 17.4755L18.3626 15.07C18.6692 14.517 18.9111 13.9338 19.0852 13.328L22 12.8423ZM18.3906 17.3356L17.3356 18.3906L15.1285 16.8141C14.9122 16.9304 14.0476 17.6019 12.6128 17.9342L12.1916 18.0318L11.746 20.7059H10.254L9.80833 18.0318C9.56275 17.9578 8.49347 17.8261 7.23826 17.043L6.87146 16.8141L4.66439 18.3906L3.60938 17.3356L5.18587 15.1285C5.06759 14.9086 4.39789 14.0469 4.06573 12.6129L3.9682 12.1917L1.29412 11.746V10.254L3.96824 9.80833C4.04218 9.56275 4.17392 8.49347 4.95703 7.23826L5.18592 6.87146L3.60942 4.66439L4.66443 3.60938L6.87151 5.18587C7.09142 5.06759 7.95313 4.39789 9.38718 4.06573L9.80838 3.9682L10.254 1.29412H11.746L12.1916 3.96824C12.4371 4.04213 13.5066 4.17392 14.7617 4.95703L15.1285 5.18592L17.3356 3.60942L18.3906 4.66443L16.8141 6.87151C16.9323 7.09142 17.6021 7.95313 17.9342 9.38718L18.0318 9.80838L20.7059 10.254V11.746L18.0318 12.1917C17.9578 12.4372 17.8261 13.5065 17.043 14.7617L16.8141 15.1285L18.3906 17.3356Z" fill="white"/>
                                        <path d="M11 5.5C7.96728 5.5 5.5 7.96728 5.5 11C5.5 14.0327 7.96728 16.5 11 16.5C14.0327 16.5 16.5 14.0327 16.5 11C16.5 7.96728 14.0327 5.5 11 5.5ZM9.11171 14.7574C9.31471 13.9026 10.084 13.2647 11 13.2647C11.916 13.2647 12.6853 13.9026 12.8883 14.7574C11.7029 15.3555 10.2976 15.3558 9.11171 14.7574ZM10.0294 11C10.0294 10.4648 10.4648 10.0294 11 10.0294C11.5352 10.0294 11.9706 10.4648 11.9706 11C11.9706 11.5352 11.5352 11.9706 11 11.9706C10.4648 11.9706 10.0294 11.5352 10.0294 11ZM13.9853 13.9596C13.7267 13.3424 13.2818 12.8224 12.7217 12.4688C13.0598 12.073 13.2647 11.5601 13.2647 11C13.2647 9.75122 12.2488 8.73529 11 8.73529C9.75122 8.73529 8.73529 9.75122 8.73529 11C8.73529 11.5601 8.94011 12.073 9.27826 12.4688C8.71817 12.8224 8.27329 13.3424 8.01468 13.9596C7.26065 13.199 6.79412 12.153 6.79412 11C6.79412 8.68085 8.68085 6.79412 11 6.79412C13.3191 6.79412 15.2059 8.68085 15.2059 11C15.2059 12.153 14.7393 13.199 13.9853 13.9596Z" fill="white"/>
                                    </svg>
                                    List Admin / {{ $route.params.id ? 'Edit' : 'Tambah'}}
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/admin">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{ $route.params.id ? 'Edit' : 'Tambah'}} Admin</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Nama admin" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Admin</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan nama admin">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Role" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Role</label>
                                                            <select class="form-control col-6" v-model="model.id_role">
                                                                <option value="">Pilih Role</option>
                                                                <option v-for="(data, index) in role" :key="index" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Email</label>
                                                            <input type="text" v-model="model.email" id="email" class="form-control"
                                                                placeholder="Masukkan email">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Password" v-slot="{ errors }" rules="required" v-if="!$route.params.id">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Password</label>
                                                            <input type="password" v-model="model.password" id="password" class="form-control"
                                                                placeholder="Masukkan password">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select class="form-control col-6" v-model="model.status">
                                                                <option value="">Pilih Status</option>
                                                                <option value="1">Aktif</option>
                                                                <option value="0">Tidak Aktif</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid" 
                                    v-if="!$route.params.id" v-can="'admin/create'">
                                    Simpan
                                </button>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid" 
                                    v-else v-can="'admin/update'">
                                    Simpan
                                </button>
                            </form>
                        </ValidationObserver>
                        <button class="btn btn-danger float-right mr-1" v-if="$route.params.id" @click="destroy()"
                            v-can="'admin/delete'">
                            Hapus
                        </button>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                id_role: '',
                status: ''
            },
            role: {}
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/admin?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_role() {
            let _ = this
            _.axios.get('/role')
                .then(resp=>{
                    _.role = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if (!_.$route.params.id){
                _.axios.post('/admin', _.model)
                    .then(resp => {
                        _.$router.push('/admin')
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.patch('/admin', _.model)
                    .then(resp => {
                        _.$router.push('/admin')
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }
        },
        destroy() {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Admin ini?',                
                html:   '<p class="mb-0">Admin tidak dapat dikembalikan <br> apabila sudah dihapus</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Ya, Hapus Admin',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/admin', { data: { id: _.$route.params.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/admin')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        _.get_role()
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>
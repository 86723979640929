<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-5 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

            <template v-slot:limit2>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Kode Pelajaran</th>
                        <th>Pelajaran</th>
                        <th>Jumlah Soal</th>
                        <th>Tgl. Buat</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="$route.params.id + '/course/' + data.id">
                        <button type="button" class="btn btn-success waves-effect waves-light btn-rounded">
                            <i class="fa fa-eye"></i>
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Data Pelajaran Kuis",
                svgIcon: `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.334 3.83325H17.2507C17.759 3.83325 18.2465 4.03519 18.6059 4.39463C18.9654 4.75408 19.1673 5.24159 19.1673 5.74992V19.1666C19.1673 19.6749 18.9654 20.1624 18.6059 20.5219C18.2465 20.8813 17.759 21.0833 17.2507 21.0833H5.75065C5.24232 21.0833 4.75481 20.8813 4.39536 20.5219C4.03592 20.1624 3.83398 19.6749 3.83398 19.1666V5.74992C3.83398 5.24159 4.03592 4.75408 4.39536 4.39463C4.75481 4.03519 5.24232 3.83325 5.75065 3.83325H7.66732" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 9.5835H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 13.4167H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.543 17.25H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 9.5835H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 13.4167H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.90625 17.25H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.3743 1.91675H8.62435C8.09508 1.91675 7.66602 2.34581 7.66602 2.87508V4.79175C7.66602 5.32102 8.09508 5.75008 8.62435 5.75008H14.3743C14.9036 5.75008 15.3327 5.32102 15.3327 4.79175V2.87508C15.3327 2.34581 14.9036 1.91675 14.3743 1.91675Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
				breadcumb: "List Kuis / List Data Pelajaran",
                dataTable: {
                    lists: [],
                    columns: ['kode', 'name', 'col_jml_question', 'created_at']
                }
            },
            search: '',
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/course?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>
<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.05625 14.61V7.9425H7.265C6.6983 8.24459 6.15062 8.58105 5.625 8.95V9.81875C6.09375 9.4975 6.83625 9.04375 7.1975 8.8475H7.2125V14.61H8.05625ZM9.54125 12.9788C9.6 13.7788 10.2838 14.7362 11.67 14.7362C13.2425 14.7362 14.17 13.4038 14.17 11.1475C14.17 8.73 13.1937 7.8125 11.7287 7.8125C10.5712 7.8125 9.4825 8.6525 9.4825 10.0738C9.4825 11.5238 10.5125 12.2863 11.5775 12.2863C12.51 12.2863 13.115 11.8162 13.3062 11.2987H13.34C13.335 12.9437 12.7637 14.0038 11.7087 14.0038C10.8787 14.0038 10.4488 13.4413 10.3963 12.9788H9.54125ZM13.2325 10.0825C13.2325 10.9525 12.5338 11.5575 11.7525 11.5575C11.0012 11.5575 10.3225 11.0787 10.3225 10.0575C10.3225 9.02875 11.05 8.545 11.7825 8.545C12.5737 8.545 13.2325 9.0425 13.2325 10.0825Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V5H1.25ZM2.5 1.25C1.83696 1.25 1.20107 1.51339 0.732233 1.98223C0.263392 2.45107 0 3.08696 0 3.75L0 17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V3.75C20 3.08696 19.7366 2.45107 19.2678 1.98223C18.7989 1.51339 18.163 1.25 17.5 1.25H2.5Z" fill="white"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25C5 1.41576 4.93415 1.57473 4.81694 1.69194C4.69973 1.80915 4.54076 1.875 4.375 1.875C4.20924 1.875 4.05027 1.80915 3.93306 1.69194C3.81585 1.57473 3.75 1.41576 3.75 1.25V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0V0ZM15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25C16.25 1.41576 16.1842 1.57473 16.0669 1.69194C15.9497 1.80915 15.7908 1.875 15.625 1.875C15.4592 1.875 15.3003 1.80915 15.1831 1.69194C15.0658 1.57473 15 1.41576 15 1.25V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0V0Z" fill="white"/>
                                    </svg>
                                    List Event
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>                
                <h2 class="content-header-title mb-0">List Event</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="col-3">
                                        <router-link to="/event/add" v-can="'event/create'">
                                            <button type="button" class="btn btn-success col p-2">
                                                Tambah
                                            </button>
                                        </router-link>
                                    </div>
                                    
                                    <div class="d-flex flex-wrap" v-if="total != 0">
                                        <router-link :to="'/event/' + data.id" v-for="(data, index) in model" :key="index" class="col-3 mt-1" v-can="'event/detail'">
                                            <button type="button" class="col btn btn-outline-dark p-4">
                                                {{ data.name }}  
                                            </button>
                                        </router-link>                                                                                                                                                                       
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'EventIndex',
    data() {
        return {            
            model: [],
            total: 0
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/event')
                .then(resp=>{
                    _.model = resp.data.data.rows
                    _.total = resp.data.data.total
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>
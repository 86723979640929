import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueAxios from 'vue-axios'
import VuePusher from 'vue-pusher'
import validate from './plugins/validate.js'
import VModal from 'vue-js-modal'
import { ValidationObserver } from 'vee-validate'
import { localize } from 'vee-validate'
import id from 'vee-validate/dist/locale/id.json'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import VueCurrencyFilter from 'vue-currency-filter'
import VueFormWizard from 'vue-form-wizard'
import vue2Dropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import VueNumericInput from 'vue-numeric-input'
import VueQuillEditor from 'vue-quill-editor'
import VCalendar from 'v-calendar'
import Switches from 'vue-switches'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueTimepicker from 'vue2-timepicker'
import Echo from 'laravel-echo'
import VueChatScroll from 'vue-chat-scroll'

import 'vue2-datepicker/index.css'
import 'dropzone/dist/dropzone.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'vue2-timepicker/dist/VueTimepicker.css'

window.io = require('socket.io-client')
export var echo_instance = new Echo({
	broadcaster: "socket.io",
	host: 'http://localhost:6001',
	auth: {
		headers: {
			Authorization: "Bearer " + localStorage.getItem('token')
		}
	}
})

Vue.prototype.$echo = echo_instance

Vue.use(VueMoment, { moment })
Vue.use(VueToast, { position: 'top' })

Vue.use(VueAxios, axios)
Vue.use(VModal)
Vue.use(VueFormWizard)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2)
Vue.use(VueNumericInput)
Vue.use(VueQuillEditor)
Vue.use(VueChatScroll)

Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

Vue.use(VCalendar, {
	componentPrefix: 'vc'
});

Vue.use(VuePusher, {
    api_key: '2ee3478c197d2b188a33',
    options: {
        cluster: 'ap1',
        authEndpoint: `${process.env.VUE_APP_URL}/broadcasting/auth`,
        auth: {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
    }
});

Vue.component('DatePicker', DatePicker)
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('multiselect', Multiselect)
Vue.component('vueDropzone', vue2Dropzone)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)
Vue.component('switches', Switches)
Vue.component('vue-timepicker', VueTimepicker)

localize('id',id)

Vue.config.productionTip = false
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

	Vue.directive('can', {
	inserted(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {
				if (store.state.permissions.indexOf(elm) != -1 ) {
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	},
	update(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {
				if (store.state.permissions.indexOf(elm) != -1 ) {
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	},
	bind(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {
				if (store.state.permissions.indexOf(elm) != -1 ) {
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	}
});

Vue.filter('sectomin', function (value){
	function secondsToHms(d) {
		d = Number(d);
		var m = Math.floor(d / 60);
		var s = Math.floor(d % 3600 % 60);
		return m + ':' + s;
	}
	return secondsToHms(value)
})

new Vue({
	store,
	router,
	render: h => h(App),
	mounted() {
		if ( localStorage.getItem('token') ) {
			this.$store.dispatch('tryAutoLogin')
		} else {
			this.$router.push('/login')
		}
	}
}).$mount('#app')

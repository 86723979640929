<template>
	<section>
		<List
            :title="table.title"
            :breadcumb="table.breadcumb"
            :dataTable="table.dataTable"
            :svgIcon="table.svgIcon">
            <template v-slot:search>                
                <div class="row">
                    <div class="col-6 input-group form-search-rounded">
                        <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Nama">
                        <span class="input-group-append">
                            <div class="input-group-text bg-transparent">
                                <i class="fa fa-search pointer"></i>
                            </div>
                        </span>
                    </div>
                </div>                
			</template>

			<template v-slot:button>
				<div class="row">
                    <router-link to="/live/add">
                        <button type="button" class="btn btn-primary mr-1">
                            Tambah Live Class
                        </button>
                    </router-link>
				</div>
			</template> 

            <template v-slot:limit>
				<div class="row float-right">
                    <div class="align-self-center text-right mr-1">
                        Menampilkan
                    </div>
                    <div class=" mr-1">
                        <select v-model="limit" class="form-control">                        
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="0">Semua</option>
                        </select>
                    </div>
				</div>
			</template>	

            <template v-slot:filter>
				<div>
                    <button type="button" @click="filter = ''" :class="filter === '' ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light">
		                Semua
		            </button>
					<button type="button" @click="filter = 0" :class="filter === 0 ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light ml-1">
		                Free
		            </button>
                    <button type="button" @click="filter = 1" :class="filter === 1 ? 'btn-success' : 'btn-muted-trans'" class="btn waves-effect waves-light ml-1">
		                Premium
		            </button>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>ID</th>
                        <th>Nama</th>
                        <th>Tanggal</th>
                        <th>Platform</th>
                        <th>Jenis</th>
                        <th>Status</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <router-link :to="'/live/' + data.id">
                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded" title="Ubah">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </router-link>
                    <router-link :to="'/live/' + data.id + '/diskusi'">
                        <button type="button" class="btn btn-info waves-effect waves-light btn-rounded" title="Diskusi">
                            <i class="fa fa-comments"></i>
                        </button>
                    </router-link>
                </div>
            </template>
		</List>
	</section>
</template>

<script>
import List from '@/components/List.vue'

export default {
	name: 'UserIndex',
	components: {
		List
	},
	data() {
		return {
			table: {
                title: "List Live Class",
                svgIcon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9827 8.43341V9.58597L15.9268 8.92491L20.4827 5.73502V16.2651L15.9268 13.0752L14.9827 12.4142V13.5667V17.4167C14.9827 17.5007 14.9493 17.5813 14.8899 17.6407C14.8305 17.7 14.75 17.7334 14.666 17.7334H1.83268C1.7487 17.7334 1.66815 17.7001 1.60877 17.6407C1.54938 17.5813 1.51602 17.5007 1.51602 17.4167V4.58341C1.51602 4.49943 1.54938 4.41888 1.60877 4.3595C1.66815 4.30011 1.7487 4.26675 1.83268 4.26675H14.666C14.75 4.26675 14.8305 4.30011 14.8899 4.3595C14.9493 4.41888 14.9827 4.49943 14.9827 4.58341V8.43341ZM20.5943 16.9946C20.5106 16.9891 20.43 16.9607 20.3613 16.9126V5.08758C20.43 5.03942 20.5106 5.01104 20.5943 5.00554C20.6781 5.00004 20.7617 5.01764 20.8361 5.05641C20.9105 5.09518 20.9729 5.15364 21.0164 5.2254C21.0598 5.29717 21.0828 5.3795 21.0827 5.46341V16.5367C21.0828 16.6207 21.0598 16.703 21.0164 16.7748C20.9729 16.8465 20.9105 16.905 20.8361 16.9438C20.7617 16.9825 20.6781 17.0001 20.5943 16.9946ZM7.38268 7.69448V7.49345L6.7828 7.49333C6.55996 7.49329 6.34394 7.57023 6.17131 7.71116C5.99868 7.85208 5.88004 8.04832 5.83547 8.26666L5.82944 8.29621L5.8264 8.32622L5.81907 8.39863L5.81602 8.42878V8.45908V13.5392C5.81602 13.5392 5.81602 13.5392 5.81602 13.5392C5.81601 13.6958 5.85401 13.8499 5.92674 13.9885L6.45802 13.7097C6.48561 13.7623 6.52556 13.8074 6.57444 13.8411C6.62331 13.8748 6.67964 13.8962 6.73859 13.9033L6.66643 14.4989C6.66643 14.4989 6.66644 14.4989 6.66644 14.4989C6.82185 14.5178 6.9795 14.4986 7.12585 14.443L7.15258 14.4328L7.17822 14.4202L7.24514 14.3872L7.27441 14.3728L7.30194 14.3552L11.294 11.8142L11.2952 11.8135C11.418 11.7349 11.5214 11.6296 11.5978 11.5054C11.6741 11.3812 11.7214 11.2414 11.736 11.0963C11.7507 10.9513 11.7323 10.8048 11.6823 10.6678L11.1187 10.8735L11.6823 10.6678C11.6324 10.5309 11.5521 10.407 11.4474 10.3055L11.426 10.2847L11.4026 10.2661L11.3448 10.2203L11.3204 10.2009L11.294 10.1841L7.38268 7.69448Z" stroke="white" stroke-width="1.2"/>
                        </svg>`,
				breadcumb: "List Live Class",
                dataTable: {
                    lists: [],
                    columns: ['name', 'col_waktu', 'platform', 'col_jenis', 'col_status']
                }
			},
            search: '',
            filter: '',
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/live?key=' + _.search + '&jenis=' + _.filter + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>
<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-users"></i> 
                                    List Jenjang / {{$route.params.id ? 'Edit' : 'Tambah'}} Jenjang
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/master">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Jenjang</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">                                            
                                            <div class="col align-self-center">
                                                <div style="text-align: -webkit-center;">
                                                    <div v-if="temp_photo != null">
                                                        <img :src="temp_photo" class="col-3 img-fluid" alt="">
                                                        <button class="btn btn-danger mt-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                                    </div>
                                                    <div v-else 
                                                        style="
                                                            text-align: center; 
                                                            height: 150px; 
                                                            width: 150px; 
                                                            border: 1px solid #bdbdbd; 
                                                            border-radius: 50%; 
                                                            background: #FAFAFA;
                                                        ">
                                                        <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                            <i class="fa fa-camera" aria-hidden="true"></i> 
                                                            <p style="font-size: 14px">Pilih Foto</p>
                                                        </label>
                                                        <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                    </div>                                                        
                                                </div>
                                            </div>
                                            <div class="col mt-1">
                                                <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Nama</label>
                                                        <input type="text" v-model="model.name" id="name" class="form-control"
                                                            placeholder="Masukkan nama">
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    {{$route.params.id ? 'Edit' : 'Tambah'}} Jenjang
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'JenjangForm',
    data() {
        return {            
            model: {
                name: '',
                photo: ''
            },
            temp_photo: null     
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/level?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if(_.model.photo != null){
                        _.temp_photo = _.model.photo
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.post('/level' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.$router.push('/master')
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        },
        Addphoto(ev) {             
            let _ = this
            const file = ev.target.files[0];
            var reader = new FileReader();
            reader.onload = (function() {
                return function(e) {
                    var binaryData      = e.target.result;
                    var base64String    = window.btoa(binaryData);
                    _.model.photo       = 'data:image/jpeg;base64,' + base64String;
                    _.temp_photo        = _.model.photo
                };
            })(file);
            reader.readAsBinaryString(file);
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
    }
}
</script>
<template>
    <aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Try Out / Management Subkategori Try Out / List Soal
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>                
                <h2 class="content-header-title mb-0">List Soal</h2>
            </div>                                    
        </div>
		<div class="card">
            <div class="card-content">
                <div class="card-body card-dashboard">
                    <div class="row align-items-center justify-content-between">
                        <div class="col mb-1">
                            <div class="row">
                                <div class="col-5 input-group form-search-rounded">
                                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Masukkan Pertanyaan">
                                    <span class="input-group-append">
                                        <div class="input-group-text bg-transparent">
                                            <i class="fa fa-search pointer"></i>
                                        </div>
                                    </span>
                                </div>

                                <div class="col">
                                    <div class="row float-right">
                                        <div class="align-self-center text-right mr-1">
                                            Menampilkan
                                        </div>
                                        <div class=" mr-1">
                                            <select v-model="limit" class="form-control">                        
                                                <option value="10">10</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="250">250</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                <option value="0">Semua</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                     
                    </div>                
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Pertanyaan</th>
                                    <th>Jawaban</th>
                                    <th>Pembahasan</th>
                                    <th>Bobot</th>
                                    <th>History Try Out</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="model.total == 0">
                                <tr>
                                    <td colspan="5">Tidak ada data</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr v-for="(data, index) in model.data" :key="index">
                                    <td class="text-center">{{index+1}}</td>
                                    <td v-html="data.question.soal"></td>
                                    <td v-html="data.question.col_jawaban"></td>
                                    <td v-html="data.question.pembahasan"></td>
                                    <td v-html="data.question.col_bobot"></td>
                                    <td v-html="data.question.col_history"></td>
                                    <td style="white-space:nowrap">
                                        <button type="button" @click="reorder(0, data.id)" class="btn btn-success waves-effect waves-light btn-rounded">
                                            <i class="fa fa-arrow-up"></i>
                                        </button>
                                        <button type="button" @click="reorder(1, data.id)" class="btn btn-danger waves-effect waves-light btn-rounded">
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                        |
                                        <button type="button" @click="destroy(data)" class="btn btn-danger waves-effect waves-light btn-rounded">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <PaginateList :data="model" v-if="model.total != 0"/>
                </div>
            </div>
        </div>
	</aside>
</template>

<script>
import PaginateList from '@/components/PaginateList'
export default {
	name: 'BankSoalDetail',
	components: {
		PaginateList
	},
	data() {
		return {
			model: [],
            search: '',
            limit: 10
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/tryout/question?key=' + _.search + '&id_tryout=' + _.$route.params.id + '&id_sub_tryout_category=' + _.$route.params.id_sub + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        reorder($dir, $id_tryout_question){
            let _ = this
            let $data = {
                id_tryout_question: $id_tryout_question,
                direction: $dir
            };

            _.axios.post('/tryout/question/reorder', $data)
                .then(resp=>{
                    if (resp.data.status){
                        _.$toast.success(resp.data.message)
                        _.getList()
                    } else {
                        _.$toast.error(resp.data.message)
                    }                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Soal?',                
                html:   '<p class="popup-title">'+ x.question.soal + '</p>' +
                        '<p class="popup-text-normal">Soal yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Soal',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/tryout/question', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        }
    }
}
</script>
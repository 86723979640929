<template>	
    <aside>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-shield"></i> 
                                    Master Data
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Master Data</h2>
            </div>
        </div>
        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist" style="border: 0px">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="jenjang-tab" data-toggle="tab" href="#jenjang" role="tab" aria-controls="jenjang" aria-selected="true">Jenjang</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="kategori-tab" data-toggle="tab" href="#kategori" role="tab" aria-controls="kategori" aria-selected="false">Kategori</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="jenjang" role="tabpanel" aria-labelledby="jenjang-tab">  
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="row">
                                                        <div class="col-6 input-group form-search-rounded">
                                                            <input class="form-control py-2 border-right-0 border" v-model.lazy="search_jenjang" type="search" id="example-search-input" placeholder="Cari">
                                                            <span class="input-group-append">
                                                                <div class="input-group-text bg-transparent">
                                                                    <i class="fa fa-search pointer"></i>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 mb-1">
                                                    <div class="btn-responsive float-right">
                                                        <router-link to="/jenjang/add">
                                                            <button type="button" class="btn btn-primary" v-can="'master/create'">
                                                                Tambah Jenjang
                                                            </button>
                                                        </router-link>
                                                    </div>
                                                </div>                                        
                                            </div>                                  
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <th width=5%>No</th>
                                                        <th>Nama</th>
                                                        <th>Tanggal Update</th>
                                                        <th>Aksi</th>
                                                    </thead>
                                                    <tbody v-if="table_jenjang.length > 0">
                                                        <tr v-for="(data, index) in table_jenjang" :key="index">
                                                            <td>{{index+1}}</td>
                                                            <td><img :src="data.photo" alt="" class="avatar-small"> {{data.name}}</td>
                                                            <td>{{data.updated_at | moment("DD/MM/YYYY")}}</td>
                                                            <td>
                                                                <div class="btn-action-block">
                                                                    <router-link :to="'jenjang/edit/' + data.id" v-can="'master/update'">
                                                                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </button>
                                                                    </router-link>
                                                                    <button type="button" @click="destroy_jenjang(data)" class="btn btn-danger waves-effect waves-light btn-rounded" v-can="'master/delete'">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="4">Tidak ada data.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="kategori" role="tabpanel" aria-labelledby="kategori-tab">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col mb-1">
                                                    <div class="row">
                                                        <div class="col-6 input-group form-search-rounded">
                                                            <input class="form-control py-2 border-right-0 border" v-model.lazy="search_kategori" type="search" id="example-search-input" placeholder="Cari">
                                                            <span class="input-group-append">
                                                                <div class="input-group-text bg-transparent">
                                                                    <i class="fa fa-search pointer"></i>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 mb-1">
                                                    <div class="btn-responsive float-right">
                                                        <router-link to="/kategori/add">
                                                            <button type="button" class="btn btn-primary" v-can="'master/create'">
                                                                Tambah Kategori
                                                            </button>
                                                        </router-link>
                                                    </div>
                                                </div>                                        
                                            </div>                                  
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <th width=5%>No</th>
                                                        <th>Nama</th>
                                                        <th>Jenjang</th>
                                                        <th>Tanggal Update</th>
                                                        <th>Aksi</th>
                                                    </thead>
                                                    <tbody v-if="table_kategori.length > 0">
                                                        <tr v-for="(data, index) in table_kategori" :key="index">
                                                            <td>{{index+1}}</td>
                                                            <td>{{data.name}}</td>
                                                            <td>{{data.col_level_name}}</td>
                                                            <td>{{data.updated_at | moment("DD/MM/YYYY")}}</td>
                                                            <td>
                                                                <div class="btn-action-block">
                                                                    <router-link :to="'kategori/edit/' + data.id" v-can="'master/update'">
                                                                        <button type="button" class="btn btn-warning waves-effect waves-light btn-rounded">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </button>
                                                                    </router-link>
                                                                    <button type="button" @click="destroy_kategori(data)" class="btn btn-danger waves-effect waves-light btn-rounded" v-can="'master/delete'">
                                                                        <i class="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="5">Tidak ada data.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>        
    </aside>	
</template>

<script>
export default {
	name: 'MasterDatandex',
	data() {
		return {
            table_jenjang: [],
            table_kategori: [],
            search_jenjang: '',
            search_kategori: ''
		}
    },
    mounted() {
        let _ = this
        _.get_list_jenjang()
        _.get_list_kategori()
    },
    methods: {
        get_list_jenjang() {
            let _ = this
            _.axios.get('/level/all?key=' + _.search_jenjang)
                .then(resp=>{
                    _.table_jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_list_kategori() {
            let _ = this
            _.axios.get('/theory/all?key=' + _.search_kategori)
                .then(resp=>{
                    _.table_kategori = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy_jenjang(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Jenjang?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text-normal">Jenjang yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Jenjang',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/level', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.get_list_jenjangt()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        destroy_kategori(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus kategori?',                
                html:   '<p class="popup-title">'+ x.name + '</p>' +
                        '<p class="popup-text-normal">kategori yang sudah dihapus tidak dapat dikembalikan </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus kategori',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/theory', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.get_list_kategori()
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        }
    },
    watch: {
        search_jenjang: function() {
            this.get_list_jenjang()
        },
        search_kategori: function() {
            this.get_list_kategori()
        }
    }
}
</script>
<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <i class="feather icon-file-text"></i> 
                                    List Live Class / {{$route.params.id ? 'Edit' : 'Tambah'}} Live Class
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/live">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Live Class</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col-xl-5">
                                                    <ValidationProvider name="Judul" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Judul</label>
                                                            <input type="text" v-model="model.name" class="form-control"
                                                                placeholder="Masukkan Judul">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Deskripsi</label>
                                                            <textarea cols="30" rows="5" class="form-control" v-model="model.deskripsi" placeholder="Tulis deskripsi"></textarea>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Materi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Materi</label>
                                                            <select name="" id="" v-model="model.id_theory" class="form-control">
                                                                <option value="">Pilih Materi</option>
                                                                <option v-for="(data, index) in option_materi" :key="index" :value="data.id">{{data.name}}</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <ValidationProvider name="Platform" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Platform</label>
                                                            <select v-model="model.platform" class="form-control">
                                                                <option value="">Pilih Platform</option>
                                                                <option value="YouTube">YouTube</option>
                                                                <option value="Zoom">Zoom</option>
                                                                <option value="Google Meet">Google Meet</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>

                                                    <div class="ml-3">
                                                        <ValidationProvider name="Link" v-slot="{ errors }" v-if="model.platform!=''" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Link</label>
                                                                <input type="text" v-model="model.link" class="form-control"
                                                                    placeholder="Masukkan link">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Meeting ID" v-slot="{ errors }" v-if="model.platform!='YouTube' && model.platform!=''" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Meeting ID</label>
                                                                <input type="text" v-model="model.meeting_id" class="form-control"
                                                                    placeholder="Masukkan Meeting ID">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Password" v-slot="{ errors }" v-if="model.platform!='YouTube' && model.platform!='' " rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Password</label>
                                                                <input type="text" v-model="model.password" class="form-control"
                                                                    placeholder="Masukkan Password">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Foto Banner" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Foto Banner</label>
                                                            <div v-if="temp_photo != null">
                                                                <img :src="temp_photo" class="img-fluid" alt="">
                                                                <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                                            </div>
                                                            <div v-else 
                                                                style="
                                                                    text-align: center; 
                                                                    height: 200px; 
                                                                    width: 500px; 
                                                                    border: 1px solid #bdbdbd; 
                                                                    border-radius: 10px; 
                                                                    background: #FAFAFA;
                                                                ">
                                                                <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 50px;">
                                                                    <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                    <p style="font-size: 14px">Pilih Foto</p>
                                                                </label>
                                                                <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                            </div>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Diskusi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Diskusi</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="Buka" v-model="model.diskusi" style="align-self: center">
                                                                    <label for="Buka" class="ml-1 mr-1">Buka</label>
                                                                    <input type="radio" value="0" name="Tutup" v-model="model.diskusi" style="align-self: center">
                                                                    <label for="Tutup" class="ml-1 mr-1">Tutup</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider> 
                                                    <ValidationProvider name="Jenis live class" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jenis Live Class</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="premium" v-model="model.jenis" style="align-self: center">
                                                                    <label for="premium" class="ml-1 mr-1">Premium</label>
                                                                    <input type="radio" value="0" name="free" v-model="model.jenis" style="align-self: center">
                                                                    <label for="free" class="ml-1 mr-1">Free</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>                          
                                                    <div class="form-group mb-0" v-if="model.jenis == 1">
                                                        <label for="">Harga</label>
                                                        <div class="container ml-0">
                                                            <div class="row mt-1">                                                        
                                                                <input type="radio" value="0" name="semua" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="semua" class="ml-1 mr-1">Semua</label>
                                                                
                                                                <input type="radio" value="1" name="rupiah" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="rupiah" class="ml-1 mr-1">Rupiah</label>
                                                                
                                                                <input type="radio" value="2" name="saku" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="saku" class="ml-1 mr-1">Saku</label>
                                                                
                                                                <input type="radio" value="3" name="diamond" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="diamond" class="ml-1 mr-1">Diamond</label>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                    <div class="col pr-0 mb-1" v-if="model.jenis == 1">
                                                        <div class="row">
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 1">
                                                                <div class="row">
                                                                    <div class="align-self-center">Rp</div>
                                                                    <div class="col"><input type="number" min="1" required v-model="model.harga_rupiah" class="form-control" placeholder="0"></div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 2">
                                                                <div class="row">
                                                                    <div class="align-self-center"><img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 40px"></div>
                                                                    <div class="col"><input type="number" min="1" required v-model="model.harga_saku" class="form-control" placeholder="0"></div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 3">
                                                                <div class="row">
                                                                    <div class="align-self-center"><img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width:30px"></div>
                                                                    <div class="col"><input type="number" min="1" required v-model="model.harga_diamond" class="form-control" placeholder="0"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="form-group mr-2 col">
                                                            <label for="">Tanggal Awal</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal awal" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.start_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal awal"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>

                                                            <label for="" class="mt-1">Jam Mulai</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Jam mulai" rules="required" v-slot="{ errors }">
                                                                    <vue-timepicker 
                                                                        format="HH:mm" 
                                                                        input-class="form-control"
                                                                        placeholder="Jam mulai"
                                                                        v-model="jam_mulai">
                                                                    </vue-timepicker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col">
                                                            <label for="">Tanggal Selesai</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal selesai" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.end_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal selesai"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>

                                                            <label for="" class="mt-1">Jam Selesai</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Jam selesai" rules="required" v-slot="{ errors }">
                                                                    <vue-timepicker 
                                                                        format="HH:mm" 
                                                                        input-class="form-control"
                                                                        placeholder="Jam selesai"
                                                                        v-model="jam_selesai">
                                                                    </vue-timepicker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select v-model="model.status" class="form-control">
                                                                <option value="0">Tidak Aktif</option>
                                                                <option value="1">Aktif</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Simpan Live Class
                                </button>
                                <router-link to="/live" v-if="!$route.params.id">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                                <button type="button" @click="destroy($route.params.id)" class="btn btn-danger mr-1 float-right" v-else>
                                    Hapus
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                tipe: 0,
                status: 1,
                diskusi: 1,
                platform: '',
                jenis: 1,
                jenis_harga: 0,
                id_theory: ''
            },
            temp_photo: null,
            option_materi: [],
            jam_mulai: {},
            jam_selesai: {}
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/live?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url 
                    } 
                    _.jam_mulai = {
                        HH: _.model.jam_mulai[0]+_.model.jam_mulai[1],
                        mm: _.model.jam_mulai[3]+_.model.jam_mulai[4]
                    }
                    _.jam_selesai = {
                        HH: _.model.jam_selesai[0]+_.model.jam_selesai[1],
                        mm: _.model.jam_selesai[3]+_.model.jam_selesai[4]
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_materi = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if (_.model.photo == null){
                _.$toast.warning('Foto harus diisi.')
                return;
            }

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }  

            _.axios.post('/live' + (_.$route.params.id ? '/'+_.$route.params.id : ''), _.FData)
                .then(resp => {                
                    _.$router.push('/live')    
                    _.$toast.success(resp.data.message)
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })  
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Live Class?',                
                html: '<p class="popup-text-normal">Live Class tidak dapat dikembalikan apabila sudah dihapus </p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Hapus Live Class',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/live', { data: { id: x }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/live')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        resetHarga(){
            let _ = this
            _.model.harga_rupiah = 0
            _.model.harga_saku = 0
            _.model.harga_diamond = 0
        }
    },
    mounted() {
        let _ = this
        _.get_materi()
        if (_.$route.params.id){
            _.get()
        }
    },
    watch: {
        jam_mulai: function(){
            let _ = this
            _.model.jam_mulai = _.jam_mulai['HH']+':'+_.jam_mulai['mm']
        },
        jam_selesai: function(){
            let _ = this
            _.model.jam_selesai = _.jam_selesai['HH']+':'+_.jam_selesai['mm']
        }
    }
}
</script>
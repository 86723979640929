<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 7.18751C11.2188 4.35024 13.9334 2.90151 19.8438 2.87501C19.9382 2.87465 20.0319 2.893 20.1192 2.92899C20.2066 2.96499 20.286 3.01791 20.3528 3.08473C20.4196 3.15154 20.4725 3.23092 20.5085 3.31828C20.5445 3.40564 20.5629 3.49927 20.5625 3.59376V16.5313C20.5625 16.7219 20.4868 16.9047 20.352 17.0395C20.2172 17.1743 20.0344 17.25 19.8438 17.25C14.0938 17.25 11.8724 18.4094 10.5 20.125C9.13573 18.418 6.90626 17.25 1.15626 17.25C0.712427 17.25 0.437505 16.8884 0.437505 16.4446V3.59376C0.437149 3.49927 0.455497 3.40564 0.491491 3.31828C0.527485 3.23092 0.580414 3.15154 0.647228 3.08473C0.714041 3.01791 0.793417 2.96499 0.88078 2.92899C0.968144 2.893 1.06177 2.87465 1.15626 2.87501C7.06663 2.90151 9.78125 4.35024 10.5 7.18751Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.5 7.1875V20.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Materi / Sub Materi / Konten / {{$route.params.id_konten ? 'Edit' : 'Tambah'}} Konten
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link :to="'/sub/' + $route.params.id + '/konten'">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id_konten ? 'Edit' : 'Tambah'}} Konten</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col">
                                                    <ValidationProvider name="Urutan" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Urutan</label>
                                                            <input type="number" class="form-control" v-model="model.index">                                            
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Jenis isi materi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jenis Isi Materi</label>
                                                            <select v-model="model.jenis_isi_materi" class="form-control">
                                                                <option value="0">Topik</option>
                                                                <option value="1">Latihan Soal</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <ValidationProvider name="Judul topik" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Judul Topik</label>
                                                            <input type="text" v-model="model.judul_topik" id="judul_topik" class="form-control"
                                                                placeholder="Masukkan nama">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <section v-if="model.jenis_isi_materi == 0">
                                                        <ValidationProvider name="Jenis isi topik" v-slot="{ errors }" rules="required">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Jenis Isi Topik</label>
                                                                <select v-model="model.jenis_isi_topik" class="form-control">
                                                                    <option value="0">E-Book</option>
                                                                    <option value="1">Video</option>
                                                                    <option value="2">Rangkuman</option>
                                                                </select>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Isi topik" v-slot="{ errors }" :rules="'required'">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Isi Topik</label>
                                                                <input type="file" class="form-control" v-if="model.jenis_isi_topik == 0" @change="changeFile" accept="application/pdf">
                                                                <input type="text" class="form-control" v-else-if="model.jenis_isi_topik == 1" v-model="model.isi_topik" placeholder="Masukkan link video">
                                                                <quill-editor v-else v-model="model.isi_topik" :options="editorOption"/>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Informasi" v-slot="{ errors }" :rules="'required'" v-if="model.jenis_isi_topik != 2">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Informasi</label>
                                                                <quill-editor v-model="model.informasi" :options="editorOption"/>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Jumlah halaman" v-slot="{ errors }" rules="required" v-if="model.jenis_isi_topik == 2">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Jumlah Halaman</label>
                                                                <input type="number" v-model="model.total_pages" id="total_pages" class="form-control"
                                                                    placeholder="Masukkan jumlah halaman">
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                        <ValidationProvider name="Durasi video" v-slot="{ errors }" rules="required" v-if="model.jenis_isi_topik == 1">
                                                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                <label for="">Durasi Video</label>
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <input type="number" class="form-control" placeholder="00" v-model="jam">
                                                                            </div>
                                                                            <div class="col align-self-center">
                                                                                Jam
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <input type="number" class="form-control" placeholder="00" v-model="menit">
                                                                            </div>
                                                                            <div class="col align-self-center">
                                                                                Menit
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col">
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <input type="number" class="form-control" placeholder="00" v-model="detik">
                                                                            </div>
                                                                            <div class="col align-self-center">
                                                                                Detik
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </div>
                                                        </ValidationProvider>
                                                    </section>
                                                    <ValidationProvider name="Durasi" v-slot="{ errors }" rules="required" v-if="model.jenis_isi_materi == 1">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Durasi</label>
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <input type="number" class="form-control" placeholder="00" v-model="jam">
                                                                        </div>
                                                                        <div class="col align-self-center">
                                                                            Jam
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <input type="number" class="form-control" placeholder="00" v-model="menit">
                                                                        </div>
                                                                        <div class="col align-self-center">
                                                                            Menit
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <input type="number" class="form-control" placeholder="00" v-model="detik">
                                                                        </div>
                                                                        <div class="col align-self-center">
                                                                            Detik
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Jenis materi" v-slot="{ errors }" rules="required">
                                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Jenis Materi</label>
                                                            <div class="container ml-0">
                                                                <div class="row mt-1">                                                        
                                                                    <input type="radio" value="1" name="premium" v-model="model.jenis" style="align-self: center">
                                                                    <label for="premium" class="ml-1 mr-1">Premium</label>
                                                                    <input type="radio" value="0" name="free" v-model="model.jenis" style="align-self: center">
                                                                    <label for="free" class="ml-1 mr-1">Free</label>
                                                                </div>
                                                            </div>                                               
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="form-group mb-0" v-if="model.jenis == 1">
                                                        <label for="">Harga</label>
                                                        <div class="container ml-0">
                                                            <div class="row mt-1">                                                        
                                                                <input type="radio" value="0" name="semua" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="semua" class="ml-1 mr-1">Semua</label>
                                                                
                                                                <input type="radio" value="1" name="rupiah" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="rupiah" class="ml-1 mr-1">Rupiah</label>
                                                                
                                                                <input type="radio" value="2" name="saku" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="saku" class="ml-1 mr-1">Saku</label>
                                                                
                                                                <input type="radio" value="3" name="diamond" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                                <label for="diamond" class="ml-1 mr-1">Diamond</label>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                    <div class="col pr-0 mb-1" v-if="model.jenis == 1">
                                                        <div class="row">
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 1">
                                                                <div class="row">
                                                                    <div class="align-self-center">Rp</div>
                                                                    <div class="col"><input type="number" min="1" v-model="model.harga_rupiah" class="form-control" placeholder="0" required></div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 2">
                                                                <div class="row">
                                                                    <div class="align-self-center"><img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 40px"></div>
                                                                    <div class="col"><input type="number" min="1" v-model="model.harga_saku" class="form-control" placeholder="0" required></div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 mt-1 col-12" v-if="model.jenis_harga == 0 || model.jenis_harga == 3">
                                                                <div class="row">
                                                                    <div class="align-self-center"><img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width:30px"></div>
                                                                    <div class="col"><input type="number" min="1" v-model="model.harga_diamond" class="form-control" placeholder="0" required></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="row">
                                                        <div class="form-group col">
                                                            <label for="">Tanggal Awal</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal awal" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.start_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal awal"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col">
                                                            <label for="">Tanggal Selesai</label>
                                                            <div class="position-relative has-icon-right">
                                                                <ValidationProvider name="Tanggal selesai" rules="required" v-slot="{ errors }">
                                                                    <date-picker
                                                                        v-model="model.end_date"
                                                                        format="DD/MM/YYYY"
                                                                        type="date"
                                                                        :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                        value-type="YYYY-MM-DD"
                                                                        placeholder="Masukan tanggal selesai"
                                                                        style="width: 100%;"
                                                                        >
                                                                    </date-picker>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ValidationProvider name="Status" v-slot="{ errors }" rules="required">
                                                        <div class="form-group w-50" :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Status</label>
                                                            <select name="" id="" v-model="model.status" class="form-control">
                                                                <option value="0">Tidak Aktif</option>
                                                                <option value="1">Aktif</option>
                                                            </select>
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    {{$route.params.id_konten ? 'Edit' : 'Tambah'}} Konten
                                </button>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
    data() {
        return {            
            model: {
                id_subject_chapter: this.$route.params.id,
                jenis_isi_materi: 0,
                jenis_isi_topik: 0,
                jenis: 1,
                jenis_harga: 0,
                durasi: '',
                status: 1,
                index: 1
            },
            jam: '',
            menit: '',
            detik: '',
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ]
                }
            },
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/sub_category/chapter/content?id=' + _.$route.params.id_konten)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.jenis_isi_topik == 1 || _.model.jenis_isi_materi == 1){
                        let durasi = _.model.durasi.split(':')
                        _.jam = durasi[0]
                        _.menit = durasi[1]
                        _.detik = durasi[2]
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if(_.detik == ''){
                _.detik = '00'
            }
            if(_.jam == ''){
                _.jam = '00'
            }
            if(_.menit == ''){
                _.menit = '00'
            }
            
            if (_.model.jenis_isi_topik == 1 || _.model.jenis_isi_materi == 1){
                _.model.durasi = _.jam + ':' + _.menit + ':' + _.detik
            }

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }

            _.axios.post('/sub_category/chapter/content' + (_.$route.params.id_konten ? '/update' : ''), _.FData)
                .then(resp => {                    
                    _.$toast.success(resp.data.message)
                    _.$router.push('/sub/' + _.$route.params.id + '/konten')
                })
                .catch(err => {
                    _.$toast.warning(err.response.data.message)
                })
        },
        changeFile(ev) {
            this.model.isi_topik = ev.target.files[0]
        },
        resetHarga(){
            let _ = this
            _.model.harga_rupiah = 0
            _.model.harga_saku = 0
            _.model.harga_diamond = 0
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id_konten) {
            _.get()
        }
    }
}
</script>
<template>
  <aside>
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.41978 14.6H17.3994V15.4H5.99979C5.23122 15.4 4.59986 14.7686 4.59986 14C4.59986 13.7529 4.66338 13.5257 4.7708 13.3377L4.77087 13.3377L4.77536 13.3296L6.12531 10.8796L6.27358 10.6105L6.14192 10.3329L2.54205 2.74288L2.37942 2.4H1.99993H0.6V1.6H2.88892L3.66684 3.25521L3.82888 3.6H4.20986H18.9985C19.068 3.60026 19.1363 3.61849 19.1967 3.65293C19.2572 3.68746 19.3078 3.73711 19.3434 3.79703C19.379 3.85694 19.3985 3.92507 19.3999 3.99477C19.4013 4.0638 19.385 4.13202 19.3525 4.1929C19.3521 4.19349 19.3518 4.19408 19.3515 4.19466L15.7741 10.6802L15.7733 10.6815C15.5369 11.1127 15.0767 11.4 14.5495 11.4H7.09976H6.74565L6.5745 11.71L5.67453 13.34L5.63669 13.4085L5.6177 13.4845L5.5877 13.6045L5.56979 13.6761V13.75C5.56979 14.2214 5.94839 14.6 6.41978 14.6ZM4.60986 19C4.60986 18.2274 5.23513 17.6 5.99979 17.6C6.76837 17.6 7.39973 18.2314 7.39973 19C7.39973 19.7686 6.76837 20.4 5.99979 20.4C5.23513 20.4 4.60986 19.7726 4.60986 19ZM14.6095 19C14.6095 18.2274 15.2348 17.6 15.9995 17.6C16.768 17.6 17.3994 18.2314 17.3994 19C17.3994 19.7686 16.768 20.4 15.9995 20.4C15.2348 20.4 14.6095 19.7726 14.6095 19Z"
                      stroke="white"
                      stroke-width="1.2"
                    />
                  </svg>
                  List Paket / {{ $route.params.id ? "Edit" : "Tambah" }} Paket
                </a>
              </li>
            </ol>
          </div>
        </div>
        <div class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <b>
                  <router-link to="/package">
                    <i class="feather icon-arrow-left"></i>
                    Kembali
                  </router-link>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <h2 class="content-header-title mb-0">
          {{ $route.params.id ? "Edit" : "Tambah" }} Paket
        </h2>
      </div>
    </div>

    <div class="content-body">
      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <ValidationObserver v-slot="{ invalid }" class="card-content">
              <form @submit.prevent="save">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body card-dashboard">
                      <div class="row">
                        <div class="col-2">
                          <div style="text-align: -webkit-center">
                            <div v-if="temp_photo_main != null">
                              <img
                                :src="temp_photo_main"
                                class="img-fluid"
                                alt=""
                              />
                              <button
                                class="btn btn-danger mt-1 mb-1"
                                @click="
                                  (temp_photo_main = null),
                                    (model.photo_web = null)
                                "
                              >
                                Hapus Foto Utama
                              </button>
                            </div>
                            <div
                              v-else
                              style="
                                text-align: center;
                                height: 150px;
                                width: 150px;
                                border: 1px solid #bdbdbd;
                                border-radius: 50%;
                                background: #fafafa;
                              "
                            >
                              <label
                                for="file-upload"
                                class="btn-edit"
                                style="font-size: 40px; margin-top: 27px"
                              >
                                <i class="fa fa-camera" aria-hidden="true"></i>
                                <p style="font-size: 14px">Pilih Foto Utama</p>
                              </label>
                              <input
                                id="file-upload"
                                class="input-file-avatar d-none"
                                type="file"
                                v-on:change="AddphotoMain"
                              />
                            </div>
                          </div>

                          <div style="text-align: -webkit-center" class="mt-1">
                            <div v-if="temp_photo_thumbnail != null">
                              <img
                                :src="temp_photo_thumbnail"
                                class="img-fluid"
                                alt=""
                              />
                              <button
                                class="btn btn-danger mt-1 mb-1"
                                @click="
                                  (temp_photo_thumbnail = null),
                                    (model.photo_mobile = null)
                                "
                              >
                                Hapus Foto Thumbnail
                              </button>
                            </div>
                            <div
                              v-else
                              style="
                                text-align: center;
                                height: 150px;
                                width: 150px;
                                border: 1px solid #bdbdbd;
                                border-radius: 50%;
                                background: #fafafa;
                              "
                            >
                              <label
                                for="file-upload"
                                class="btn-edit"
                                style="font-size: 40px; margin-top: 27px"
                              >
                                <i class="fa fa-camera" aria-hidden="true"></i>
                                <p style="font-size: 14px">
                                  Pilih Foto Thumbnail
                                </p>
                              </label>
                              <input
                                id="file-upload"
                                class="input-file-avatar d-none"
                                type="file"
                                v-on:change="AddphotoThumbnail"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <ValidationProvider
                            name="Nama paket"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Nama Paket</label>
                              <input
                                type="text"
                                v-model="model.name"
                                id="name"
                                class="form-control"
                                placeholder="Masukkan nama paket"
                              />
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            name="Deskripsi"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Deskripsi</label>
                              <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="7"
                                class="form-control"
                                v-model="model.deskripsi"
                                placeholder="Tulis deskripsi"
                              ></textarea>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            name="Status"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Status</label>
                              <select
                                name=""
                                id=""
                                v-model="model.status"
                                class="form-control col-6"
                              >
                                <option value="0">Tidak Aktif</option>
                                <option value="1">Aktif</option>
                              </select>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            name="Tanggal selesai"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Tanggal Selesai</label>
                              <date-picker
                                v-model="model.end_date"
                                format="DD/MM/YYYY"
                                type="date"
                                input-class="form-control"
                                value-type="YYYY-MM-DD"
                                placeholder="Masukan tanggal selesai"
                                style="width: 100%"
                              >
                              </date-picker>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            name="Featured"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Featured</label>
                              <select
                                name=""
                                id=""
                                v-model="model.is_featured"
                                class="form-control col-6"
                              >
                                <option value="1">Iya</option>
                                <option value="0">Tidak</option>
                              </select>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <ValidationProvider
                            name="Type"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Type</label>
                              <select
                                name=""
                                id=""
                                v-model="model.type"
                                class="form-control col-6"
                              >
                                <option value="1">Tryout</option>
                                <option value="0">Bundling</option>
                                <option value="2">Diamond</option>
                              </select>
                           

                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <label for="">Tipe Paket</label>
                              <div class="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value="Tryout"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <ValidationProvider
                                name="ID kategori"
                                v-slot="{ errors }"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">ID Kategori</label>
                                  <input
                                    type="text"
                                    v-model="model.tryout_ids"
                                    class="form-control"
                                    placeholder="Pilih ID kategori"
                                    readonly
                                  />
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>


                            

                            
                            <div class="col-2 align-self-center">
                              <a
                                data-target="#modalTableTryout"
                                data-toggle="modal"
                                ><u>Cari ID Kategori</u></a
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label for="">Tipe Paket</label>
                              <div class="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value="Materi"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <ValidationProvider
                                name="ID kategori"
                                v-slot="{ errors }"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">ID Kategori</label>
                                  <input
                                    type="text"
                                    v-model="model.materi_ids"
                                    class="form-control"
                                    placeholder="Pilih ID kategori"
                                    readonly
                                  />
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-2 align-self-center">
                              <a
                                data-target="#modalTableMateri"
                                data-toggle="modal"
                                ><u>Cari ID Kategori</u></a
                              >
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <label for="">Tipe Paket</label>
                              <div class="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value="Quiz"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <ValidationProvider
                                name="ID kategori"
                                v-slot="{ errors }"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">ID Kategori</label>
                                  <input
                                    type="text"
                                    v-model="model.quiz_ids"
                                    class="form-control"
                                    placeholder="Pilih ID kategori"
                                    readonly
                                  />
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-2 align-self-center">
                              <a
                                data-target="#modalTableQuiz"
                                data-toggle="modal"
                                ><u>Cari ID Kategori</u></a
                              >
                            </div>
                          </div>


                          <div class="row">
                            <div class="col">
                              <label for="">Tipe Paket</label>
                              <div class="form-group">
                                <input
                                  type="text"
                                  disabled
                                  value="Live Class"
                                  class="form-control"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <ValidationProvider
                                name="ID kategori"
                                v-slot="{ errors }"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">ID Kategori</label>
                                  <input
                                    type="text"
                                    v-model="model.live_ids"
                                    class="form-control"
                                    placeholder="Pilih ID kategori"
                                    readonly
                                  />
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col-2 align-self-center">
                              <a
                                data-target="#modalLiveClass"
                                data-toggle="modal"
                                ><u>Cari ID Kategori</u></a
                              >
                            </div>
                          </div>

                          <ValidationProvider
                            name="Premium item"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Premium Item</label>
                              <select
                                name=""
                                id=""
                                v-model="model.premium_item"
                                class="form-control"
                              >
                                <option value="">Pilih Materi</option>
                                <option
                                  v-for="(data, index) in option_materi"
                                  :key="index"
                                  :value="data.id"
                                >
                                  {{ data.name }}
                                </option>
                              </select>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                          <div class="row">
                            <div class="col-8">
                              <ValidationProvider
                                name="Jenis paket"
                                v-slot="{ errors }"
                                rules="required"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">Jenis Paket</label>
                                  <select
                                    name=""
                                    id=""
                                    v-model="model.jenis"
                                    class="form-control"
                                  >
                                    <option value="0">Sendiri</option>
                                    <option value="1">Patungan</option>
                                  </select>
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col">
                              <ValidationProvider
                                name="Total user"
                                v-slot="{ errors }"
                                :rules="model.jenis == 1 ? 'required' : ''"
                              >
                                <div
                                  class="form-group mt-2"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for=""></label>
                                  <input
                                    type="number"
                                    v-model="model.total_user"
                                    id="name"
                                    class="form-control"
                                    placeholder="Total user"
                                    :disabled="model.jenis == 0"
                                  />
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="form-group mb-0">
                            <label for="">Harga</label>
                            <div class="container ml-0">
                              <div class="row mt-1">
                                <input
                                  type="radio"
                                  value="0"
                                  name="semua"
                                  v-model="model.jenis_harga"
                                  style="align-self: center"
                                  v-on:change="resetHarga()"
                                />
                                <label for="semua" class="ml-1 mr-1"
                                  >Semua</label
                                >

                                <input
                                  type="radio"
                                  value="1"
                                  name="rupiah"
                                  v-model="model.jenis_harga"
                                  style="align-self: center"
                                  v-on:change="resetHarga()"
                                />
                                <label for="rupiah" class="ml-1 mr-1"
                                  >Rupiah</label
                                >

                                <input
                                  type="radio"
                                  value="2"
                                  name="saku"
                                  v-model="model.jenis_harga"
                                  style="align-self: center"
                                  v-on:change="resetHarga()"
                                />
                                <label for="saku" class="ml-1 mr-1">Saku</label>

                                <input
                                  type="radio"
                                  value="3"
                                  name="diamond"
                                  v-model="model.jenis_harga"
                                  style="align-self: center"
                                  v-on:change="resetHarga()"
                                />
                                <label for="diamond" class="ml-1 mr-1"
                                  >Diamond</label
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col pr-0 mb-1">
                            <div class="row">
                              <div
                                class="col-xl-4 mt-1 col-12"
                                v-if="
                                  model.jenis_harga == 0 ||
                                  model.jenis_harga == 1
                                "
                              >
                                <div class="row">
                                  <div class="align-self-center">Rp</div>
                                  <div class="col">
                                    <input
                                      type="number"
                                      min="1"
                                      required
                                      v-model="model.harga_rupiah"
                                      class="form-control"
                                      placeholder="0"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-xl-4 mt-1 col-12"
                                v-if="
                                  model.jenis_harga == 0 ||
                                  model.jenis_harga == 2
                                "
                              >
                                <div class="row">
                                  <div class="align-self-center">
                                    <img
                                      src="/assets/img/icon/saku.png"
                                      alt=""
                                      class="img-fluid"
                                      style="width: 40px"
                                    />
                                  </div>
                                  <div class="col">
                                    <input
                                      type="number"
                                      min="1"
                                      required
                                      v-model="model.harga_saku"
                                      class="form-control"
                                      placeholder="0"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-xl-4 mt-1 col-12"
                                v-if="
                                  model.jenis_harga == 0 ||
                                  model.jenis_harga == 3
                                "
                              >
                                <div class="row">
                                  <div class="align-self-center">
                                    <img
                                      src="/assets/img/icon/diamond.png"
                                      alt=""
                                      class="img-fluid"
                                      style="width: 30px"
                                    />
                                  </div>
                                  <div class="col">
                                    <input
                                      type="number"
                                      min="1"
                                      required
                                      v-model="model.harga_diamond"
                                      class="form-control"
                                      placeholder="0"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col" v-if="model.harga_rupiah > 0">
                              <ValidationProvider
                                name="Diskon"
                                v-slot="{ errors }"
                                rules="min_value:0|max_value:99"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">Diskon</label>
                                  <div class="row">
                                    <div class="col">
                                      <input
                                        type="number"
                                        v-model="model.diskon"
                                        id="diskon"
                                        class="form-control"
                                        placeholder="0"
                                      />
                                    </div>
                                    <div class="col align-self-center">%</div>
                                  </div>
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                            <div class="col" v-if="model.diskon > 0">
                              <ValidationProvider
                                name="Harga Sebelum Diskon"
                                v-slot="{ errors }"
                                rules="required|min_value:1"
                              >
                                <div
                                  class="form-group"
                                  :class="{ 'validation-warning': errors[0] }"
                                >
                                  <label for="">Harga Sebelum Diskon</label>
                                  <div class="row">
                                    <div class="col-3 align-self-center">
                                      Rp
                                    </div>
                                    <div class="col">
                                      <input
                                        type="number"
                                        disabled
                                        v-model="model.harga_before_diskon"
                                        id="harga_before_diskon"
                                        class="form-control"
                                        placeholder="0"
                                      />
                                    </div>
                                  </div>
                                  <small class="text-muted" v-if="errors[0]">{{
                                    errors[0]
                                  }}</small>
                                </div>
                              </ValidationProvider>
                            </div>
                          </div>
                          <ValidationProvider
                            name="Masa berlaku"
                            v-slot="{ errors }"
                            rules="required|min_value:1"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Masa Berlaku</label>
                              <div class="row">
                                <div class="col">
                                  <input
                                    type="number"
                                    v-model="model.masa_berlaku"
                                    id="masa_berlaku"
                                    class="form-control"
                                    placeholder="1"
                                  />
                                </div>
                                <div class="col align-self-center">Bulan</div>
                              </div>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                        </div>


                        
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-success float-right"
                  :disabled="invalid"
                >
                  Simpan Paket
                </button>
                <router-link to="/package">
                  <button
                    type="button"
                    class="btn btn-outline-success mr-1 float-right"
                  >
                    Cancel
                  </button>
                </router-link>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </section>
    </div>
    <div
      class="modal fade"
      id="modalTableTryout"
      tabindex="-1"
      role="dialog"
      aria-labelledby="setting"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        style="min-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <LookupTryout :id="model.tryout_ids"></LookupTryout>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalTableMateri"
      tabindex="-1"
      role="dialog"
      aria-labelledby="setting"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        style="min-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <LookupMateri :id="model.materi_ids"></LookupMateri>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalTableQuiz"
      tabindex="-1"
      role="dialog"
      aria-labelledby="setting"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        style="min-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <LookupQuiz :id="model.quiz_ids"></LookupQuiz>
          </div>
        </div>
      </div>
    </div>


    <div
      class="modal fade"
      id="modalLiveClass"
      tabindex="-1"
      role="dialog"
      aria-labelledby="setting"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        style="min-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <LookupLiveClass :id="model.live_ids"></LookupLiveClass>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import LookupMateri from "@/views/materi/Lookup.vue";
import LookupTryout from "@/views/tryout/Lookup.vue";
import LookupQuiz from "@/views/quiz/Lookup.vue";
import LookupLiveClass from "@/views/live_class/Lookup.vue";

export default {
  name: "PackageForm",
  components: {
    LookupMateri,
    LookupTryout,
    LookupQuiz,
    LookupLiveClass,
  },
  data() {
    return {
      model: {
        type: 1,
        jenis: 0,
        jenis_harga: 0,
        masa_berlaku: 1,
        premium_item: "",
        status: 1,
        id: this.$route.params.id ? this.$route.params.id : "",
        id_kategori: [],
        is_featured: 0,
        harga_rupiah: 0,
        diskon: 0,
        harga_diamond: 0,
        harga_saku: 0,
        tryout_ids: [],
        materi_ids: [],
        quiz_ids: [],
        live_ids:[]
      },
      option_materi: [],
      temp_photo_main: null,
      temp_photo_thumbnail: null,
    };
  },
  methods: {
    get() {
      let _ = this;
      _.axios
        .get("/package?id=" + _.$route.params.id)
        .then((resp) => {
          _.model = resp.data.data.rows;
          if (_.model.photo_web != null) {
            _.temp_photo_main = _.model.photo_web.url;
          }

          if (_.model.photo_mobile != null) {
            _.temp_photo_thumbnail = _.model.photo_mobile.url;
          }

          _.model.tryout_ids = _.model.tryouts;
          _.model.materi_ids = _.model.subjects;
          _.model.quiz_ids = _.model.quizzes;
          _.model.live_ids = _.model.live_class
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get_materi() {
      let _ = this;
      _.axios
        .get("/theory/all")
        .then((resp) => {
          _.option_materi = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save() {
      let _ = this;
      if (_.model.photo_web == null) {
        _.$toast.warning("Foto utama harus diisi.");
        return;
      }

      if (_.model.photo_mobile == null) {
        _.$toast.warning("Foto thumbnail harus diisi.");
        return;
      }

      if (
        _.model.tryout_ids.length == 0 &&
        _.model.materi_ids.length == 0 &&
        _.model.quiz_ids.length == 0 &&
        _.model.live_ids.length == 0
      ) {
        _.$toast.warning("Paket tidak boleh kosong");
        return;
      }

      // if(_.model.diskon !== ''){
      //     _.$toast.warning('Diskon tidak boleh kosong');
      //     return;
      // }

      _.FData = new FormData();
      for (let i in _.model) {
        if (i == "tryout_ids") {
          _.FData.append(i, JSON.stringify(_.model[i]));
        } else if (i == "materi_ids") {
          _.FData.append(i, JSON.stringify(_.model[i]));
        } else if (i == "quiz_ids") {
          _.FData.append(i, JSON.stringify(_.model[i]));
        }else if(i == "live_ids"){
          _.FData.append(i, JSON.stringify(_.model[i]));
        } else {
          _.FData.append(i, _.model[i]);
        }
      }

      let API = _.$route.params.id
        ? "/package/" + _.$route.params.id
        : "/package";
      _.axios
        .post(API, _.FData)
        .then((resp) => {
          _.$router.push("/package");
          _.$toast.success(resp.data.message);
        })
        .catch((err) => {
          _.$toast.warning(err.response.data.message);
        });
    },
    AddphotoThumbnail(ev) {
      const file = ev.target.files[0];
      this.model.photo_mobile = ev.target.files[0];
      this.temp_photo_thumbnail = URL.createObjectURL(file);
    },
    AddphotoMain(ev) {
      const file = ev.target.files[0];
      this.model.photo_web = ev.target.files[0];
      this.temp_photo_main = URL.createObjectURL(file);
    },
    resetHarga() {
      let _ = this;
      _.model.harga_rupiah = 0;
      _.model.harga_saku = 0;
      _.model.harga_diamond = 0;
    },
  },
  mounted() {
    let _ = this;
    if (_.$route.params.id) {
      _.get();
    }
    _.get_materi();

    _.$root.$on("pickTryout", (data) => {
      _.model.tryout_ids.push(data);
    });

    _.$root.$on("unpickTryout", (data) => {
      _.model.tryout_ids.splice(_.model.tryout_ids.indexOf(data), 1);
    });

    _.$root.$on("pickMateri", (data) => {
      _.model.materi_ids.push(data);
    });

    _.$root.$on("unpickMateri", (data) => {
      _.model.materi_ids.splice(_.model.materi_ids.indexOf(data), 1);
    });

    _.$root.$on("pickQuiz", (data) => {
      _.model.quiz_ids.push(data);
    });

    _.$root.$on("unpickQuiz", (data) => {
      _.model.quiz_ids.splice(_.model.quiz_ids.indexOf(data), 1);
    });
    _.$root.$on("pickLive", (data) => {
      _.model.live_ids.push(data);
    });

    _.$root.$on("unpickLive", (data) => {
      _.model.live_ids.splice(_.model.live_ids.indexOf(data), 1);
    });

    
  },
  watch: {
    "model.harga_rupiah": function () {
      let _ = this;
      var diskon = 0;
      if (_.model.diskon !== "") {
        diskon = _.model.diskon;
      }

      _.model.harga_before_diskon =
        parseInt(_.model.harga_rupiah) * (100 / (100 - parseInt(diskon)));
    },
    "model.diskon": function () {
      let _ = this;
      var diskon = 0;
      if (_.model.diskon !== "") {
        diskon = _.model.diskon;
      }
      _.model.harga_before_diskon =
        parseInt(_.model.harga_rupiah) * (100 / (100 - parseInt(diskon)));
    },
  },
};
</script>
 
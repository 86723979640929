<template>
  <aside>
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.05625 14.61V7.9425H7.265C6.6983 8.24459 6.15062 8.58105 5.625 8.95V9.81875C6.09375 9.4975 6.83625 9.04375 7.1975 8.8475H7.2125V14.61H8.05625ZM9.54125 12.9788C9.6 13.7788 10.2838 14.7362 11.67 14.7362C13.2425 14.7362 14.17 13.4038 14.17 11.1475C14.17 8.73 13.1937 7.8125 11.7287 7.8125C10.5712 7.8125 9.4825 8.6525 9.4825 10.0738C9.4825 11.5238 10.5125 12.2863 11.5775 12.2863C12.51 12.2863 13.115 11.8162 13.3062 11.2987H13.34C13.335 12.9437 12.7637 14.0038 11.7087 14.0038C10.8787 14.0038 10.4488 13.4413 10.3963 12.9788H9.54125ZM13.2325 10.0825C13.2325 10.9525 12.5338 11.5575 11.7525 11.5575C11.0012 11.5575 10.3225 11.0787 10.3225 10.0575C10.3225 9.02875 11.05 8.545 11.7825 8.545C12.5737 8.545 13.2325 9.0425 13.2325 10.0825Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.25 5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V5H1.25ZM2.5 1.25C1.83696 1.25 1.20107 1.51339 0.732233 1.98223C0.263392 2.45107 0 3.08696 0 3.75L0 17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V3.75C20 3.08696 19.7366 2.45107 19.2678 1.98223C18.7989 1.51339 18.163 1.25 17.5 1.25H2.5Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25C5 1.41576 4.93415 1.57473 4.81694 1.69194C4.69973 1.80915 4.54076 1.875 4.375 1.875C4.20924 1.875 4.05027 1.80915 3.93306 1.69194C3.81585 1.57473 3.75 1.41576 3.75 1.25V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0V0ZM15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25C16.25 1.41576 16.1842 1.57473 16.0669 1.69194C15.9497 1.80915 15.7908 1.875 15.625 1.875C15.4592 1.875 15.3003 1.80915 15.1831 1.69194C15.0658 1.57473 15 1.41576 15 1.25V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0V0Z"
                      fill="white"
                    />
                  </svg>
                  Leaderboard
                </a>
              </li>
            </ol>
          </div>
        </div>
        <h2 class="content-header-title mb-0">Leaderboard</h2>
      </div>
    </div>

    <div class="content-body">
      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body card-dashboard">
                  <p><b>Pilih Leaderboard</b></p>
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" @click="reset('tryout')">
                      <a
                        class="nav-link active"
                        id="tryout-tab"
                        data-toggle="tab"
                        href="#tryout"
                        role="tab"
                        aria-controls="tryout"
                        aria-selected="true"
                        >Tryout</a
                      >
                    </li>
                    <li class="nav-item" @click="reset('quiz')">
                      <a
                        class="nav-link"
                        id="kuis-tab"
                        data-toggle="tab"
                        href="#kuis"
                        role="tab"
                        aria-controls="kuis"
                        aria-selected="false"
                        >Kuis</a
                      >
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="tryout"
                      role="tabpanel"
                      aria-labelledby="tryout-tab"
                    >
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col mb-1">
                          <div class="row">
                            <div class="col-4 input-group form-search-rounded">
                              <select
                                name=""
                                id=""
                                class="form-control"
                                v-model="id_tryout"
                              >
                                <option value="">Pilih Tryout</option>
                                <option
                                  v-for="(data, idx) in option"
                                  :key="idx"
                                  :value="data.id"
                                >
                                  {{ data.name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-2 input-group form-search-rounded">
                              <select
                                name=""
                                id=""
                                class="form-control"
                                v-model="type"
                              >
                                <option value="1">Nasional</option>
                                <option value="2">Khusus</option>
                              </select>
                            </div>
                            <div
                              class="col-3 input-group form-search-rounded"
                              v-if="type == 2"
                            >
                              <multiselect
                                v-model="college"
                                deselect-label="Can't remove this value"
                                track-by="name"
                                label="name"
                                placeholder="Pilih Kampus"
                                :options="college_list"
                                :searchable="true"
                                :allow-empty="true"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong></template
                                >
                              </multiselect>
                            </div>

                            <div
                              class="col-3 input-group form-search-rounded"
                              v-if="type == 2"
                            >
                              <multiselect
                                v-model="majors"
                                deselect-label="Can't remove this value"
                                track-by="name"
                                label="name"
                                placeholder="Pilih Jurusan"
                                :options="major_list"
                                :searchable="true"
                                :allow-empty="true"
                              >
                                <template
                                  slot="singleLabel"
                                  slot-scope="{ option }"
                                  ><strong>{{ option.name }}</strong></template
                                >
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" v-if="page.total > 0">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[0].student_id+'/'+backup[0].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <br>
                                  <b>{{ backup[0].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[0].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_1.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[0].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4" v-if="page.total > 1">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[1].student_id+'/'+backup[1].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <br>
                                  <b>{{ backup[1].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[1].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_2.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[1].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4" v-if="page.total > 2">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                  <!-- <div class="avatar-small mx-auto"></div> -->

                                  <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[2].student_id+'/'+backup[2].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <br>
                                  <b>{{ backup[2].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[2].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_3.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[2].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- TABLE START  -->
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col mb-1">
                          <div class="row">
                            <div class="col-6 input-group form-search-rounded">
                              <input
                                class="form-control py-2 border-right-0 border"
                                v-model.lazy="search"
                                type="search"
                                id="example-search-input"
                                placeholder="Masukkan Email"
                              />
                              <span class="input-group-append">
                                <div class="input-group-text bg-transparent">
                                  <i class="fa fa-search pointer"></i>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="float-right" style="display: inline-flex">
                            <div class="align-self-center text-right mr-1">
                              Menampilkan
                            </div>
                            <div class="">
                              <select v-model="limit" class="form-control">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="0">Semua</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th rowspan="2"><b>No.</b></th>
                              <th rowspan="2"><b>Name</b></th>
                              <th rowspan="2"><b>Email</b></th>
                              <th rowspan="2"><b>Nilai</b></th>
                            </tr>
                          </thead>
                          <tbody v-if="page.total == 0">
                            <tr>
                              <td colspan="4">Tidak ada data</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr v-for="(data, index) in students" :key="index">
                              <td class="text-center">
                                {{
                                  (page.current_page - 1) * limit + index + 1
                                }}
                              </td>
                              <td>{{ data.name }}</td>
                              <td>{{ data.email }}</td>
                              <td>
                                <b>{{ data.total_poin }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-5 mt-2">
                          Menampilkan
                          <b>{{ page.from }} - {{ page.to }}</b> dari
                          <b>{{ page.total }} Data</b>
                        </div>
                        <div class="col-sm-12 col-md-7 mt-1">
                          <div
                            class="dataTables_paginate paging_simple_numbers pull-right"
                          >
                            <ul class="pagination">
                              <li
                                class="paginate_button page-item"
                                v-for="(page, key) in pages"
                                :key="key"
                                :class="isCurrentPage(page) ? 'active' : ''"
                              >
                                <button
                                  type="button"
                                  class="page-link"
                                  @click="changePage(page)"
                                >
                                  {{ page }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- TABLE END  -->
                    </div>
                    <div
                      class="tab-pane fade"
                      id="kuis"
                      role="tabpanel"
                      aria-labelledby="kuis-tab"
                    >
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col mb-1">
                          <div class="row">
                            <div class="col-4 input-group form-search-rounded">
                              <select
                                name=""
                                id=""
                                class="form-control"
                                v-model="id_quiz"
                              >
                                <option value="">Pilih Kuis</option>
                                <option
                                  v-for="(data, idx) in option"
                                  :key="idx"
                                  :value="data.id"
                                >
                                  {{ data.col_matkul }} - {{ data.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4" v-if="page.total > 0">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[0].student_id+'/'+backup[0].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <b>{{ backup[0].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[0].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_1.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[0].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4" v-if="page.total > 1">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[1].student_id+'/'+backup[1].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <b>{{ backup[1].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[1].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_2.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[1].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4" v-if="page.total > 2">
                          <div class="card">
                            <div class="card-content">
                              <div class="card-body card-dashboard">
                                <div
                                  class="col text-center"
                                  style="border-bottom: 1px solid #ccc"
                                >
                                  <img
                                    :src="'https://api.kelastryout.id/storage/uploads/user/'+backup[2].student_id+'/'+backup[2].photo"
                                    class="avatar-small mx-auto"
                                    alt=""
                                  />
                                  <b>{{ backup[2].name }}</b
                                  ><br />
                                  <p>
                                    <small>{{ backup[2].email }}</small>
                                  </p>
                                </div>
                                <div class="row mt-1">
                                  <div
                                    class="col text-center"
                                    style="border-right: 1px solid #ccc"
                                  >
                                    <p>Peringkat</p>
                                    <img
                                      src="/assets/img/medal_3.png"
                                      alt=""
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <p>Nilai</p>
                                    <h1>
                                      <b>{{ backup[2].total_poin }}</b>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- TABLE START  -->
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col mb-1">
                          <div class="row">
                            <div class="col-6 input-group form-search-rounded">
                              <input
                                class="form-control py-2 border-right-0 border"
                                v-model.lazy="search"
                                type="search"
                                id="example-search-input"
                                placeholder="Masukkan Email"
                              />
                              <span class="input-group-append">
                                <div class="input-group-text bg-transparent">
                                  <i class="fa fa-search pointer"></i>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="float-right" style="display: inline-flex">
                            <div class="align-self-center text-right mr-1">
                              Menampilkan
                            </div>
                            <div class="">
                              <select v-model="limit" class="form-control">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="0">Semua</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th rowspan="2"><b>No.</b></th>
                              <th rowspan="2"><b>Name</b></th>
                              <th rowspan="2"><b>Email</b></th>
                              <th rowspan="2"><b>Nilai</b></th>
                            </tr>
                          </thead>
                          <tbody v-if="page.total == 0">
                            <tr>
                              <td colspan="4">Tidak ada data</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr v-for="(data, index) in students" :key="index">
                              <td class="text-center">
                                {{
                                  (page.current_page - 1) * limit + index + 1
                                }}
                              </td>
                              <td>{{ data.name }}</td>
                              <td>{{ data.email }}</td>
                              <td>
                                <b>{{ data.total_poin }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-md-5 mt-2">
                          Menampilkan
                          <b>{{ page.from }} - {{ page.to }}</b> dari
                          <b>{{ page.total }} Data</b>
                        </div>
                        <div class="col-sm-12 col-md-7 mt-1">
                          <div
                            class="dataTables_paginate paging_simple_numbers pull-right"
                          >
                            <ul class="pagination">
                              <li
                                class="paginate_button page-item"
                                v-for="(page, key) in pages"
                                :key="key"
                                :class="isCurrentPage(page) ? 'active' : ''"
                              >
                                <button
                                  type="button"
                                  class="page-link"
                                  @click="changePage(page)"
                                >
                                  {{ page }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- TABLE END  -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </aside>
</template>
<style>
.card .card {
  box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;
}
</style>

<script>
export default {
  name: "LeaderboardIndex",
  data() {
    return {
      students: [],
      search: "",
      limit: 0,
      backup: [],
      page: {
        from: 0,
        to: 0,
        total: 0,
        current_page: 0,
        last_page: 0,
      },
      id_tryout: "",
      id_quiz: "",
      option: [],
      type: 1,
      college: "",
      majors: "",
      college_list: [],
      major_list: [],
    };
  },
  methods: {
    reset(x) {
      let _ = this;
      _.students = [];
      _.search = "";
      _.limit = 0;
      _.backup = [];
      _.page = {
        from: 0,
        to: 0,
        total: 0,
        current_page: 0,
        last_page: 0,
      };
      _.id_tryout = "";
      _.id_quiz = "";
      _.option = [];
      _.type = 1;
      _.college = {};
      _.majors = {};

      if (x == "tryout") {
        _.get("tryout");
      } else {
        _.get("quiz");
      }
    },
    isCurrentPage(page) {
      return this.page.current_page === page;
    },
    changePage(page) {
      let _ = this;
      if (page > _.page.last_page) {
        page = _.page.last_page;
      }
      _.page.current_page = page;
      _.students = _.backup;
      let limited_data = _.students.slice(
        (_.page.current_page - 1) * _.limit,
        _.limit * _.page.current_page
      );
      _.page.from = (_.page.current_page - 1) * _.limit + 1;
      _.page.to =
        _.limit * _.page.current_page > _.page.total
          ? _.page.total
          : _.limit * _.page.current_page;
      _.students = limited_data;
      window.scrollTo(0, 0);
    },
    getLeaderboard(x) {
      let _ = this;
      let api =
        x == "tryout"
          ? "/tryout/leaderboard?id_tryout=" + _.id_tryout
          : "/quiz/leaderboard?id_quiz=" + _.id_quiz;
      if (_.college != "" && x == "tryout") {
        api += "&college=" + _.college.name;
      }
      if (_.majors != "" && x == "tryout") {
        api += "&majors=" + _.majors.name;
      }
      _.axios
        .get(api)
        .then((resp) => {
          _.students = resp.data.data.rows;
          _.backup = _.students;
          _.limit = 10;
          _.paginate();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    get(x) {
      let _ = this;
      let api = x == "tryout" ? "/tryout/all" : "/quiz";
      _.axios
        .get(api)
        .then((resp) => {
          _.option = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getCollages() {
      let _ = this;
      let api = "/college";
      _.axios
        .get(api)
        .then((resp) => {
          _.college_list = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getMajors() {
      let _ = this;
      let api = "/major";
      _.axios
        .get(api)
        .then((resp) => {
          _.major_list = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    paginate() {
      let _ = this;

      _.students = _.backup;
      let searched_data = _.students.filter(function (data) {
        return data.email.indexOf(_.search) > -1;
      });

      _.students = searched_data;
      _.page.total = _.students.length;

      let limited_data = searched_data.slice(
        0,
        _.limit == 0 ? _.page.total : _.limit
      );

      _.students = limited_data;
      _.page.from = _.page.total > 0 ? 1 : 0;
      _.page.to =
        _.limit == 0 ? _.page.total : _.page.total > 10 ? 10 : _.page.total;
      _.page.current_page = 1;
      _.page.last_page = Math.ceil(
        _.page.total / (_.limit == 0 ? _.page.total : _.limit)
      );
    },
  },
  watch: {
    search: function () {
      this.paginate();
    },
    limit: function () {
      this.paginate();
    },
    id_tryout: function () {
      if (this.id_tryout != "") {
        this.getLeaderboard("tryout");
      }
    },
    id_quiz: function () {
      if (this.id_quiz != "") {
        this.getLeaderboard("quiz");
      }
    },
    type: function () {
      if (this.type != "") {
        if (this.type == 2) {
          this.getCollages();
          this.getMajors();
        } else {
          this.college = "";
          this.majors = "";
        }
      }
    },
    college: function () {
      this.getLeaderboard("tryout");
    },
    majors: function () {
      this.getLeaderboard("tryout");
    },
  },
  computed: {
    pages() {
      let current = this.page.current_page;
      let last = this.page.last_page;
      let delta = 2;
      let left = current - delta;
      let right = current + delta + 1;
      let range = [];
      let rangeWithDots = [];
      let l = 0;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  mounted() {
    this.get("tryout");
  },
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
// import PageNotFound from '../views/404.vue'
// import ComingSoon from '../views/ComingSoon.vue'

import UserIndex from '../views/user/Index.vue'
import UserForm from '../views/user/Form.vue'
import UserDetail from '../views/user/Detail.vue'
import EditProfile from '../views/user/EditProfile.vue'

import TryoutIndex from '../views/tryout/Index.vue'
import TryoutForm from '../views/tryout/Form.vue'
import TryoutSubIndex from '../views/tryout/subkategori/Index.vue'
import TryoutSubForm from '../views/tryout/subkategori/Form.vue'
import TryoutSubCourseIndex from '../views/tryout/subkategori/mapel/Index.vue'
import TryoutSubCourseForm from '../views/tryout/subkategori/mapel/Form.vue'
import TryoutSubCourseQuestionIndex from '../views/tryout/subkategori/mapel/Detail.vue'
import TryoutIndexStudent from '../views/tryout/Index_Students.vue'
import TryoutSubQuestionIndex from '../views/tryout/subkategori/Detail.vue'

import BankSoalIndex from '../views/bank_soal/Index.vue'
import BankSoalForm from '../views/bank_soal/Form.vue'
import BankSoalDetail from '../views/bank_soal/Detail.vue'
import BankSoalQuestionForm from '../views/bank_soal/soal/Form.vue'

import EventIndex from '../views/event/Index.vue'
import EventForm from '../views/event/Form.vue'

import MasterDataIndex from '../views/master_data/Index.vue'
import JenjangForm from '../views/master_data/jenjang/Form.vue'
import KategoriForm from '../views/master_data/kategori/Form.vue'

import MateriIndex from '../views/materi/Index.vue'
import MateriForm from '../views/materi/Form.vue'
import MateriSubIndex from '../views/materi/sub/Index.vue'
import MateriKontenIndex from '../views/materi/sub/content/Index.vue'
import MateriKontenFormAdd from '../views/materi/sub/content/Form.vue'
import MateriKontenFormEdit from '../views/materi/sub/content/Form.vue'
import MateriKontenMapelIndex from '../views/materi/sub/content/mapel/Index.vue'
import MateriKontenMapelQuestionIndex from '../views/materi/sub/content/mapel/Detail.vue'
import MateriKontenMapelQuestionDetail from '../views/materi/sub/content/Detail.vue'
import MateriKontenDiskusiIndex from '../views/materi/sub/content/Index_Diskusi.vue'

import QuizIndex from '../views/quiz/Index.vue'
import QuizForm from '../views/quiz/Form.vue'
import QuizCourse from '../views/quiz/mapel/Index.vue'
import QuizCourseQuestion from '../views/quiz/mapel/Detail.vue'
import QuizQuestion from '../views/quiz/Detail.vue'
import QuizIndexStudent from '../views/quiz/Index_Students.vue'

import PackageIndex from '../views/package/Index.vue'
import PackageForm from '../views/package/Form.vue'

import TransactionIndex from '../views/transaction/Index.vue'
import TransactionDetail from '../views/transaction/Detail.vue'

import LeaderboardIndex from '../views/leaderboard/Index.vue'

import SettingIndex from '../views/setting/Index.vue'

import RoleIndex from '../views/role/Index.vue'
import RoleForm from '../views/role/Form.vue'
import RolePermissions from '../views/role/FormPermissions.vue'

import AdminIndex from '../views/admin/Index.vue'
import AdminForm from '../views/admin/Form.vue'

import PushNotificationIndex from '../views/push_notification/Index.vue'
import PushNotificationForm from '../views/push_notification/Form.vue'

import VoucherIndex from '../views/voucher_soal/Index.vue'
import VoucherForm from '../views/voucher_soal/Form.vue'

import RewardIndex from '../views/reward/Index.vue'
import RewardForm from '../views/reward/Form.vue'

import Artikel from '../views/artikel/Index.vue'
import AddArtikel from '../views/artikel/Add.vue'
import Download from '../views/user/Download.vue'

import LiveClassIndex from '../views/live_class/Index.vue'
import LiveClassForm from '../views/live_class/Form.vue'
import LiveClassDiskusiIndex from '../views/live_class/Index_Diskusi.vue'

import ChatIndex from '../views/chat/Index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
                meta: {
                    permissions: ['dashboard/view']
                }
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile
            },
            {
                path: '/user',
                name: 'UserIndex',
                component: UserIndex,
                meta: {
                    permissions: ['user/read']
                }
            },
            {
                path: '/user/add',
                name: 'UserForm',
                component: UserForm,
                meta: {
                    permissions: ['user/create']
                }
            },
            {
                path: '/user/download',
                name: 'Download',
                component: Download,
                meta: {
                    permissions: ['user/create']
                }
            },
            {
                path: '/user/:id',
                name: 'UserDetail',
                component: UserDetail,
                meta: {
                    permissions: ['user/detail']
                }
            },
            {
                path: '/artikel',
                name: 'Artikel',
                component: Artikel,
              
               
            },
              {
                path: '/add_artikel',
                name: 'AddArtikel',
                component: AddArtikel,
              
               
            },
            {
                path: '/edit_artikel/:id',
                name: 'EditArtikel',
                component: AddArtikel,
              
               
            },
            {
                path: '/tryout',
                name: 'TryoutIndex',
                component: TryoutIndex,
                meta: {
                    permissions: ['tryout/read']
                }
            },
            {
                path: '/tryout/add',
                name: 'TryoutFormAdd',
                component: TryoutForm,
                meta: {
                    permissions: ['tryout/create']
                }
            },
            {
                path: '/tryout/edit/:id',
                name: 'TryoutFormEdit',
                component: TryoutForm,
                meta: {
                    permissions: ['tryout/update']
                }
            },
            {
                path: '/tryout/:id',
                name: 'TryoutSubIndex',
                component: TryoutSubIndex,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/add',
                name: 'TryoutSubFormAdd',
                component: TryoutSubForm,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/edit/:id_sub',
                name: 'TryoutSubFormEdit',
                component: TryoutSubForm,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/sub/:id_sub',
                name: 'TryoutSubCourseIndex',
                component: TryoutSubCourseIndex,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/sub/:id_sub/add',
                name: 'TryoutSubCourseForm',
                component: TryoutSubCourseForm,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/sub/:id_sub/course/:id_course',
                name: 'TryoutSubCourseQuestionIndex',
                component: TryoutSubCourseQuestionIndex,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/sub/:id_sub/question',
                name: 'TryoutSubQuestionIndex',
                component: TryoutSubQuestionIndex,
                meta: {
                    permissions: ['tryout/detail']
                }
            },
            {
                path: '/tryout/:id/students',
                name: 'TryoutIndexStudent',
                component: TryoutIndexStudent,
                meta: {
                    permissions: ['tryout/view student']
                }
            },
            {
                path: '/bank_soal',
                name: 'BankSoalIndex',
                component: BankSoalIndex,
                meta: {
                    permissions: ['question/read']
                }
            },
            {
                path: '/bank_soal/add',
                name: 'BankSoalFormAdd',
                component: BankSoalForm,
                meta: {
                    permissions: ['question/create']
                }
            },
            {
                path: '/bank_soal/edit/:id',
                name: 'BankSoalFormEdit',
                component: BankSoalForm,
                meta: {
                    permissions: ['question/update']
                }
            },
            {
                path: '/bank_soal/:id',
                name: 'BankSoalDetail',
                component: BankSoalDetail,
                meta: {
                    permissions: ['question/detail']
                }
            },
            {
                path: '/bank_soal/:id/add',
                name: 'BankSoalQuestionFormAdd',
                component: BankSoalQuestionForm,
                meta: {
                    permissions: ['question/detail']
                }
            },
            {
                path: '/bank_soal/:id/edit/:id_soal',
                name: 'BankSoalQuestionFormEdit',
                component: BankSoalQuestionForm,
                meta: {
                    permissions: ['question/detail']
                }
            },
            {
                path: '/event',
                name: 'EventIndex',
                component: EventIndex,
                meta: {
                    permissions: ['event/read']
                }
            },
            {
                path: '/event/add',
                name: 'EventFormAdd',
                component: EventForm,
                meta: {
                    permissions: ['event/create']
                }
            },
            {
                path: '/event/:id',
                name: 'EventFormEdit',
                component: EventForm,
                meta: {
                    permissions: ['event/detail']
                }
            },
            {
                path: '/master',
                name: 'MasterDataIndex',
                component: MasterDataIndex,
                meta: {
                    permissions: ['master/read']
                }
            },
            {
                path: '/jenjang/add',
                name: 'JenjangFormAdd',
                component: JenjangForm,
                meta: {
                    permissions: ['master/create']
                }
            },
            {
                path: '/jenjang/edit/:id',
                name: 'JenjangFormEdit',
                component: JenjangForm,
                meta: {
                    permissions: ['master/update']
                }
            },
            {
                path: '/kategori/add',
                name: 'KategoriFormAdd',
                component: KategoriForm,
                meta: {
                    permissions: ['master/create']
                }
            },
            {
                path: '/kategori/edit/:id',
                name: 'KategoriFormEdit',
                component: KategoriForm,
                meta: {
                    permissions: ['master/update']
                }
            },
            {
                path: '/quiz',
                name: 'QuizIndex',
                component: QuizIndex,
                meta: {
                    permissions: ['quiz/read']
                }
            },
            {
                path: '/quiz/add',
                name: 'QuizFormAdd',
                component: QuizForm,
                meta: {
                    permissions: ['quiz/create']
                }
            },
            {
                path: '/quiz/edit/:id',
                name: 'QuizFormEdit',
                component: QuizForm,
                meta: {
                    permissions: ['quiz/update']
                }
            },
            {
                path: '/quiz/:id',
                name: 'QuizCourse',
                component: QuizCourse,
                meta: {
                    permissions: ['quiz/detail']
                }
            },
            {
                path: '/quiz/:id/course/:id_course',
                name: 'QuizCourseQuestion',
                component: QuizCourseQuestion,
                meta: {
                    permissions: ['quiz/detail']
                }
            },
            {
                path: '/quiz/:id/questions',
                name: 'QuizQuestion',
                component: QuizQuestion,
                meta: {
                    permissions: ['quiz/detail']
                }
            },
            {
                path: '/quiz/:id/students',
                name: 'QuizIndexStudent',
                component: QuizIndexStudent,
                // meta: {
                //     permissions: ['tryout/view student']
                // }
            },
            {
                path: '/package',
                name: 'PackageIndex',
                component: PackageIndex,
                meta: {
                    permissions: ['package/read']
                }
            },
            {
                path: '/package/add',
                name: 'PackageFormAdd',
                component: PackageForm,
                meta: {
                    permissions: ['package/create']
                }
            },
            {
                path: '/package/edit/:id',
                name: 'PackageFormEdit',
                component: PackageForm,
                meta: {
                    permissions: ['package/update']
                }
            },
            {
                path: '/transaction',
                name: 'TransactionIndex',
                component: TransactionIndex,
                meta: {
                    permissions: ['transaction/read']
                }
            },
            {
                path: '/transaction/:id',
                name: 'TransactionDetail',
                component: TransactionDetail,
                meta: {
                    permissions: ['transaction/detail']
                }
            },
            {
                path: '/leaderboard',
                name: 'LeaderboardIndex',
                component: LeaderboardIndex,
                meta: {
                    permissions: ['leaderboard/read']
                }
            },
            {
                path: '/setting',
                name: 'SettingIndex',
                component: SettingIndex,
                meta: {
                    permissions: ['setting/read']
                }
            },
            {
                path: '/materi',
                name: 'MateriIndex',
                component: MateriIndex,
                meta: {
                    permissions: ['theory/read']
                }
            },
            {
                path: '/materi/add',
                name: 'MateriFormAdd',
                component: MateriForm,
                meta: {
                    permissions: ['theory/create']
                }
            },
            {
                path: '/materi/edit/:id',
                name: 'MateriFormEdit',
                component: MateriForm,
                meta: {
                    permissions: ['theory/update']
                }
            },
            {
                path: '/materi/:id/sub',
                name: 'MateriSubIndex',
                component: MateriSubIndex,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten',
                name: 'MateriKontenIndex',
                component: MateriKontenIndex,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten/add',
                name: 'MateriKontenFormAdd',
                component: MateriKontenFormAdd,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/edit/:id_konten',
                name: 'MateriKontenFormEdit',
                component: MateriKontenFormEdit,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten/:id_konten',
                name: 'MateriKontenMapelIndex',
                component: MateriKontenMapelIndex,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten/:id_konten/course/:id_course',
                name: 'MateriKontenMapelQuestionIndex',
                component: MateriKontenMapelQuestionIndex,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten/:id_konten/question',
                name: 'MateriKontenMapelQuestionDetail',
                component: MateriKontenMapelQuestionDetail,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/sub/:id/konten/:id_subject_content/diskusi',
                name: 'MateriKontenDiskusiIndex',
                component: MateriKontenDiskusiIndex,
                meta: {
                    permissions: ['theory/detail']
                }
            },
            {
                path: '/voucher',
                name: 'VoucherIndex',
                component: VoucherIndex
            },
            {
                path: '/voucher/add',
                name: 'VoucherFormAdd',
                component: VoucherForm
            },
            {
                path: '/voucher/:id',
                name: 'VoucherFormEdit',
                component: VoucherForm
            },
            {
                path: '/reward',
                name: 'RewardIndex',
                component: RewardIndex
            },
            {
                path: '/reward/add',
                name: 'RewardFormAdd',
                component: RewardForm
            },
            {
                path: '/reward/:id',
                name: 'RewardFormEdit',
                component: RewardForm
            },
            {
                path: '/live',
                name: 'LiveClassIndex',
                component: LiveClassIndex
            },
            {
                path: '/live/add',
                name: 'LiveClassFormAdd',
                component: LiveClassForm
            },
            {
                path: '/live/:id',
                name: 'LiveClassFormEdit',
                component: LiveClassForm
            },
            {
                path: '/live/:id/diskusi',
                name: 'LiveClassDiskusiIndex',
                component: LiveClassDiskusiIndex
            },
            {
                path: '/admin',
                name: 'AdminIndex',
                component: AdminIndex,
                meta: {
                    permissions: ['admin/read']
                }
            },
            {
                path: '/admin/add',
                name: 'AdminFormAdd',
                component: AdminForm,
                meta: {
                    permissions: ['admin/create']
                }
            },
            {
                path: '/admin/:id',
                name: 'AdminFormEdit',
                component: AdminForm,
                meta: {
                    permissions: ['admin/detail']
                }
            },
            {
                path: '/role',
                name: 'RoleIndex',
                component: RoleIndex,
                meta: {
                    permissions: ['role/read']
                }
            },
            {
                path: '/role/add',
                name: 'RoleFormAdd',
                component: RoleForm,
                meta: {
                    permissions: ['role/create']
                }
            },
            {
                path: '/role/:id',
                name: 'RoleFormEdit',
                component: RoleForm,
                meta: {
                    permissions: ['role/detail']
                }
            },
            {
                path: '/role/:id/permissions',
                name: 'RolePermissions',
                component: RolePermissions,
                meta: {
                    permissions: ['role/update']
                }
            },
            {
                path: '/notification',
                name: 'PushNotificationIndex',
                component: PushNotificationIndex
            },
            {
                path: '/notification/add',
                name: 'PushNotificationFormAdd',
                component: PushNotificationForm
            },
            {
                path: '/notification/:id',
                name: 'PushNotificationFormEdit',
                component: PushNotificationForm
            },
            {
                path: '/chat',
                name: 'ChatIndex',
                component: ChatIndex
            },
            // {
            //     path: '/:pathMatch(.*)*',
            //     name: 'PageNotFound',
            //     component: PageNotFound
            // },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    }
    else if ( from.name == 'Login' && ( !store.state.token && !localStorage.getItem('token') ) ) {
        Vue.$toast.warning('Silahkan Login Terlebih Dahulu')
        next({name: 'Login'})
    }
    else if (to.meta.permissions){
        let permissions = to.meta.permissions.toString().split('')
        let isAllowed = false
        permissions.forEach(elm => {
            if (localStorage.getItem('permissions').split('').indexOf(elm) != -1 ) {
                isAllowed = true
            }
        });
        if (isAllowed){
            next()
        } else{
            Vue.$toast.warning('Maaf, anda tidak memiliki akses')
            if (localStorage.getItem('permissions').split(',').indexOf('dashboard/view') != -1 ) {
                next({name: 'Home'})
            } else {
                next({name: 'EditProfile'})
            }
        }
    }
    else {
        next()
    }
})

export default router

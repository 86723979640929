<template>
    <div class="content-body">
        <div class="mb-2">
            <router-link to="/">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 0.5H15.5V5.75H8.5V0.5ZM8.5 15.375V6.625H15.5V15.375H8.5ZM0.625 15.375V10.125H7.625V15.375H0.625ZM0.625 9.25V0.5H7.625V9.25H0.625ZM1.5 1.375V8.375H6.75V1.375H1.5ZM9.375 1.375V4.875H14.625V1.375H9.375ZM9.375 7.5V14.5H14.625V7.5H9.375ZM1.5 11V14.5H6.75V11H1.5Z" fill="white"/>
                </svg>
                <span class="menu-title"> Dashboard</span>
            </router-link>
        </div>
        <div class="row">
            <div class="col d-flex align-items-stretch" v-if="setting.total_transaksi">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <img src="/assets/img/icon/money-bag-1.png" class="img-fluid" style="width:50px" alt="">
                            </div>
                            <div class="ic-title large">{{ counters.transaction | currency }}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Total Transaksi">Total Transaksi</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch" v-if="setting.total_materi">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <img src="/assets/img/icon/ion_book-outline.png" class="img-fluid" style="width:50px" alt="">
                            </div>
                            <div class="ic-title large">{{ counters.materi | numFormat }}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Total Materi">Total Materi</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch" v-if="setting.total_tryout">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <img src="/assets/img/icon/tryout.png" class="img-fluid" style="width:50px" alt="">
                            </div>
                            <div class="ic-title large">{{ counters.tryout | numFormat }}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Total Tryout">Total Try Out</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch"  v-if="setting.total_event">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <img src="/assets/img/icon/bi_calendar-date.png" class="img-fluid" style="width:50px" alt="">
                            </div>
                            <div class="ic-title large">{{ counters.event | numFormat }}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Total Event">Total Event</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col d-flex align-items-stretch"  v-if="setting.total_kuis">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                <img src="/assets/img/icon/tryout.png" class="img-fluid" style="width:50px" alt="">
                            </div>
                            <div class="ic-title large">{{ counters.quiz | numFormat }}</div>
                            <p class="line-ellipsis auto-truncate" data-toggle="tooltip" data-placement="bottom" data-original-title="Total Quiz">Total Quiz</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8 d-flex align-items-stretch" v-if="setting.grafik_visitor">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <div class="container">
                                <div class="row">
                                    <div class="col align-self-center">
                                        <p class="f-18 m-0">Jumlah Visitor</p>
                                    </div>
                                    <div class="col align-self-center">
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Tanggal Mulai"
                                            style="width: 100%;"
                                            v-model="start_date">
                                        </date-picker>
                                    </div>
                                    <div class="col align-self-center">
                                        <date-picker
                                            format="DD/MM/YYYY"
                                            type="date"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Tanggal Akhir"
                                            style="width: 100%;"
                                            v-model="end_date">
                                        </date-picker>
                                    </div>
                                    <button class="btn btn-success" @click="getVisitor(), done=false">Terapkan</button>                          
                                </div> 
                                <apexchart type="bar" :options="options" :series="series" v-if="done" height="330"></apexchart>  
                            </div>                         
                        </div>
                    </div>
                </div>
            </div>
            <div class="col d-flex align-items-stretch"  v-if="setting.total_user">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <p class="f-18">Jumlah Users : <b>{{ ages.total | numFormat }}</b></p>
                            <div class="row">
                                <div class="col text-center">
                                    <img src="/assets/img/icon/user-aktif.png" class="img-fluid" alt="" style="width: 83%">                                    
                                    <p class="f-20 mt-1"><b>{{ status.active | numFormat }}</b></p>
                                </div>
                                <div class="col text-center">
                                    <img src="/assets/img/icon/user-tidakaktif.png" class="img-fluid" alt="">
                                    <p class="f-20 mt-1"><b>{{ status.nonactive | numFormat }}</b></p>
                                </div>
                                <div class="col text-center">
                                    <img src="/assets/img/icon/user-premium.png" class="img-fluid" alt="">
                                    <p class="f-20 mt-1"><b>{{ status.premium | numFormat }}</b></p>
                                </div>
                            </div>
                            <hr>
                            <p class="f-18"><b>Usia Users</b></p>
                            <p class="f-16">Usia 0 - 20 Tahun : <b>{{ ages.under20 | numFormat }}</b></p>
                            <p class="f-16">Usia 21 - 30 Tahun : <b>{{ ages.under30 | numFormat }}</b></p>
                            <p class="f-16">Usia 31 - 40 Tahun : <b>{{ ages.under40 | numFormat }}</b></p>
                            <p class="f-16">Usia 41 - 50 Tahun : <b>{{ ages.under50 | numFormat }}</b></p>
                            <p class="f-16">Usia 51 Tahun Keatas : <b>{{ ages.upper51 | numFormat }}</b></p>
                            <p class="f-16">Usia tidak diketahui : <b>{{ ages.undefined | numFormat }}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex align-items-stretch" v-if="setting.total_event">
                <div class="card w-100 ">
                    <div class="card-content">
                        <div class="card-body ic-statistic">
                            <p class="f-18 ml-1">Event</p>
                            <div class="col-6 mb-1">
                                <div class="row">
                                    <div class="col align-self-center">
                                        <p class="text-success m-0 f-18">Bulan <b>{{ selectedMonth }}</b></p>
                                    </div>
                                    <div class="col align-self-center">
                                        <date-picker
                                            format="MMM, YYYY"
                                            type="month"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih Bulan"
                                            style="width: 100%;"
                                            v-model="month"
                                            @change="changeMonth()">
                                        </date-picker>
                                    </div>
                                    <!-- <button class="btn btn-success" @click="changeMonth()">Terapkan</button> -->
                                </div>
                            </div>   
                            <vc-calendar is-expanded ref="calendar" :attributes="events"></vc-calendar>
                            <div class="row" v-for="(data, index) in events.
                                filter(data => 
                                    (data.dates.start >= $moment(month).startOf('month').format('YYYY-MM-DD') && 
                                    data.dates.start <= $moment(month).endOf('month').format('YYYY-MM-DD'))
                                    ||
                                    (data.dates.end >= $moment(month).startOf('month').format('YYYY-MM-DD') && 
                                    data.dates.end <= $moment(month).endOf('month').format('YYYY-MM-DD'))
                                )"
                                :key="index" 
                                :style="'border-left: 6px solid ' + whatColor(data.highlight)" 
                                style="font-size: 16px; padding: 15px 15px;">
                                <div class="col">
                                    {{ data.popover.label }}
                                </div>
                                <div class="col text-right">
                                    {{ data.dates.start | moment('LL') }} - {{ data.dates.end | moment('LL') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>  
    </div>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            counters: {},
            status: {},
            ages: {},
            eventCategories: [],
            events: [],
            selectedMonth: null,      
            month: null,
            setting: {},
            options: {
                chart: { id: 'vuechart-example' },
                xaxis: { 
                    categories: [],
                    labels: {
                        rotate: 0
                    }
                },
                colors: ['#78D192'],
                plotOptions: { bar: { endingShape: 'rounded' } },
                grid: {
                    show: true,
                    xaxis: { lines: { show: true } },   
                    yaxis: { lines: { show: true } }
                }
            },
            series: [{
                name: 'Jumlah Visitor',
                data: []
            }],
            start_date: '',
            end_date: '',
            done: false
        }
    },
    methods: {
        async changeMonth() {
            let _ = this
            _.selectedMonth = _.$moment(_.month).format('MMM YYYY')
            const calendar = this.$refs.calendar
            await calendar.move({ month: parseInt(_.$moment(_.month).format('M')), year: parseInt(_.$moment(_.month).format('YYYY')) })            
        },
        getHeader () {
            let _ = this
            _.axios.get('/dashboard/header')
                .then(resp=>{
                    _.counters = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getStudents () {
            let _ = this
            _.axios.get('/dashboard/student')
                .then(resp=>{
                    _.ages      = resp.data.data.ages
                    _.status    = resp.data.data.status
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getEvents () {
            let _ = this
            _.axios.get('/event')
                .then(resp=>{
                    let i = 1
                    let colors = ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'indigo', 'purple', 'pink']
                    _.events = []
                    resp.data.data.rows.forEach(elm => {
                        _.events.push({
                            key: i,
                            highlight: colors[Math.floor(Math.random() * colors.length)], 
                            dot: false,       
                            bar: false,       
                            content: 'black',  
                            popover: { label: elm.name },
                            dates: { start: elm.start_date, end: elm.end_date },
                            excludeDates: null,
                            order: 0
                        })
                        i++
                    });                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        whatColor(data){
            let $color = ''
            switch (data) {
                case 'gray':
                    $color = '#718096'
                    break;

                case 'red':
                    $color = '#e53e3e'
                    break;

                case 'orange':
                    $color = '#dd6b20'
                    break;

                case 'yellow':
                    $color = '#d69e2e'
                    break;

                case 'green':
                    $color = '#38a169'
                    break;

                case 'teal':
                    $color = '#319795'
                    break;

                case 'blue':
                    $color = '#3182ce'
                    break;

                case 'indigo':
                    $color = '#5a67d8'
                    break;

                case 'purple':
                    $color = '#805ad5'
                    break;

                case 'pink':
                    $color = '#d53f8c'
                    break;
            
                default:
                    break;
            }
            return $color;
        },
        getSetting(){
            let _ = this
            _.axios.get('/setting')
                .then(resp=>{
                    if (resp.data.data){
                        _.setting = resp.data.data
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getVisitor(){
            let _ = this
            _.axios.get('/dashboard/visitor?start_date=' + _.start_date + '&end_date=' + _.end_date)
                .then(resp=>{
                    if (resp.data.data){
                        _.series[0].data = []
                        _.options.xaxis.categories = []
                        
                        let res = resp.data.data
                        res.forEach(elm => {
                            _.series[0].data.push(elm.visitors)
                            _.options.xaxis.categories.push(elm.access_date.split(", "))
                        });
                        _.done = true
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.selectedMonth = _.$moment().format('MMM YYYY')      
        _.month = _.$moment().format('YYYY-MM-DD')
        _.end_date = _.$moment().format('YYYY-MM-DD')
        _.start_date = _.$moment().day(-5).format('YYYY-MM-DD')

        _.getSetting()
        _.getHeader()
        _.getStudents()
        _.getEvents()
        _.getVisitor()
    }
}
</script>

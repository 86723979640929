<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.875 2.625C8.52397 2.625 9.15837 2.81744 9.69797 3.17799C10.2376 3.53854 10.6581 4.051 10.9065 4.65057C11.1548 5.25014 11.2198 5.90989 11.0932 6.54639C10.9666 7.18289 10.6541 7.76755 10.1952 8.22644C9.73631 8.68534 9.15164 8.99784 8.51514 9.12445C7.87864 9.25106 7.21889 9.18608 6.61932 8.93773C6.01975 8.68938 5.50729 8.26881 5.14674 7.72921C4.78619 7.18962 4.59375 6.55522 4.59375 5.90625C4.59375 5.03601 4.93945 4.20141 5.55481 3.58606C6.17016 2.9707 7.00476 2.625 7.875 2.625ZM7.875 1.3125C6.96644 1.3125 6.07829 1.58192 5.32285 2.08669C4.56741 2.59145 3.97862 3.3089 3.63093 4.1483C3.28324 4.9877 3.19227 5.91135 3.36952 6.80245C3.54677 7.69355 3.98428 8.51207 4.62673 9.15452C5.26918 9.79697 6.08771 10.2345 6.97881 10.4117C7.86991 10.589 8.79356 10.498 9.63295 10.1503C10.4724 9.80263 11.1898 9.21384 11.6946 8.4584C12.1993 7.70296 12.4688 6.81481 12.4688 5.90625C12.4688 4.68791 11.9848 3.51947 11.1233 2.65798C10.2618 1.79648 9.09334 1.3125 7.875 1.3125Z" fill="white"/>
                                        <path d="M14.4375 19.0312C14.4375 19.3937 14.1437 19.6875 13.7812 19.6875C13.4188 19.6875 13.125 19.3937 13.125 19.0312V16.4062C13.125 15.536 12.7793 14.7014 12.1639 14.0861C11.5486 13.4707 10.714 13.125 9.84375 13.125H5.90625C5.03601 13.125 4.20141 13.4707 3.58606 14.0861C2.9707 14.7014 2.625 15.536 2.625 16.4062V19.0312C2.625 19.3937 2.33119 19.6875 1.96875 19.6875C1.60631 19.6875 1.3125 19.3937 1.3125 19.0312V16.4062C1.3125 15.1879 1.79648 14.0195 2.65798 13.158C3.51947 12.2965 4.68791 11.8125 5.90625 11.8125H9.84375C11.0621 11.8125 12.2305 12.2965 13.092 13.158C13.9535 14.0195 14.4375 15.1879 14.4375 16.4062V19.0312Z" fill="white"/>
                                        <path d="M14.4375 3.28125C14.4375 2.91881 14.7313 2.625 15.0938 2.625H20.3438C20.7062 2.625 21 2.91881 21 3.28125C21 3.64369 20.7062 3.9375 20.3438 3.9375H15.0938C14.7313 3.9375 14.4375 3.64369 14.4375 3.28125Z" fill="white"/>
                                        <path d="M14.4375 6.5625C14.4375 6.20006 14.7313 5.90625 15.0938 5.90625H20.3438C20.7062 5.90625 21 6.20006 21 6.5625C21 6.92494 20.7062 7.21875 20.3438 7.21875H15.0938C14.7313 7.21875 14.4375 6.92494 14.4375 6.5625Z" fill="white"/>
                                        <path d="M14.4375 9.84375C14.4375 9.48131 14.7313 9.1875 15.0938 9.1875H18.375C18.7374 9.1875 19.0312 9.48131 19.0312 9.84375C19.0312 10.2062 18.7374 10.5 18.375 10.5H15.0937C14.7313 10.5 14.4375 10.2062 14.4375 9.84375Z" fill="white"/>
                                    </svg>
                                    List User / Detail
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/user">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">Detail User</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <!-- INFORMASI USER -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Informasi User</b></h4>
                                    <div class="row">                                        
                                        <div class="col-2">
                                            <div style="text-align: -webkit-center;">
                                                <div v-if="temp_photo != null">
                                                    <img :src="temp_photo" class="img-fluid" alt="">
                                                </div>
                                                <div v-else 
                                                    style="
                                                        text-align: center; 
                                                        height: 150px; 
                                                        width: 150px; 
                                                        border: 1px solid #bdbdbd; 
                                                        border-radius: 50%; 
                                                        background: #FAFAFA;
                                                    ">
                                                    <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                        <i class="fa fa-camera" aria-hidden="true"></i> 
                                                        <p style="font-size: 14px">Tidak ada foto</p>
                                                    </label>
                                                    <input id="file-upload" class="input-file-avatar d-none" type="file" disabled>
                                                </div>                                                        
                                            </div>
                                        </div>
                                        <div class="col">
                                            <p class="mb-0">Nama Lengkap</p>
                                            <p class="f-16"><b>{{model.name}}</b></p>

                                            <p class="mb-0">Jenis Kelamin</p>
                                            <p class="f-16"><b>{{model.col_jenkel}}</b></p>

                                            <p class="mb-0">Email</p>
                                            <p class="f-16"><b>{{model.email}}</b></p>

                                            <p class="mb-0">Nomor Handphone</p>
                                            <p class="f-16"><b>{{model.no_telp == 'null' ? '-' : model.no_telp}}</b></p>

                                            <p class="mb-0">Alamat Lengkap</p>
                                            <p class="f-16 text-justify"><b>{{model.alamat ? model.alamat : '-'}}</b></p>

                                            <p class="mb-0">Kabupaten / Kota</p>
                                            <p class="f-16" v-if="col_kabupaten.length > 0"><b>{{col_kabupaten[0].type}} {{col_kabupaten[0].city_name}}</b></p>
                                            <p class="f-16" v-else><b>-</b></p>
                                            
                                            <p class="mb-0">Kecamatan</p>
                                            <p class="f-16" v-if="col_kecamatan.length > 0"><b>{{col_kecamatan[0].subdistrict_name}}</b></p>
                                            <p class="f-16" v-else><b>-</b></p>

                                            <p class="mb-0">Kelurahan</p>
                                            <p class="f-16"><b>{{model.kelurahan ? model.kelurahan : '-'}}</b></p>

                                            <p class="mb-0">Jenjang</p>
                                            <ul class="f-16 font-bold pl-2" v-if="model.jenjang != null">
                                                <li v-for="(dt, idx) in model.jenjang" :key="idx">{{ dt.name }}</li>
                                            </ul>
                                            <p class="f-16" v-else><b>-</b></p>
                                        </div>
                                        <div class="col">
                                            <div class="row">
                                                <div class="col">
                                                    <p class="mb-0">Saku</p>
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 55px;">
                                                        </div>
                                                        <div class="col pl-0 align-self-center">
                                                            <p class="mb-0" style="font-size: 25px; color: #46C3E8; font-weight: bold">Rp0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <p class="mb-0">Diamond</p>
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width: 50px;">
                                                        </div>
                                                        <div class="col pl-0 align-self-center">
                                                            <p class="mb-0" style="font-size: 25px; color: #FE9923; font-weight: bold">{{ model.col_diamond }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            

                                            <p class="mb-0 mt-1">User</p>
                                            <p class="f-16" :class="model.bill_status == 1 ? 'text-warning' : 'text-success'"><b>{{model.bill_status == 1 ? 'Premium User' : 'Free User'}}</b></p>
                                            
                                            <p class="mb-0">ID User</p>
                                            <p class="f-16"><b>{{model.id_user}}</b></p>

                                            <p class="mb-0">Rekening</p>
                                            <p class="f-16"><b>-</b></p>

                                            <p class="mb-0">Nomor Rekening</p>
                                            <p class="f-16"><b>-</b></p>

                                            <p class="mb-0">Nama Rekening</p>
                                            <p class="f-16"><b>-</b></p>
                                            
                                            <p class="mb-0">Status</p>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="btn-group">
                                                        <button type="button" class="btn dropdown-toggle" :class="model.status == 1 ? 'btn-outline-success' : 'btn-outline-danger'"
                                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {{ model.status == 1 ? 'Aktif' : 'Nonaktif' }}
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" @click="save(1)" v-if="model.status == 0" v-can="'user/update'">Aktif</a>
                                                            <a class="dropdown-item" v-else>Aktif</a>                                                            
                                                            <a class="dropdown-item" @click="save(0)" v-if="model.status == 1" v-can="'user/update'">Nonaktif</a>
                                                            <a class="dropdown-item" v-else>Nonaktif</a>
                                                        </div>
                                                    </div>
                                                    <div class="col align-self-center">
                                                        <p class="mb-0" style="color: #EF8481; cursor: pointer" @click="destroy(model)" v-can="'user/delete'">Hapus Akun</p>
                                                    </div>
                                                </div>                                                
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PAKET -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Paket</b></h4>
                                    <div class="col p-0" v-if="packages.length > 0">
                                        <div class="d-flex flex-wrap">
                                            <div class="col-12" v-for="(data, index) in packages" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <img :src="data.package.photo_web.url" alt="" class="img-fluid">
                                                        </div>                                                        
                                                        <div class="col-4 text-left p-0 align-self-center">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <p class="f-16"><b>{{data.package.name}}</b></p>
                                                                    <p class="f-16 mb-0">Aktif Hingga</p>
                                                                    <p class="f-16 mb-0">Harga</p>
                                                                </div>
                                                                <div class="col text-right pr-3">
                                                                    <span class="badge badge-pill badge-success mb-1 pl-2 pr-2" v-if="data.is_active">Aktif</span>
                                                                    <p class="f-16 mb-0" v-if="data.is_active"><b>{{ data.end_date | moment('LL') }}</b></p>
                                                                    <p class="f-16 mb-0" v-else><b>Sudah Habis</b></p>
                                                                    <p class="f-16 mb-0"><b>{{ data.package.harga_rupiah | currency }}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 text-justify align-self-center border-left pl-2" >
                                                            <p class="f-16 mb-0">{{data.package.deskripsi}}</p>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col text-center" v-else>
                                        <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                        <p>User ini tidak memiliki Paket</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- MATERI -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Materi</b></h4>
                                    <div class="col p-0" v-if="subjects.length > 0">
                                        <div class="col">
                                            <div class="row">
                                                <div class="col-4 input-group form-search-rounded">
                                                    <select name="" class="form-control" id="" v-model="filter.jenjang">
                                                        <option value="">Pilih jenjang</option>
                                                        <option v-for="(data, index) in option.jenjang" :key="index" :value="data.id">{{data.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-3 input-group form-search-rounded">
                                                    <select name="" class="form-control" id="" v-model="filter.kategori">
                                                        <option value="">Pilih kategori</option>
                                                        <option v-for="(data, index) in option.kategori.filter(data => data.id_level == filter.jenjang)" :key="index" :value="data.id">{{data.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap" v-if="filter.jenjang != '' && filter.kategori != ''">
                                            <div class="col-4" v-for="(data, index) in subjects.filter(data => data.materi.id_level == filter.jenjang && data.materi.id_theory == filter.kategori)" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col text-left">
                                                            <p class="f-16"><b>{{ data.materi.name }}</b></p>
                                                            <p class="mb-0">{{ data.materi.col_total_chapter }} Bab</p>
                                                        </div>
                                                        <div class="col-5 text-right">
                                                            <img :src="data.materi.photo" alt="" class="img-fluid">
                                                        </div>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap" v-else-if="filter.jenjang != ''">
                                            <div class="col-4" v-for="(data, index) in subjects.filter(data => data.materi.id_level == filter.jenjang)" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col text-left">
                                                            <p class="f-16"><b>{{ data.materi.name }}</b></p>
                                                            <p class="mb-0">{{ data.materi.col_total_chapter }} Bab</p>
                                                        </div>
                                                        <div class="col-5 text-right">
                                                            <img :src="data.materi.photo" alt="" class="img-fluid">
                                                        </div>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap" v-else-if="filter.kategori != ''">
                                            <div class="col-4" v-for="(data, index) in subjects.filter(data => data.materi.id_theory == filter.kategori)" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col text-left">
                                                            <p class="f-16"><b>{{ data.materi.name }}</b></p>
                                                            <p class="mb-0">{{ data.materi.col_total_chapter }} Bab</p>
                                                        </div>
                                                        <div class="col-5 text-right">
                                                            <img :src="data.materi.photo" alt="" class="img-fluid">
                                                        </div>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap" v-else>
                                            <div class="col-4" v-for="(data, index) in subjects" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col text-left">
                                                            <p class="f-16"><b>{{ data.materi.name }}</b></p>
                                                            <p class="mb-0">{{ data.materi.col_total_chapter }} Bab</p>
                                                        </div>
                                                        <div class="col-5 text-right">
                                                            <img :src="data.materi.photo" alt="" class="img-fluid">
                                                        </div>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col text-center" v-else>
                                        <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                        <p>User ini tidak memiliki Materi</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- TRYOUT -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Try Out</b></h4>                                    
                                    <div class="col p-0" v-if="tryouts.length > 0">
                                        <div class="d-flex flex-wrap">
                                            <div class="col-4" v-for="(data, index) in tryouts" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <img src="/assets/img/icon/surface1.png" alt="" class="img-fluid" style="width: 51px">
                                                        </div>                                                        
                                                        <div class="col text-left p-0 align-self-center">
                                                            <p class="f-16 mb-0"><b>{{data.col_tryout_name}}</b></p>
                                                            <p class="mb-0">{{data.col_start_end_date}}</p>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col text-center" v-else>
                                        <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                        <p>User ini tidak memiliki Try Out</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- KUIS -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Kuis</b></h4>
                                    <div class="col p-0" v-if="quizzes.length > 0">
                                        <div class="d-flex flex-wrap">
                                            <div class="col-4" v-for="(data, index) in quizzes" :key="index">
                                                <div class="btn col mt-1" style="min-height:100px; border: 1px solid #F1F1F1; box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);border-radius: 4px; padding: 23px;">
                                                    <div class="row">
                                                        <div class="col text-left">
                                                            <p class="f-16"><b>Kuis {{ data.quiz.col_matkul }}</b></p>
                                                            <p class="mb-0">{{ data.quiz.name }}</p>
                                                        </div>
                                                        <div class="col-5 text-right">
                                                            <img :src="data.quiz.photo.url" alt="" class="img-fluid">
                                                        </div>                                                        
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col text-center" v-else>
                                        <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                        <p>User ini tidak memiliki Kuis</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- GRAFIK PROGRES -->
                        <!-- <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <h4><b>Grafik Progres</b></h4>
                                    <div class="col text-center">
                                        <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                        <p>User ini tidak memiliki Grafik Progres</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- RAPOR -->
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">                                    
                                    <h4><b>Rapor</b></h4>                                    
                                    <div class="col p-0 mt-1">
                                        <div class="btn col" style="
                                            min-height:100px; 
                                            border: 1px solid #F1F1F1; 
                                            box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);
                                            border-radius: 4px; 
                                            padding: 23px;">
                                            <div class="col">
                                                <div class="row align-items-center">                                                
                                                    <img src="/assets/img/icon/rapor_tryout.png" alt="" class="img-fluid">
                                                    <p class="f-20 mb-0 ml-1" style="color: #EF8481"><b>Tryout</b></p>
                                                </div>
                                                <div id="accordionTryout" v-if="tryouts.length > 0" class="mt-1">
                                                    <div class="mb-0" v-for="(data, index) in tryouts" :key="index">
                                                        <div class="card-header pt-0 pl-0 pb-0" :id="'headingTryout'+index" style="border-bottom: 1px solid #eee;">
                                                            <button data-toggle="collapse" :data-target="'#collapseTryout'+index" aria-expanded="false" :aria-controls="'collapse'+index" class="btn bd-0 pl-1 col" @click="show.tryout == index ? show.tryout = null : show.tryout = index">
                                                                <div class="row align-items-center">
                                                                    <img src="/assets/img/icon/elipse.png" alt="" class="img-fluid">
                                                                    <p class="f-16 mb-0 ml-1"><b>Hasil {{data.col_tryout_name}}</b></p>
                                                                    <i class="fa" :class="show.tryout == index ? 'fa-chevron-up' : 'fa-chevron-down'" style="position: absolute; right: 0"></i>
                                                                </div>
                                                            </button>
                                                        </div>                                                        

                                                        <div :id="'collapseTryout'+index" class="collapse" :class="index == 0 ? 'show' : '' " :aria-labelledby="'headingTryout'+index" data-parent="#accordionTryout">
                                                            <div class="card-body p-0 text-left mt-1">
                                                                <div class="row" v-for="(dt, idx) in data.nilai_sub_tryout" :key="idx">
                                                                    <div class="col">
                                                                        <p class="f-14">{{dt.name}}</p>
                                                                    </div>
                                                                    <div class="col text-right pr-2">
                                                                        <p class="f-16 text-warning"><b>{{dt.nilai}}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                   
                                                </div>
                                                <div class="col text-center" v-else>
                                                    <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                                    <p>User ini tidak memiliki Try Out</p>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="col p-0 mt-1">
                                        <div class="btn col" style="
                                            min-height:100px; 
                                            border: 1px solid #F1F1F1; 
                                            box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);
                                            border-radius: 4px; 
                                            padding: 23px;">
                                            <div class="col">
                                                <div class="row align-items-center">                                                
                                                    <img src="/assets/img/icon/rapor_materi.png" alt="" class="img-fluid">
                                                    <p class="f-20 mb-0 ml-1" style="color: #78D192"><b>Materi</b></p>
                                                </div>
                                                <div id="accordionMateri" v-if="subjects.length > 0" class="mt-1">
                                                    <div class="mb-0" v-for="(data, index) in subjects" :key="index">
                                                        <div class="card-header pt-0 pl-0 pb-0" :id="'headingMateri'+index" style="border-bottom: 1px solid #eee;">
                                                            <button data-toggle="collapse" :data-target="'#collapseMateri'+index" aria-expanded="false" :aria-controls="'collapseMateri'+index" class="btn bd-0 pl-1 col" @click="show.materi == index ? show.materi = null : show.materi = index">
                                                                <div class="row align-items-center">
                                                                    <img src="/assets/img/icon/elipse_materi.png" alt="" class="img-fluid">
                                                                    <p class="f-16 mb-0 ml-1"><b>{{data.materi.name}}</b></p>
                                                                    <i class="fa" :class="show.materi == index ? 'fa-chevron-up' : 'fa-chevron-down'" style="position: absolute; right: 0"></i>
                                                                </div>
                                                            </button>
                                                        </div>                                                        

                                                        <div :id="'collapseMateri'+index" class="collapse" :class="index == 0 ? 'show' : '' " :aria-labelledby="'headingMateri'+index" data-parent="#accordionMateri">
                                                            <div class="card-body p-0 text-left mt-1">
                                                                <div v-for="(dt, idx) in data.nilai_latihan" :key="idx">
                                                                    <p class="f-16"><b>{{dt.chapter_name}}</b></p>
                                                                        <div class="row" v-for="(dtt, idxx) in dt.contents" :key="idxx">
                                                                            <div class="col">
                                                                                <p class="f-14">{{dtt.name}}</p>
                                                                            </div>
                                                                            <div class="col text-right pr-2">
                                                                                <p class="f-16 text-warning"><b>{{dtt.poin}}</b></p>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                   
                                                </div>
                                                <div class="col text-center" v-else>
                                                    <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                                    <p>User ini tidak memiliki Materi</p>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="col p-0 mt-1">
                                        <div class="btn col" style="
                                            min-height:100px; 
                                            border: 1px solid #F1F1F1; 
                                            box-shadow: 0px 2px 10px rgba(183, 177, 177, 0.25);
                                            border-radius: 4px; 
                                            padding: 23px;">
                                            <div class="col">
                                                <div class="row align-items-center">                                                
                                                    <img src="/assets/img/icon/rapor_quiz.png" alt="" class="img-fluid">
                                                    <p class="f-20 mb-0 ml-1" style="color: #47568C"><b>Kuis</b></p>
                                                </div>
                                                <div v-if="quizzes.length > 0" class="mt-1">
                                                    <div class="mb-1" v-for="(data, index) in quizzes" :key="index">
                                                        <div class="card-header pt-0 pl-0 pb-0 pr-0" style="border-bottom: 1px solid #eee;">
                                                            <div class="btn bd-0 pl-1 col">
                                                                <div class="row align-items-center">
                                                                    <img src="/assets/img/icon/elipse_kuis.png" alt="" class="img-fluid">
                                                                    <p class="f-16 mb-0 ml-1"><b>{{ data.quiz.col_matkul }}</b> - {{ data.quiz.name }}</p>
                                                                    <p class="f-16 mb-0 text-warning" style="position: absolute; right: 0"><b>{{ data.poin }}</b></p>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>                                                   
                                                </div>
                                                <div class="col text-center" v-else>
                                                    <img src="/assets/img/icon/empty.png" alt="" class="img-fluid mb-1">
                                                    <p>User ini tidak memiliki Kuis</p>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'UserDetail',
    data() {
        return {            
            model: {},            
            temp_photo: null,
            option: {
                provinces: {},
                city: {},
                subdistrict: {},
                jenjang: {},
                kategori: {}
            },
            filter: {
                jenjang: "",
                kategori: ""
            },
            col_kabupaten: [],
            col_kecamatan: [],
            show: {
                tryout: 0,
                materi: 0
            },
            packages: [],
            subjects: [],
            tryouts: [],
            quizzes: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/student?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url
                    }
                    _.get_packages()
                    _.get_subjects()
                    _.get_tryouts()
                    _.get_quizzes()
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_tryouts() {
            let _ = this
            _.axios.get('/student/tryout?email=' + _.model.email, {
                    headers:{
                        detail: 1
                    }
                })
                .then(resp=>{
                    _.tryouts = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_packages() {
            let _ = this
            _.axios.get('/student/package?id_user=' + _.model.id_user)
                .then(resp=>{
                    _.packages = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_subjects() {
            let _ = this
            _.axios.get('/student/subject?id_user=' + _.model.id_user)
                .then(resp=>{
                    _.subjects = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_quizzes() {
            let _ = this
            _.axios.get('/student/quiz?id_user=' + _.model.id_user)
                .then(resp=>{
                    _.quizzes = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_city() {
            let _ = this
            _.axios.get('/location/city')
                .then(resp=>{
                    _.option.city = resp.data.data.rajaongkir.results                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_subdistrict() {
            let _ = this
            _.axios.get('/location/subdistrict' + '?city=' + _.model.kabupaten)
                .then(resp=>{
                    _.option.subdistrict = resp.data.data.rajaongkir.results
                    _.col_kecamatan = _.option.subdistrict.filter(data => data.subdistrict_id === _.model.kecamatan);
                    _.col_kabupaten = _.option.city.filter(data => data.city_id === _.model.kabupaten);
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save(x) {
            let _ = this
            let data = {
                id: _.model.id,
                status: x
            }
            _.$swal.fire({
                title: x == 1 ? 'Aktifkan User ini?' : 'Nonaktifkan User ini?',                
                html:   '<p class="mb-0">User dapat diaktifkan kembali</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#30B44B',
                confirmButtonText: x == 1 ? 'Aktifkan' : 'Nonaktifkan',
                }).then((result) => {
                    if (result.value) {
                        _.axios.patch('/student/update-status', data)
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: x == 1 ? 'User Diaktifkan!' : 'User Dinonaktifkan!',
                                        text: x == 1 ? 'User berhasil diaktifkan' : 'User berhasil dinonaktifkan',
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.get()
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                if (err.response.status === 401){
                                    _.$store.dispatch('logout')
                                    _.$router.push('/login')
                                } else {
                                    console.log(err.response)
                                }
                            })                    
                    }
            })                        
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus User ini?',                
                html:   '<p class="mb-0">User tidak dapat dikembalikan <br> apabila sudah dihapus</p>',
                showCancelButton: true,
                cancelButtonText: 'Batalkan',
                confirmButtonColor: '#EF8481',
                confirmButtonText: 'Ya, Hapus User',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/student', { data: { id: x.id }})
                            .then(resp => {
                                if (resp.status) {
                                    _.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                    _.$router.push('/user')
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                if (err.response.status === 401){
                                    _.$store.dispatch('logout')
                                    _.$router.push('/login')
                                } else {
                                    console.log(err.response)
                                }
                            })                        
                    }
            })
        },
        get_jenjang() {
            let _ = this
            _.axios.get('/level/all')
                .then(resp=>{
                    _.option.jenjang = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_kategori() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option.kategori = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.get_jenjang()
        _.get_kategori()
        _.get_city()
        if (_.$route.params.id) {
            _.get()
        }
    },
    watch:{
        'model.kabupaten': function(){
            this.get_subdistrict()
        },
        'filter.jenjang': function(){
            this.filter.kategori = ''
        }
    }
}
</script>
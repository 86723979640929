<template>
  <section>
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.41978 14.6H17.3994V15.4H5.99979C5.23122 15.4 4.59986 14.7686 4.59986 14C4.59986 13.7529 4.66338 13.5257 4.7708 13.3377L4.77087 13.3377L4.77536 13.3296L6.12531 10.8796L6.27358 10.6105L6.14192 10.3329L2.54205 2.74288L2.37942 2.4H1.99993H0.6V1.6H2.88892L3.66684 3.25521L3.82888 3.6H4.20986H18.9985C19.068 3.60026 19.1363 3.61849 19.1967 3.65293C19.2572 3.68746 19.3078 3.73711 19.3434 3.79703C19.379 3.85694 19.3985 3.92507 19.3999 3.99477C19.4013 4.0638 19.385 4.13202 19.3525 4.1929C19.3521 4.19349 19.3518 4.19408 19.3515 4.19466L15.7741 10.6802L15.7733 10.6815C15.5369 11.1127 15.0767 11.4 14.5495 11.4H7.09976H6.74565L6.5745 11.71L5.67453 13.34L5.63669 13.4085L5.6177 13.4845L5.5877 13.6045L5.56979 13.6761V13.75C5.56979 14.2214 5.94839 14.6 6.41978 14.6ZM4.60986 19C4.60986 18.2274 5.23513 17.6 5.99979 17.6C6.76837 17.6 7.39973 18.2314 7.39973 19C7.39973 19.7686 6.76837 20.4 5.99979 20.4C5.23513 20.4 4.60986 19.7726 4.60986 19ZM14.6095 19C14.6095 18.2274 15.2348 17.6 15.9995 17.6C16.768 17.6 17.3994 18.2314 17.3994 19C17.3994 19.7686 16.768 20.4 15.9995 20.4C15.2348 20.4 14.6095 19.7726 14.6095 19Z"
                      stroke="white"
                      stroke-width="1.2"
                    />
                  </svg>
                  List Artikel /
                  {{ $route.params.id ? "Edit" : "Tambah" }} Artikel
                </a>
              </li>
            </ol>
          </div>
        </div>
        <div class="breadcrumbs-top">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <b>
                  <router-link to="/artikel">
                    <i class="feather icon-arrow-left"></i>
                    Kembali
                  </router-link>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <h2 class="content-header-title mb-0">
          {{ $route.params.id ? "Edit" : "Tambah" }} Artikel
        </h2>
      </div>
    </div>
    <div class="content-body">
      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <ValidationObserver v-slot="{ invalid }" class="card-content">
              <form @submit.prevent="save">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body card-dashboard">
                      <div class="row">
                        <div class="col">
                          <ValidationProvider
                            name="Foto reward"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div style="text-align: -webkit-center">
                              <div v-if="temp_photo != null">
                                <img
                                  :src="temp_photo"
                                  class="img-fluid"
                                  alt=""
                                />
                                <br />
                                <button
                                  class="btn btn-danger mt-1 mb-1"
                                  @click="
                                    (temp_photo = null), (model.foto = null)
                                  "
                                >
                                  Hapus Foto
                                </button>
                              </div>
                              <div
                                v-else
                                style="
                                  text-align: center;
                                  height: 150px;
                                  width: 150px;
                                  border: 1px solid #bdbdbd;
                                  border-radius: 50%;
                                  background: #fafafa;
                                "
                              >
                                <label
                                  for="file-upload"
                                  class="btn-edit"
                                  style="font-size: 40px; margin-top: 27px"
                                >
                                  <i
                                    class="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <p style="font-size: 14px">Pilih Foto</p>
                                </label>
                                <input
                                  id="file-upload"
                                  class="input-file-avatar d-none"
                                  type="file"
                                  v-on:change="Addphoto"
                                />
                              </div>
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>

                          <ValidationProvider
                            name="Judul Artikel"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Judul Artikel</label>
                              <input
                                type="text"
                                v-model="model.judul"
                                class="form-control"
                                placeholder="Masukkan judul "
                              />
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>

                       

                          <ValidationProvider
                            name="Deskripsi"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <div
                              class="form-group"
                              :class="{ 'validation-warning': errors[0] }"
                            >
                              <label for="">Deskripsi</label>
                              <quill-editor
                                v-model="model.deskripsi"
                                :options="editorOption"
                              />
                              <small class="text-muted" v-if="errors[0]">{{
                                errors[0]
                              }}</small>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-success float-right"
                  :disabled="invalid"
                >
                  Simpan Artikel
                </button>
                <router-link to="/artikel">
                  <button
                    type="button"
                    class="btn btn-outline-success mr-1 float-right"
                  >
                    Cancel
                  </button>
                </router-link>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
// import List from "@/components/List.vue";
export default {
  name: "Add",
  components: {},
  data() {
    return {
      model: {
        judul: "",
        deskripsi: "",
        foto: null,
      },
      temp_photo: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
            handleStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
              // other camelCase styles for size display
            },
            toolbarStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
              // other camelCase styles for size display
            },
            toolbarButtonStyles: {
              // ...
            },
            toolbarButtonSvgStyles: {
              // ...
            },
          },
        },
      },
    };
  },
  methods: {
    get() {
      let _ = this;
      _.axios
        .get("/artikel?id=" + _.$route.params.id)
        .then((resp) => {
          _.model = resp.data.data.rows;
          if (_.model.foto != null) {
            _.temp_photo = _.model.foto;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    Addphoto(ev) {
      let _ = this;
      const file = ev.target.files[0];
      var reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData);
          _.model.foto = "data:image/jpeg;base64," + base64String;
          _.temp_photo = _.model.foto;
        };
      })(file);
      reader.readAsBinaryString(file);
    },
    save() {
      let _ = this;

      _.FData = new FormData();
      for (let i in _.model) {
        _.FData.append(i, _.model[i]);
      }

      if (_.$route.params.id) {
        _.axios
          .post("/artikel/" + _.$route.params.id, _.FData)
          .then((resp) => {
            _.$router.push("/artikel");
            _.$toast.success(resp.data.message);
          })
          .catch((err) => {
            console.log(err.response.data.message);
            _.$toast.warning(err.response.data.message);
          });
      } else {
        if (_.model.foto == null) {
          _.$toast.warning("Foto harus diisi.");
          return;
        }

        _.axios
          .post("/artikel", _.FData)
          .then((resp) => {
            _.$router.push("/artikel");
            _.$toast.success(resp.data.message);
          })
          .catch((err) => {
            _.$toast.warning(err.response.data.message);
          });
      }
    },
  },
  mounted() {
    let _ = this;
    if (_.$route.params.id) {
      _.get();
    }
  },

  watch: {},
};
</script>

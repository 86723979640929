<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">                                
                                <a>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.334 3.83325H17.2507C17.759 3.83325 18.2465 4.03519 18.6059 4.39463C18.9654 4.75408 19.1673 5.24159 19.1673 5.74992V19.1666C19.1673 19.6749 18.9654 20.1624 18.6059 20.5219C18.2465 20.8813 17.759 21.0833 17.2507 21.0833H5.75065C5.24232 21.0833 4.75481 20.8813 4.39536 20.5219C4.03592 20.1624 3.83398 19.6749 3.83398 19.1666V5.74992C3.83398 5.24159 4.03592 4.75408 4.39536 4.39463C4.75481 4.03519 5.24232 3.83325 5.75065 3.83325H7.66732" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.543 9.5835H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.543 13.4167H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10.543 17.25H15.3346" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.90625 9.5835H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.90625 13.4167H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.90625 17.25H7.91253" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.3743 1.91675H8.62435C8.09508 1.91675 7.66602 2.34581 7.66602 2.87508V4.79175C7.66602 5.32102 8.09508 5.75008 8.62435 5.75008H14.3743C14.9036 5.75008 15.3327 5.32102 15.3327 4.79175V2.87508C15.3327 2.34581 14.9036 1.91675 14.3743 1.91675Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    List Kuis / {{$route.params.id ? 'Edit' : 'Tambah'}} Kuis
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="breadcrumbs-top">                    
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <b>
                                    <router-link to="/quiz">
                                        <i class="feather icon-arrow-left"></i> 
                                        Kembali
                                    </router-link>
                                </b>
                            </li>
                        </ol>
                    </div>
                </div>
                <h2 class="content-header-title mb-0">{{$route.params.id ? 'Edit' : 'Tambah'}} Kuis</h2>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver v-slot="{ invalid }" class="card-content">
                            <form @submit.prevent="save">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body card-dashboard">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div style="text-align: -webkit-center;">
                                                        <div v-if="temp_photo != null">
                                                            <img :src="temp_photo" class="img-fluid" alt="">
                                                            <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null">Hapus Foto</button>
                                                        </div>
                                                        <div v-else 
                                                            style="
                                                                text-align: center; 
                                                                height: 150px; 
                                                                width: 150px; 
                                                                border: 1px solid #bdbdbd; 
                                                                border-radius: 50%; 
                                                                background: #FAFAFA;
                                                            ">
                                                            <label for="file-upload" class="btn-edit" style="font-size: 40px; margin-top: 27px;">
                                                                <i class="fa fa-camera" aria-hidden="true"></i> 
                                                                <p style="font-size: 14px">Pilih Foto</p>
                                                            </label>
                                                            <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <ValidationProvider name="Nama kuis" v-slot="{ errors }" rules="required">
                                                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                            <label for="">Nama Kuis</label>
                                                            <input type="text" v-model="model.name" id="name" class="form-control"
                                                                placeholder="Masukkan nama kuis">
                                                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                        </div>
                                                    </ValidationProvider>
                                                    <div class="row">                                                        
                                                        <div class="col">
                                                            <ValidationProvider name="Materi" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Materi</label>
                                                                    <select name="" id="" v-model="model.id_theory" class="form-control">
                                                                        <option value="">Pilih Materi</option>
                                                                        <option v-for="(data, index) in option_materi" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col">
                                                            <ValidationProvider name="Mata pelajaran" v-slot="{ errors }" rules="required">
                                                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                                                    <label for="">Mata Pelajaran</label>
                                                                    <select name="" id="" v-model="model.id_course" class="form-control">
                                                                        <option value="">Pilih Mata Pelajaran</option>
                                                                        <option v-for="(data, index) in option_pelajaran" :key="index" :value="data.id">{{data.name}}</option>
                                                                    </select>
                                                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <ValidationProvider name="Urutan" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Urutan</label>
                                                        <input type="number" class="form-control" v-model="model.index">                                            
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Waktu pengerjaan" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Waktu Pengerjaan</label>
                                                        <div class="row align-items-center">
                                                            <div class="col-1">
                                                                <input type="number" v-model="model.waktu" class="form-control" placeholder="0">
                                                            </div>
                                                            Menit                                                 
                                                        </div>                                                        
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col">
                                                <ValidationProvider name="Jenis kuis" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Jenis Kuis</label>
                                                        <div class="container ml-0">
                                                            <div class="row mt-1">                                                        
                                                                <input type="radio" value="1" name="premium" v-model="model.jenis" style="align-self: center">
                                                                <label for="premium" class="ml-1 mr-1">Premium</label>
                                                                <input type="radio" value="0" name="free" v-model="model.jenis" style="align-self: center">
                                                                <label for="free" class="ml-1 mr-1">Free</label>
                                                            </div>
                                                        </div>                                               
                                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col" v-if="model.jenis == 1">                                                
                                                <div class="form-group mb-0">
                                                    <label for="">Harga</label>
                                                    <div class="container ml-0">
                                                        <div class="row mt-1">                                                        
                                                            <input type="radio" value="0" name="semua" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="semua" class="ml-1 mr-1">Semua</label>
                                                            
                                                            <input type="radio" value="1" name="rupiah" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="rupiah" class="ml-1 mr-1">Rupiah</label>
                                                            
                                                            <input type="radio" value="2" name="saku" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="saku" class="ml-1 mr-1">Saku</label>
                                                            
                                                            <input type="radio" value="3" name="diamond" v-model="model.jenis_harga" style="align-self: center" v-on:change="resetHarga()">
                                                            <label for="diamond" class="ml-1 mr-1">Diamond</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col mb-1" v-if="model.jenis == 1">
                                                <div class="col pr-0">
                                                    <div class="row">
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 1">
                                                            <div class="row">
                                                                <div class="align-self-center">Rp</div>
                                                                <div class="col"><input type="number" min="1" required v-model="model.harga_rupiah" class="form-control" placeholder="0"></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 2">
                                                            <div class="row">
                                                                <div class="align-self-center"><img src="/assets/img/icon/saku.png" alt="" class="img-fluid" style="width: 40px"></div>
                                                                <div class="col"><input type="number" min="1" required v-model="model.harga_saku" class="form-control" placeholder="0"></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12 mt-1" v-if="model.jenis_harga == 0 || model.jenis_harga == 3">
                                                            <div class="row">
                                                                <div class="align-self-center"><img src="/assets/img/icon/diamond.png" alt="" class="img-fluid" style="width:30px"></div>
                                                                <div class="col"><input type="number" min="1" required v-model="model.harga_diamond" class="form-control" placeholder="0"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row">
                                                    <div class="form-group mr-2 col">
                                                        <label for="">Tanggal Awal</label>
                                                        <div class="position-relative has-icon-right">
                                                            <ValidationProvider name="Tanggal awal" rules="required" v-slot="{ errors }">
                                                                <date-picker
                                                                    v-model="model.start_date"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Masukan tanggal awal"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col">
                                                        <label for="">Tanggal Selesai</label>
                                                        <div class="position-relative has-icon-right">
                                                            <ValidationProvider name="Tanggal selesai" rules="required" v-slot="{ errors }">
                                                                <date-picker
                                                                    v-model="model.end_date"
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control validation-warning no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    placeholder="Masukan tanggal selesai"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" :disabled="invalid">
                                    Simpan Kuis
                                </button>
                                <router-link to="/quiz">
                                    <button type="button" class="btn btn-outline-success mr-1 float-right">
                                        Cancel
                                    </button>
                                </router-link>
                            </form>  
                        </ValidationObserver>                      
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'QuizForm',
    data() {
        return {            
            model: {
                id_course           : '',
                id_theory           : '',
                jenis               : 1,
                jenis_harga         : 0,
                masa_berlaku        : '',
                index               : 1
            },
            option_pelajaran: [],
            option_materi: [],
            temp_photo: null      
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/quiz?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                    if (_.model.photo != null){
                        _.temp_photo = _.model.photo.url 
                    }                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_pelajaran() {
            let _ = this
            _.axios.get('/course/all')
                .then(resp=>{
                    _.option_pelajaran = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_materi() {
            let _ = this
            _.axios.get('/theory/all')
                .then(resp=>{
                    _.option_materi = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this

            _.FData =  new FormData()
            for (let i in _.model){
                _.FData.append(i, _.model[i])
            }  

            if (_.$route.params.id){
                _.axios.post('/quiz/'+_.$route.params.id, _.FData)
                    .then(resp => {                
                        _.$router.push('/quiz')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            } else {
                _.axios.post('/quiz', _.FData)
                    .then(resp => {                
                        _.$router.push('/quiz')    
                        _.$toast.success(resp.data.message)
                    })
                    .catch(err => {
                        _.$toast.warning(err.response.data.message)
                    })
            }            
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.photo    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        resetHarga(){
            let _ = this
            _.model.harga_rupiah = 0
            _.model.harga_saku = 0
            _.model.harga_diamond = 0
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id) {
            _.get()
        }
        _.get_pelajaran()
        _.get_materi()
    }
}
</script>